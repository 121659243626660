import { DarkMode, LightMode } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  collapseClasses,
} from '@mui/material'
import { useState } from 'react'

const GeneralSettings = ({}) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')
  const [collapseMenu, setCollapseMenu] = useState(
    localStorage.getItem('NO_EXPAND_MENU') || 'false',
  )

  const applyChanges = () => {
    localStorage.setItem('theme', theme)
    localStorage.setItem('NO_EXPAND_MENU', collapseMenu)
    window.location.reload()
  }

  return (
    <>
      <h1 style={{ marginBottom: '1em' }}>Ajustes Generales</h1>
      {/* <Divider textAlign="right" sx={{ my: 2 }}> */}
      {/*   Aspecto */}
      {/* </Divider> */}
      <Grid spacing={1} container>
        <Grid xs={12} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tema</InputLabel>
            <Select
              sx={{ '.MuiSelect-select': { display: 'flex !important', alignItems: 'center' } }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={theme}
              label="Tema"
              onChange={(e) => setTheme(e.target.value)}
            >
              <MenuItem value={'light'}>
                <ListItemIcon>
                  <LightMode fontSize="small" />
                </ListItemIcon>
                <ListItemText>Modo Claro</ListItemText>
              </MenuItem>
              <MenuItem value={'dark'}>
                <ListItemIcon>
                  <DarkMode fontSize="small" />
                </ListItemIcon>
                <ListItemText>Modo Oscuro</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          <FormControlLabel
            control={
              <Switch
                checked={collapseMenu == 'true'}
                onChange={(event) => setCollapseMenu(event.target.checked ? 'true' : 'false')}
              />
            }
            label="Colapsar el menú automáticamente"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 1 }} spacing={1}>
        <Grid xs={12} lg={6} item>
          <Button
            sx={{ width: '100%' }}
            onClick={applyChanges}
            variant="contained"
            disableElevation
          >
            Aplicar Cambios
          </Button>
        </Grid>
        <Grid xs={12} lg={6} item>
          <Button sx={{ width: '100%' }} variant="contained" color="warning" disableElevation>
            Cancelar Cambios
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default GeneralSettings
