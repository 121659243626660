import { ArrowUpward, EscalatorWarningRounded, Groups } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton, Paper, Typography } from '@mui/material'
import CustomTable from 'common/components/CustomTable'
import ScrollHelper from 'common/libs/ScrollHelper'

/**
 *
 * @param {Object} props
 * @param {Model145DTO} props.data - The user to show the data
 */
const Model145ParentsData = ({ data }) => {
  const descendants = data?.irpf_da_ascendentes ?? []
  return (
    <Paper>
      <Card>
        <CardHeader
          avatar={<EscalatorWarningRounded />}
          sx={{
            backgroundColor: 'background.lighter',
            boxShadow: 'none',
          }}
          title="Mayores"
          action={
            <IconButton onClick={() => ScrollHelper.scrollTo({ id: 'model145-intro' })}>
              <ArrowUpward />
            </IconButton>
          }
        />
        <CardContent>
          <Typography sx={{ my: 1 }} variant="subtitle1">
            {
              'Datos de los ascendientes mayores de 65 años (o menores de dicha edad si son discapacitados) que conviven con Vd. durante, al menos, la mitad del año y que no tienen rentas anuales superiores a 8.000 euros.'
            }
          </Typography>
          <CustomTable
            hideFooterPagination={true}
            dataSource={descendants}
            fields={[
              { key: 'Año nacimiento', value: 'ejer_nacimiento', flex: 1 },
              {
                key: 'Año de adopcion o recogimiento',
                value: 'ejer_adopcion',
                flex: 1,
              },
              {
                key: 'Grado igual o superior al 33% e inferior al 65%',
                value: 'disability_1',
                flex: 1,
                customGetter: (a) => {
                  return a.row.tipo_discapacidad === '01' ? 'Si' : 'No'
                },
              },
              {
                key: 'Grado igual o superior al 65%',
                value: 'disability_2',
                flex: 1,
                customGetter: (a) => {
                  return a.row.tipo_discapacidad === '02' ? 'Si' : 'No'
                },
              },
              {
                key: 'Además, tiene acreditada la necesidad de ayuda de terceras personas o movilidad reducida',
                value: 'disability_3',
                flex: 1,
                customGetter: (a) => {
                  return a.row.tipo_discapacidad === '03' ? 'Si' : 'No'
                },
              },
            ]}
          />
        </CardContent>
      </Card>
    </Paper>
  )
}

export default Model145ParentsData
