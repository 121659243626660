// @ts-check
import axios from 'api/axios'

/**
 * This class hels to manage files convertion and download
 */
class FilesHelper {
  /**
   *
   * @typedef {Object} FileReturn
   * @property {string} FileReturn.name
   * @property {string} FileReturn.base64
   *
   * @async
   *
   * @param {File} file - The file to get the base64
   * @returns {Promise<FileReturn>}
   */
  static fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader()
      // Read file content on file loaded event
      reader.onload = function (event) {
        if (!event?.target?.result) throw new Error('No se pudo leer el archivo')
        resolve({
          name: file.name,
          base64: event.target.result.toString().replace('data:', '').replace(/^.+,/, ''),
        })
      }

      // Convert data to base64
      reader.readAsDataURL(file)
    })
  }

  /**
   * This function makes the browser download a blob string
   *
   * @param {string} blobString - The Blob String
   * @param {string} mimeType - The file mimeType
   * @param {string} fileName - The name of the file to download
   */
  static blobDownload = (blobString, mimeType, fileName) => {
    try {
      const binary = window.atob(blobString.replace(/\s/g, ''))
      const len = binary.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)

      for (let i = 0; i < len; i += 1) {
        view[i] = binary.charCodeAt(i)
      }

      const blobFile = new Blob([view], { type: mimeType })
      const url = URL.createObjectURL(blobFile)

      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style.display = 'none'
      a.href = url
      a.download = fileName

      a.click()
    } catch (err) {
      throw err
    }
  }

  /**
   * This function downloads a document
   * @async
   *
   * @param {number} file_id - The id of the file to download
   * @param {number} document_type - The type of document to download
   */
  static async downloadFile(file_id, document_type) {
    try {
      /** @type {import('axios').AxiosResponse<DocumentFileDTO>} */
      const response = await axios.get(`/descargararchivo/?t=${document_type}&f=${file_id}`)
      FilesHelper.blobDownload(
        response.data.fichero,
        response.data.mime_type,
        response.data.nombre_archivo,
      )
    } catch (err) {
      throw err
    }
  }
}

export default FilesHelper
