import { Grid, Paper } from '@mui/material'
import React from 'react'
import GeneralSettings from '../components/GeneralSettings'
import AccountSettings from '../components/AccountSettings'

const Settings = ({}) => {
  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <AccountSettings />
          </Grid>
          <Grid item xs={12} md={5}>
            <GeneralSettings />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default Settings
