import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPE from "../../api/axios";

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const initialState = {
  subempresas: [],
  subempresasStatus: "idle",
  hojas: [],
  hojasStatus: "idle",
  crearStatus: "idle",
  crearError: "",
  tipos: [],
  tiposStatus: "idle",
  error: "",
  grupoGasto: [],
  grupoGastoStatus: "idle",
  gastoError: "",
  lineaGastosError: "",
  lineaGastosStatus: "idle",
  borrarLineaError: '',
  borrarLineaStatus: '',
  borrarDocStatus: 'idle',
  borrarDocError: '',
  descargaDocStatus: 'idle',
  descargaDocError: '',
  descargaDoc: '',
  editarLineaError: "",
  editarLineaStatus:"idle",
  totalHojas: 0,
  nextPag: '',
  prevPag: '',
};

export const fetchSubempresasTrabajador = createAsyncThunk("/subempresas", async () => {
  const response = await axiosPE.get("/subempresas");
  return response.data;
});
export const fetchTipos = createAsyncThunk("/rhgtipos", async () => {
  const response = await axiosPE.get("/hojasgasto/tipos");
  return response.data;
});
export const fetchHojasGasto = createAsyncThunk("/fetchhojasgasto", async ({url, sort}) => {
  // mejor no utilizar url que tenga un next con sort incluido, al mismo tiempo que se pasa el sort por parámetro a esta función.
  let consulta = url || '/hojasgasto/'
  // añadir el orden según como venga la consulta:
  //// next sin sort, o url sin next
  consulta = sort ? `${consulta}${url ? '&':'?'}sort=${sort}` : consulta
  const response = await axiosPE.get(consulta);
  response.data.sort = Boolean(sort)    // indica que se ha hecho un sort. El reducer vacía las hojas
  return response.data;
});
export const createHojaGasto = createAsyncThunk("/createhojasgasto", async (hoja) => {
  const response = await axiosPE.post("/hojasgasto/", hoja);
  return response.data;
});
// Coger datos de grupo gastos
export const fetchGrupoGastos = createAsyncThunk("/fetchhojastiposgasto", async (id) => {
  const response = await axiosPE.get("/hojastiposgasto");
  return response.data;
});
//postear nueva linea de gastos
export const createLineaGastos = createAsyncThunk("/hojasgastolin", async (linea) => {
  const response = await axiosPE.post("/hojasgastolin/", linea);
  return response.data;
});
// borrar linea de gastos. linea = {id_lin, id_hoja}
export const deleteLineaGastos = createAsyncThunk("/deletehojasgastolin", async (linea) => {
  const response = await axiosPE.delete("/hojasgastolin/", {data: linea});
  return response.data;
});
// borrar documento en linea de gasto. doc = {id_hoja, id_lin, id_doc}
export const deleteGastoLinDoc = createAsyncThunk('/deletehojasgastolindoc', async (doc) => {
  const response = await axiosPE.delete('/hojasgastolindoc/', {data: doc})
  return response.data
})
export const descargaLinDoc = createAsyncThunk('/descargalindoc', async (doc) => {
  const response = await axiosPE.get(`/descargardocumento/${doc}`)
  return response.data
})
//editar Linea de gastos
export const editarLineaGastos = createAsyncThunk("/hojasgastolineditar", async (linea)=> {
  const response = await axiosPE.patch("/hojasgastolin/",linea)
  return response.data;
})
//borrar hoja de gasto
export const borrarHojaGasto = createAsyncThunk("/hojasgasto",async (hoja) => {
  const response = await axiosPE.delete('/hojasgasto',{data: hoja})
  return response.data;
})

export const hojasGastoSlice = createSlice({
  name: "hojasgasto",
  initialState,
  reducers: {
    setIniciarCrearStatus: (state, action) => {
      state.crearStatus = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubempresasTrabajador.pending, (state, action) => {
        state.subempresasStatus = "loading";
      })
      .addCase(fetchSubempresasTrabajador.rejected, (state, action) => {
        state.subempresasStatus = "failed";
      })
      .addCase(fetchSubempresasTrabajador.fulfilled, (state, action) => {
        state.subempresasStatus = "succeeded";
        state.subempresas = action.payload.results;
      })
      .addCase(fetchTipos.pending, (state, action) => {
        state.tiposStatus = "loading";
      })
      .addCase(fetchTipos.rejected, (state, action) => {
        state.tiposStatus = "failed";
      })
      .addCase(fetchTipos.fulfilled, (state, action) => {
        state.tiposStatus = "succeeded";
        state.tipos = action.payload.results;
      })
      .addCase(fetchHojasGasto.pending, (state, action) => {
        state.hojasStatus = "loading";
      })
      .addCase(fetchHojasGasto.rejected, (state, action) => {
        state.hojasStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchHojasGasto.fulfilled, (state, action) => {
        state.hojasStatus = "succeeded";
        state.totalHojas = action.payload.count
        state.nextPag = action.payload.next
        state.prevPag = action.payload.previous
        //meter las siguientes hojas en la lista, si no es la primera pagina
        if(action.payload.sort){
          console.log('nuevo fetch');
          state.hojas = action.payload.results
        }else{
          console.log('más datos');
          state.hojas.push(...action.payload.results);
        }
      })
      .addCase(createHojaGasto.pending, (state, action) => {
        state.crearStatus = "loading";
      })
      .addCase(createHojaGasto.rejected, (state, action) => {
        state.crearStatus = "failed";
        state.crearError = action.error.message;
      })
      .addCase(createHojaGasto.fulfilled, (state, action) => {
        state.crearStatus = "succeeded";
        state.hojas.push(action.payload);
      })
      .addCase(fetchGrupoGastos.fulfilled, (state, action) => {
        state.grupoGastoStatus = "succeeded";
        state.grupoGasto = action.payload.results;
      })
      .addCase(fetchGrupoGastos.pending, (state, action) => {
        state.grupoGastoStatus = "loading";
      })
      .addCase(fetchGrupoGastos.rejected, (state, action) => {
        state.grupoGastoStatus = "failed";
        state.grupoGasto.gastoError = action.error.message;
      })
      .addCase(createLineaGastos.fulfilled, (state, action) => {
        state.lineaGastosStatus = "succeeded";
        state.lineaGastosError = "";
        // meter la linea en la hoja
        const hoja = action.payload.id_hoja;
        state.hojas.forEach((h) => {
          if (h.id === hoja) {
            h.lineas.push(action.payload);
          }
        });
      })
      .addCase(createLineaGastos.pending, (state, action) => {
        state.lineaGastosStatus = "loading";
      })
      .addCase(createLineaGastos.rejected, (state, action) => {
        state.lineaGastosStatus = "failed";
        state.lineaGastosError = action.error.message;
      })
      .addCase(deleteLineaGastos.pending, (state) => {
        state.borrarLineaStatus = 'loading'
      })
      .addCase(deleteLineaGastos.rejected, (state, action) => {
        state.borrarLineaStatus = 'failed'
        state.borrarLineaError = action.error.message
      })
      .addCase(deleteLineaGastos.fulfilled, (state, action) => {
        state.borrarLineaError = ''
        state.borrarLineaStatus = 'succeded'
        // Borrar la linea del state
        const hoja = action.payload.id_hoja;
        state.hojas.forEach((h) => {
          if (h.id === Number(hoja)) {
            let index = h.lineas.map(l => l.id_lin).indexOf(action.payload.id_lin)
            h.lineas.splice(index, 1)
          }
        });
      })
      .addCase(deleteGastoLinDoc.pending, state => {
        state.borrarDocStatus = 'loading'
      })
      .addCase(deleteGastoLinDoc.rejected, (state, action) => {
        state.borrarDocError = action.error
        state.borrarDocStatus = 'failed'
      })
      .addCase(deleteGastoLinDoc.fulfilled, (state, action) => {
        state.borrarDocError = ''
        state.borrarDocStatus = 'succeded'
        // Localiza y borra el documento del state
        const hoja = Number(action.payload.idHoja);
        const lin = Number(action.payload.idLin)
        const doc = Number(action.payload.idDoc)
        state.hojas.forEach((h) => {
          if (h.id === hoja) {
            const  linIndex = h.lineas.map(l => l.id_lin).indexOf(lin)
            const l = h.lineas[linIndex]
            const docIndex = l.documentos.map(d => d.id_doc).indexOf(doc)
            l.documentos.splice(docIndex, 1)
          }
        });
      })
      .addCase(descargaLinDoc.pending, state => {
        state.descargaDocStatus = 'loading'
        state.descargaDocError = ''
      })
      .addCase(descargaLinDoc.rejected, (state, action) => {
        state.descargaDocStatus = 'failed'
        state.descargaDocError = action.error
      })
      .addCase(descargaLinDoc.fulfilled, (state, action) => {
        state.descargaDocStatus = 'succeded'
        // generar binarios
        const blob = b64toBlob(action.payload.doc)
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = action.payload.nombre;
        a.click();
      })
      //EditarLineasGasto
      .addCase(editarLineaGastos.fulfilled, (state, action) => {
        state.editarLineaStatus = "succeeded";
        state.editarLineaError = "";
        // meter la linea en la hoja
        const hoja = action.payload.id_hoja;
        const linea = action.payload.id_lin;
        state.hojas.forEach((g) => {
          if(g.id===hoja){
              const  linIndex = g.lineas.map(l => l.id_lin).indexOf(linea)
              g.lineas[linIndex] = action.payload
            };
          }
        );
      })
      .addCase(editarLineaGastos.pending, (state, action) => {
        state.editarLineaStatus = "loading";
      })
      .addCase(editarLineaGastos.rejected, (state, action) => {
        state.editarLineaStatus = "failed";
        state.editarLineaError = action.error.message;
      })
      //Borrar Hoja Gasto
      .addCase(borrarHojaGasto.fulfilled, (state, action) => {
        state.borrarHojaStatus = "succeeded";
        state.borrarHojaError = ''
      })
      .addCase(borrarHojaGasto.pending, (state, action) => {
        state.borrarHojaStatus = "loading";
      })
      .addCase(borrarHojaGasto.rejected, (state, action) => {
        state.borrarHojaStatus = "failed";
        state.borrarHojaError = action.error
      });
  },
});

export const { setIniciarCrearStatus } = hojasGastoSlice.actions;

export const selectSubempresas = (state) => state.hojasgasto.subempresas;
export const getSubempresasStatus = (state) => state.hojasgasto.subempresasStatus;
export const selectTipos = (state) => state.hojasgasto.tipos;
export const getTiposStatus = (state) => state.hojasgasto.tiposStatus;
export const selectHojasGasto = (state) => state.hojasgasto.hojas;
export const getHojasStatus = (state) => state.hojasgasto.hojasStatus;
export const getCrearStatus = (state) => state.hojasgasto.crearStatus;
export const getCrearError = (state) => state.hojasgasto.crearError;
export const selectGrupoGastos = (state) => state.hojasgasto.grupoGasto;
export const getGastoError = (state) => state.hojasgasto.gastoError;
export const statusLineaGastos = (state) => state.hojasgasto.lineaGastosStatus;
export const lineaGastosError = (state) => state.hojasgasto.lineaGastosError;
export const deleteLineaGastosError = (state) => state.hojasgasto.borrarLineaError;
export const deleteLineaGastosStatus = (state) => state.hojasgasto.borrarLineaStatus;
export const deleteLinGastosDocStatus = (state) => state.hojasgasto.borrarDocStatus;
export const deleteLinGastosDocError = (state) => state.hojasgasto.borrarDocError;
export const getLineasGasto = (state) => state.hojasgasto.hojas.lineas;
export const getEditarLineaStatus = (state) => state.hojasgasto.editarLineaStatus;
export const getEditarLineaError = (state) => state.hojasgasto.editarLineaError;
export const totalHojas = (state) => state.hojasgasto.totalHojas;
export const nextPag = (state) => state.hojasgasto.nextPag
export const prevPag = (state) => state.hojasgasto.prevPag

export default hojasGastoSlice.reducer;
