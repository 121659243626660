import {
  AppsOutage,
  PeopleOutline,
  QuizOutlined,
  Link,
  Edit,
  Newspaper,
  Campaign,
  FolderSharedOutlined,
  SourceOutlined,
  CalendarMonthOutlined,
  LogoutOutlined,
  RequestPageOutlined,
  PersonOutlineOutlined,
  DescriptionOutlined,
  HomeOutlined,
  Badge,
  AccountTree,
  FileCopyOutlined,
  CloudCircle
} from '@mui/icons-material'

const IconsList = {
  home: HomeOutlined,
  user: PersonOutlineOutlined,
  logout: LogoutOutlined,
  calendar: CalendarMonthOutlined,
  documentos: SourceOutlined,
  nomina: RequestPageOutlined,
  documento: DescriptionOutlined,
  personal: FolderSharedOutlined,
  comunicacion: Campaign,
  editar: Edit,
  noticias: Newspaper,
  enlaces: Link,
  preguntas: QuizOutlined,
  organigrama: AccountTree,
  users: PeopleOutline,
  solicitudes: AppsOutage,
  badge: Badge,
  firmaFicheros: FileCopyOutlined,
  //nombre menu : CloudCircle
}

export default IconsList
