import { createTheme } from '@mui/system'
import ThemesFacade from './theme/ThemesFacade'

class ThemeManager {
  /**@type {ThemeController}*/
  activeTheme = null
  /**@type {ThemeOptions}*/
  options = {}
  /**@type {EdisaTheme}*/
  pallete = null
  /**@type {ThemeComponents}*/
  components = null
  pages = null
  components_style = null
  activePallete = null
  /**@type {ThemeHideSections}*/
  hide = null
  /**@type {LogoComponents} */
  logos = {}
  /**@type {ThemeRoutes} */
  routes = []
  /**@type {import('@mui/material').ThemeOptions} */
  mui_theme = null

  constructor() {
    const facade = new ThemesFacade()
    this.activeTheme = facade.activeTheme ?? {}
    this.options = facade.activeTheme?.options ?? {}
    this.components = facade.activeTheme?.components ?? {}
    this.pallete = facade?.activePallete()
    this.activePallete = facade.activePallete
    this.components_style = facade.activeTheme?.components_styles
    this.hide = facade.activeTheme.hide ?? {}
    this.activePallete = facade.activePallete ?? {}
    this.components_style = facade.activeTheme?.components_styles ?? {}
    this.logos = facade.activeTheme?.logos ?? {}
    this.routes = facade.activeTheme?.routes ?? []
    this.mui_theme = createTheme({
      palette: this.activePallete(),
      components: this.components_style,
    })
  }
}

export default ThemeManager
