import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import useFetchDocs from '../hooks/useFetchDocs'
import { useState, useEffect } from 'react'
import DocumentSkeleton from './DocumentListSkeleton'

import TaskIcon from '@mui/icons-material/Task'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import './css/ListadoDocumentos.css'
import {
  Alert,
  Autocomplete,
  SpeedDial,
  SpeedDialAction,
  TextField,
  Card,
  Button,
  Snackbar,
} from '@mui/material'
import * as Constants from './Constants'
import SearchIcon from '@mui/icons-material/Search'

import TarjetaDocumento from './TarjetaDocumento'
import DocumentDialogs from './DocumentDialogs'

const ListadoDocumentos = () => {
  const defaultErrorState = {
    error: false,
    code: 0,
    msg: '',
  }

  const [trigger, setTrigger] = useState(null)
  const { response, loading } = useFetchDocs(trigger, setTrigger)
  const [ficheros, setFicheros] = useState(response)
  const [ficherosBuscador, setFicherosBuscador] = useState({
    labe: String,
    id: Number,
  })
  const [selectedDocs, setSelectedDocs] = useState(new Set())
  const [filtro, setFiltro] = useState('sinFirmar')
  const [textoFiltro, setTextoFiltro] = useState('Sin Firmar')
  const [action, setAction] = useState('')
  const [busqueda, setBusqueda] = useState('')
  const [hayFicherosParaFirmar, setHayFicherosParaFirmar] = useState(false)
  const [textoTipoFirma, setTextoTipoFirma] = useState('')
  const [openFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => setOpenFilter(true)
  const handleCloseFilter = () => setOpenFilter(false)
  const [errorStatus, setErrorStatus] = useState(defaultErrorState)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (response !== undefined) {
      if (response.length > 0) {
        setFicheros(response)
        setSelectedDocs(new Set())
        let paraFirmar = response.find((e) => e.puede_firmar === 'S')
        setHayFicherosParaFirmar(paraFirmar ? true : false)
        if (Constants.TIPOS_FIRMA_WEB_SIN_DF.includes(response[0].modo_firma)) {
          setTextoTipoFirma(
            'Debe descargar el documento, firmarlo por sus medios y aceptar el expediente adjuntando el fichero firmado.',
          )
        } else if (
          Constants.TIPOS_FIRMA_WEB_CON_DF.includes(response[0].modo_firma) &&
          response[0].permitir_adjuntar_documentos === 'N'
        ) {
          setTextoTipoFirma(
            'Debe pulsar el botón de firmar y validar su firma con un doble factor.',
          )
        } else if (
          Constants.TIPOS_FIRMA_WEB.includes(response[0].modo_firma) &&
          response[0].permitir_adjuntar_documentos === 'S'
        ) {
          setTextoTipoFirma(
            'Debe usted descargar el documento, firmarlo por sus medios y pulsar el botón firmar para subir el documento firmado y validar su firma con un doble factor.',
          )
        } else {
          setTextoTipoFirma('')
        }

        const ficherosTemp = response.map((item, index) => ({
          label:
            item.id_documento.split('-')[1] +
            ' ' +
            item.nombre_fichero.split('.')[0].replaceAll('_', ' '),
          id: index,
        }))

        if (ficherosTemp.length > 0) setFicherosBuscador(ficherosTemp)
      }
    }
  }, [response])

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorStatus(defaultErrorState)
  }

  const aplicarFiltros = () => {
    const filtrar = (ficheros) => {
      if (filtro === 'todos') {
        return ficheros
      } else if (filtro === 'firmados') {
        return ficheros.filter((fichero) => fichero.ha_firmado === 'S')
      } else if (filtro === 'sinFirmar') {
        return ficheros.filter((fichero) => fichero.ha_firmado === 'N')
      } else {
        return ficheros
      }
    }

    if (busqueda.trim() === '' || busqueda === undefined) {
      return filtrar(ficheros)
    } else {
      let ficherosFiltr = ficheros.filter((fichero) =>
        (fichero.id_documento.split('-')[1] + ' ' + fichero.nombre_fichero.replaceAll('_', ' '))
          .toLocaleLowerCase()
          .includes(busqueda.toLocaleLowerCase()),
      )
      if (filtro !== '') {
        return filtrar(ficherosFiltr)
      } else {
        return ficherosFiltr
      }
    }
  }

  const alertSinFicheros = (
    <Box sx={{ flexDirection: 'column' }}>
      <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
        Sin documentos para firmar.
      </Alert>
    </Box>
  )

  return (
    <>
      {!loading && ficheros !== undefined && typeof ficheros === 'object' && ficheros.length > 0 ? (
        <>
          <div className="gestion-documentos-buscador">
            <Autocomplete
              freeSolo
              onInputChange={(event, newInputValue) => {
                setBusqueda(newInputValue)
              }}
              id="controllable-states-demo"
              options={ficherosBuscador}
              sx={{
                width: {
                  xs: '100%',
                  sm: '80%',
                  md: '50%',
                  lg: '40%',
                  xl: '40%',
                },
                margin: 'auto',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <p className="gestion-documentos-buscador-label">
                      <SearchIcon /> Buscar documento
                    </p>
                  }
                />
              )}
            />
            <Box
              sx={{
                position: 'fixed',
                bottom: 30,
                right: 30,
                width: 70,
                textAlign: 'center',
                zIndex: openFilter ? 999 : 0,
              }}
            >
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<FilterAltOutlinedIcon />}
                className="boton-filtros"
                onClose={handleCloseFilter}
                onOpen={handleOpenFilter}
              >
                <SpeedDialAction
                  key={action.name}
                  icon={<TaskIcon />}
                  tooltipTitle={
                    <p className={filtro === 'firmados' ? 'filtro-activo' : ''}>Firmados</p>
                  }
                  tooltipOpen
                  onClick={() => {
                    setFiltro('firmados')
                    setTextoFiltro('Firmados')
                  }}
                  className={filtro === 'firmados' ? 'filtro-activo' : ''}
                />
                <SpeedDialAction
                  key={action.name}
                  icon={<InsertDriveFileIcon />}
                  tooltipTitle={
                    <p className={filtro === 'sinFirmar' ? 'filtro-activo' : ''}>Sin&nbsp;firmar</p>
                  }
                  tooltipOpen
                  onClick={() => {
                    setFiltro('sinFirmar')
                    setTextoFiltro('Sin Firmar')
                  }}
                  className={filtro === 'sinFirmar' ? 'filtro-activo' : ''}
                />
                <SpeedDialAction
                  key={action.name}
                  icon={<ContentCopyOutlinedIcon />}
                  tooltipTitle={<p className={filtro === 'todos' ? 'filtro-activo' : ''}>Todos</p>}
                  tooltipOpen
                  onClick={() => {
                    setFiltro('todos')
                    setTextoFiltro('Todos')
                  }}
                  className={filtro === 'todos' ? 'filtro-activo' : ''}
                />
              </SpeedDial>
              <Typography
                variant="body2"
                sx={{ position: 'relative', 'text-align': 'center', top: 10 }}
              >
                {textoFiltro}
              </Typography>
            </Box>
          </div>

          {textoTipoFirma && (filtro === 'sinFirmar' || filtro === 'todos') ? (
            <Box sx={{ flexDirection: 'column' }}>
              <Alert sx={{ margin: '5px 0px 10px 0px' }} variant="outlined" severity="info">
                {textoTipoFirma}
              </Alert>
            </Box>
          ) : !hayFicherosParaFirmar ? (
            alertSinFicheros
          ) : null}

          <Grid container spacing={2} sx={{ marginBottom: '120px' }}>
            {aplicarFiltros()?.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                <TarjetaDocumento
                  item={item}
                  index={index}
                  setAction={setAction}
                  action={action}
                  setTrigger={setTrigger}
                  selectedDocs={selectedDocs}
                  setSelectedDocs={setSelectedDocs}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : !hayFicherosParaFirmar && !loading ? (
        alertSinFicheros
      ) : (
        <DocumentSkeleton spacing={2} />
      )}

      {selectedDocs.size > 1 && (
        <Card
          variant="outlined"
          sx={{
            position: 'fixed',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            bottom: 30,
            left: 300,
            textAlign: 'center',
          }}
        >
          <Box sx={{ padding: '20px 50px' }}>
            <Button onClick={() => setModalOpen(true)}>
              firmar todos los documentos seleccionados
            </Button>
            <DocumentDialogs
              setErrorStatus={setErrorStatus}
              docData={ficheros.filter((doc) => selectedDocs.has(doc.id_documento))}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              action={'F'}
              setTrigger={setTrigger}
            />
          </Box>
        </Card>
      )}

      {errorStatus.error ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorStatus.error}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={errorStatus.code > 300 ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {errorStatus.msg}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  )
}

export default ListadoDocumentos
