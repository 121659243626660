import { Description } from '@mui/icons-material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Stack, Grid, Paper, Box, Typography, Button, IconButton, Chip } from '@mui/material'

/**
 * @component
 * @param {Object} props
 * @param {PersonalDocumentDtoArray} props.documents
 * @param {string} props.levelName
 * @param {Function} props.setCurrentLevel
 * @return <DocumentsView documents={documents} />
 */
const DocumentsView = ({ documents, levelName, setCurrentLevel, hideNav = false }) => {
  return (
    <Paper sx={{ p: 2 }}>
      {hideNav ? (
        <></>
      ) : (
        <Box sx={{ mb: 2 }}>
          <IconButton sx={{ mr: 2 }} onClick={() => setCurrentLevel(null)}>
            <ArrowBack />
          </IconButton>
          Regresar
        </Box>
      )}
      <Grid container spacing={1} flexGrow={1}>
        {documents?.length > 0 ? (
          documents.map((document, idx) => (
            <Grid key={idx} item xl={2} md={2} sm={3} xs={6}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyItems: 'center',
                  transtion: '.3s !important',
                  zIndex: '2',
                  p: 2,
                  width: '100%',
                  position: 'relative',
                  '&::before': {
                    transition: 'ease-in-out .3s',
                    display: 'block',
                    zIndex: '1',
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    borderRadius: '5px',
                    width: '100%',
                    opacity: 0.08,
                  },
                  '&:hover::before': {
                    backgroundColor: 'primary.main',
                  },
                  '&:hover': {
                    // backgroundColor: 'primary.main',
                  },
                }}
              >
                <Description color="primary" sx={{ fontSize: '6em', zIndex: '2', mb: 1, mt: 1 }} />
                <Typography sx={{ zIndex: 2, mb: 1 }} variant="title">
                  {document.descripcion}
                </Typography>
                {document.fecha_documento ? (
                  <Typography sx={{ zIndex: 2, mb: 1 }} variant="caption">
                    {document.fecha_documento}
                  </Typography>
                ) : (
                  <></>
                )}
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  sx={{ zIndex: 2, width: '100%', marginTop: 'auto' }}
                >
                  Descargar
                </Button>
              </Box>
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Paper>
  )
}

export default DocumentsView
