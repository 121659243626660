import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import useFetchCorporativa from '../hooks/useFetchCorporativa'
import CorporateDocsCard from './CorporateDocsCard'
import { useEffect, useState } from 'react'
import ScrollDetector from 'common/components/ScrollDetector'
import { LinearProgress } from '@mui/material'
import ThemeManager from 'theming/ThemeManager'

/**
 * Muestra la documentacion corporativa
 *
 * @param {Object} props
 * @param {number | string} [props.document_tipe]
 */
const Corporativa = ({ document_type = null }) => {
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const { response, loading } = useFetchCorporativa(page, document_type)
  const Theme = new ThemeManager()

  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (!loading && response.results && page !== lastPage) {
      if (response.next) setLastPage(lastPage + 1)

      setDocuments(response.previous ? [...documents, ...response.results] : [...response.results])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const FULL_WIDTH_ITEM = Theme.options?.corporateDocsOptions?.fullWidthItem
  const GRID_SPACING = Theme.options?.corporateDocsOptions?.spacing ?? 2
  const CustomCard = Theme.components?.CorporateDocsCard

  return (
    <Box key="documentos" sx={{ flexGrow: 1 }}>
      <Grid container spacing={GRID_SPACING}>
        {documents.length > 0 ? (
          documents?.map((document, idx) => (
            <Grid
              key={idx}
              item
              xs={12}
              sm={FULL_WIDTH_ITEM ? 12 : 6}
              md={FULL_WIDTH_ITEM ? 12 : 4}
            >
              {CustomCard ? (
                <CustomCard document={document} />
              ) : (
                <CorporateDocsCard document={document} />
              )}
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
      {loading ? <LinearProgress sx={{ mt: 2 }} /> : <></>}
      <ScrollDetector
        show={!loading && response?.next !== null && page === lastPage}
        onElementIsVisible={() => {
          if (response?.next && response?.next !== null) setPage(page + 1)
        }}
      />
    </Box>
  )
}

export default Corporativa
