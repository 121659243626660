import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import CustomTable from 'common/components/CustomTable'
import { getFechaFromISO } from 'common/libs/fechas'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { descargarDocumento } from 'tool/funciones'
import useFetchLaboral from '../hooks/useFetchLaboral'
import { selectPortal } from 'features/portal/portalSlice'
import { useSelector } from 'react-redux'

const LaboralTableView = () => {
  const location = useLocation()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral(user)
  const portal = useSelector(selectPortal)

  return (
    <CustomTable
      sx={{ mt: 2 }}
      hideHeader={false}
      dataSource={response ?? []}
      showToolbar={true}
      height="fit-content"
      initialPageSize={15}
      loading={loading}
      density={'comfort'}
      fields={[
        {
          value: 'd_rh_documento',
          key: 'Titulo',
          sortable: true,
          filterable: true,
          flex: 1.5,
          width: 150,
        },
        {
          sortable: true,
          filterable: true,
          value: 'fecha_inicio',
          customGetter: (field) =>
            getFechaFromISO(field.row.nom_inicio, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
          key: 'Fecha Inicio',
          width: 150,
          flex: 1,
        },
        {
          value: 'fecha_fin',
          customGetter: (field) =>
            getFechaFromISO(field.row.nom_fin, portal?.locale?.replaceAll('-', '_') ?? 'es-ES'),
          key: 'Fecha Fin',
          width: 150,
          flex: 1,
        },
        {
          value: 'actions',
          key: 'Descargar',
          width: 150,
          flex: 1,
          renderCell: (document) => {
            return (
              <Box sx={{ width: '100%', display: 'flex', placeItems: 'center', gap: '2px' }}>
                <Button
                  sx={{ width: '100%' }}
                  variant="contained"
                  startIcon={<Download />}
                  onClick={() => {
                    descargarDocumento(document.tipo_doc_principal, document.id)
                  }}
                >
                  PDF
                </Button>
                {document.has_xml > 0 ? (
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      descargarDocumento(document.tipo_doc_secundario, document.id)
                    }}
                    startIcon={<Download />}
                  >
                    XML
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            )
          },
        },
      ]}
    />
  )
}

export default LaboralTableView
