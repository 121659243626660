import CalendarioAnual from '../components/CalendarioAnual'
import WidgetsCalendario from '../components/WidgetsCalendario'

import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  selectVacionesPendientes,
  selectVacionesTipoDias,
} from 'features/solicitudes/solicitudesSlice'

/**
 *
 * @param {Object} props - [TODO:description]
 * @param {string} [props.requestUser = null] - [TODO:description]
 * @param {[TODO:type]} [[TODO:name].fechasSolicitud] - [TODO:description]
 * @param {[TODO:type]} [[TODO:name].requestUser] - [TODO:description]
 * @returns {[TODO:type]} [TODO:description]
 */
const Calendario = ({ vistaAmpliada = true, fechasSolicitud = [], requestUser = null }) => {
  const location = useLocation()
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [user, setUser] = useState(requestUser ? requestUser : location?.state?.user ?? null)

  const vacacionesPendientes = useSelector(selectVacionesPendientes)
  const vacacionesTipoDias = useSelector(selectVacionesTipoDias)

  return (
    <Stack spacing={2}>
      <WidgetsCalendario useUser={[user, setUser]} currentYear={currentYear} />
      {vistaAmpliada ? (
        <>
          <BotonSolicitudInterna
            titulo="Solicitar Vacaciones"
            id_solicitud_pe="VAC01"
            buttonProps={{
              sx: {
                transition: '.3s',
                textAlign: 'center',
                width: {
                  md: '30em',
                  xs: '100%',
                },
              },
            }}
          />
          <CalendarioAnual
            useUser={[user, setUser]}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </>
      ) : (
        <>
          <Divider sx={{ mt: 2, mb: 2 }} />
          {vacacionesPendientes ? (
            <CalendarioAnual
              useUser={[user, setUser]}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              vacacionesPendientes={vacacionesPendientes}
              vacacionesTipoDias={vacacionesTipoDias}
              allowSelection={true}
              fechasSolicitud={fechasSolicitud}
            />
          ) : null}
        </>
      )}
    </Stack>
  )
}

export default Calendario
