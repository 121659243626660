import { useEffect, useState } from 'react'
import useFetchArbol from '../hooks/useFetchArbol'
import FolderView from './FolderView'
import useFetchLevelDocs from '../hooks/useFetchLevelDocs'
import DocumentsView from './DocumentsView'
import { LinearProgress, Box, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import CustomUserCard from 'features/users/components/CustomUserCard'

/**
 * Personal.
 *
 * @param {Object} params
 * @param {string} [params.forcedLevel] The default Level
 */
const Personal = ({ forcedLevel = null }) => {
  const location = useLocation()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchArbol({ user })
  const [/** @type {string} */ docLevel, setDocLevel] = useState(/**@type {string}*/ null)
  const { response: responseDocs, loading: loadingDocs } = useFetchLevelDocs(
    forcedLevel ?? docLevel?.codigo_nivel ?? null,
  )

  const [arbol, setArbol] = useState([])
  const [documents, setDocuments] = useState(responseDocs)

  const [isLoading, setIsLoading] = useState(loading)
  const [isLoadingDocs, setIsLoadingDocs] = useState(loadingDocs)

  useEffect(() => setIsLoading(loading), [loading])
  useEffect(() => setIsLoadingDocs(loadingDocs), [loadingDocs])
  useEffect(() => setDocuments(responseDocs), [responseDocs])
  useEffect(() => setArbol(response), [response])

  return (
    <>
      {user ? (
        <>
          <CustomUserCard
            user={user}
            loading={isLoadingDocs || isLoading}
            returnToUser={() => setUser(null)}
          />
          <Divider sx={{ mt: 1, mb: 1, opacity: 0 }} />
        </>
      ) : (
        <></>
      )}
      {(isLoadingDocs || isLoading) && !user ? <LinearProgress sx={{ mb: 2 }} /> : <></>}
      {documents?.length > 0 ? (
        <DocumentsView documents={documents} hideNav={forcedLevel} setCurrentLevel={setDocLevel} />
      ) : (
        <></>
      )}
      <Box sx={{ display: documents.length > 0 ? 'none' : 'block' }}>
        <FolderView setCurrentLevel={setDocLevel} tree={arbol} />
      </Box>
    </>
  )
}

export default Personal
