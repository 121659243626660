import { CheckRounded, DownloadForOfflineOutlined, Warning } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  LinearProgress,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material'
import FilesHelper from 'common/libs/Files'
import { useState } from 'react'
import { markDocumentAsReaded } from '../libs/markDocumentAsReaded'
import { enqueueSnackbar } from 'notistack'
import ThemeManager from 'theming/ThemeManager'

/**
 * Component that renders and download a corporate document
 *
 * @param {Object} props
 * @param {DocCorporativaDto} props.document - The document to show
 */
const CorporateDocsCard = ({ document }) => {
  const [isMarking, setIsMarking] = useState(false)
  const [displayDocument, setDisplayDocument] = useState(document)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [NeedReading, SetNeedsReading] = useState(displayDocument.obligatorio && !document.leido)

  return (
    <Box>
      <Card
        sx={{
          backgroundColor: !NeedReading ? '' : 'error.main',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader
          title={
            <Typography
              sx={{
                fontSize: '1.0em',
                mb: 0,
                color: NeedReading ? 'error.contrastText' : '',
              }}
            >
              {displayDocument.descripcion}
            </Typography>
          }
          action={
            NeedReading ? (
              <Tooltip title="Lectura Obligatoria">
                <Warning sx={{ color: 'error.contrastText', mr: 1 }} />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
        <CardActions sx={{ mt: 'auto', pt: '0', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {NeedReading ? (
              <Button
                onClick={handleOpen}
                sx={{
                  color: NeedReading ? 'error.contrastText' : '',
                }}
                endIcon={<CheckRounded />}
              >
                Leido
              </Button>
            ) : (
              <></>
            )}
          </Box>
          {displayDocument.tipo_doc && document.tipo_doc > 0 ? (
            <Button
              onClick={() => FilesHelper.downloadFile(displayDocument.id, document.tipo_doc)}
              sx={{
                color: NeedReading ? 'error.contrastText' : '',
              }}
              endIcon={<DownloadForOfflineOutlined />}
            >
              Descargar
            </Button>
          ) : (
            <></>
          )}
        </CardActions>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            transition: '.3s',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '30%',
            width: {
              xs: '95%',
              md: '30%',
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Marcar como leido
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            ¿Está seguro de que desea marcar como leído este documento?
            <br />
            <b>Con esta acción está reconociendo haber leído el documento completo.</b>
          </Typography>
          {isMarking ? <LinearProgress /> : <></>}
          <Box
            sx={{
              display: 'flex',
              gap: '1em',
              width: '100%',
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <Button
              disabled={isMarking}
              onClick={async () => {
                try {
                  setIsMarking(true)
                  await markDocumentAsReaded(displayDocument.id)
                  SetNeedsReading(false)
                  setDisplayDocument({
                    ...displayDocument,
                    obligatorio: false,
                  })
                  displayDocument.obligatorio = false
                  setIsMarking(false)
                  handleClose()
                } catch (error) {
                  enqueueSnackbar('No se pudo marcar como leido el documento', {
                    variant: 'error',
                  })
                  setIsMarking(false)
                }
              }}
              variant="contained"
            >
              Si
            </Button>
            <Button disabled={isMarking} onClick={handleClose} variant="outlined">
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default CorporateDocsCard
