import { Box } from '@mui/system'
import { useState } from 'react'
import ThemeManager from 'theming/ThemeManager'
import useFetchNewsMeta from '../hooks/useFetchNewsMeta'
import CategoryFilter from './CategoryFilter'
import TagsFilter from './TagsFilter'

/**
 * Filter the news
 *
 * @param {Object} props
 * @param {onCategorySelectedCallback} [props.onCategorySelected] - Event triggered when the user selects a category
 * @param {Array<NewsTags>} [props.selectedTags] - Array of the selected tags
 * @param {onTagSelected} [props.onTagSelected] - Event triggered when the user selects a tag
 * @example
 *
 * <NewsFilter onCategorySelected={(category) => console.log(category)}/>
 */
const NewsFilter = ({ onCategorySelected, onTagSelected, selectedTags }) => {
  const { tags, categories } = useFetchNewsMeta()
  const Theme = new ThemeManager()
  const themeFilter = Theme.components?.NewsFilter

  return (
    themeFilter ?? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TagsFilter tags={tags} onTagSelected={onTagSelected} selectedTags={selectedTags} />
        <CategoryFilter categories={categories} onCategorySelected={onCategorySelected} />
      </Box>
    )
  )
}

export default NewsFilter
