import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  TextField,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import CommentsStepper from './ComentsStepper'
import Button from '@mui/material/Button'
import React from 'react'
import * as Constants from './Constants'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import DocsController from '../libs/DocsController'
import { styled } from '@mui/material/styles'
import { json } from 'react-router-dom'

const acciones = {
  F: {
    title: 'Firmar documento',
    text: '',
    placeholder:
      'Si desea aportar alguna observación a la firma de este documento, indíquelas aquí',
    actionTrue: 'Confirmar',
    actionFalse: 'Cancelar',
  },
  R: {
    title: 'Rechazar documento',
    text: '¿Estás seguro de que deseas rechazar este documento? Si es así, introduce observaciones',
    placeholder:
      'Si desea aportar alguna observación a la firma de este documento, indíquelas aquí',
    actionTrue: 'Confirmar',
    actionFalse: 'Cancelar',
  },
  O: {
    title: 'Observaciones',
    text: 'Añadir observaciones',
    placeholder: 'Observaciones',
    actionTrue: 'Confirmar',
    actionFalse: 'Cancelar',
  },
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`

const DocumentDialogs = ({
  setErrorStatus,
  docData,
  modalOpen,
  setModalOpen,
  action,
  setTrigger,
}) => {
  const [observaciones, setObservaciones] = React.useState('')
  const [authMethod, setAuthMethod] = React.useState('')
  const [authToken, setAuthToken] = React.useState('')
  const [tokenInputVisible, setTokenInputVisible] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState(null)

  const handleCloseModal = () => {
    setObservaciones('')
    setTokenInputVisible(false)
    setAuthToken('')
    setModalOpen(false)
    setSelectedFile(null)
  }

  const getTokenAction = async () => {
    setTokenInputVisible(true)
    let response = await DocsController.sendToken(
      authMethod || docData[0].tipo_df_preferido || docData[0].tipos_df_list[0]?.tipo || '',
    )

    setErrorStatus({
      error: true,
      code: response.status,
      msg: response.data,
    })
  }

  const handleSubmitForm = async (action) => {
    if (action === 'O' || action === 'R' || (action === 'F' && docData.length > 1)) {
      sendData()
    } else if (action === 'F') {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryData = e.target.result
        sendData(binaryData)
      }
      reader.readAsBinaryString(selectedFile)
    }
  }

  const sendData = async (file_data) => {
    let jsonData = {}
      
    if (file_data !== undefined) {
      jsonData['file'] = selectedFile
      jsonData['file_content'] = btoa(file_data)
      jsonData['sube_fichero'] = selectedFile !== null ? 'S' : 'N'
      jsonData['nombre'] = selectedFile.name
    }
    jsonData['tipo_gestion'] = action
    jsonData['token'] = authToken
    jsonData['observaciones'] = observaciones

    jsonData['documentos'] = docData.map((item) => ({ id_documento: item.id_documento }))

    jsonData['modo_firma'] = docData.map((item) => {
      return item.modo_firma
    })
    
    if(docData.length === 1)
      jsonData['id_documento'] = docData[0].id_documento;

    let response = await DocsController.sign(jsonData)
    setTrigger(true)
    setErrorStatus({
      error: true,
      code: response.status,
      msg: response.data,
    })
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
  }

  const isDisabled = () => {
   
    switch (action) {
      case 'O':
        return observaciones?.trim().length <= 0
      case 'F':
        if (
          docData.length < 1 &&
          docData[0]?.modo_firma === Constants.MODO_FIRMA_SIN_FIRMA_DOCUMENTOS
        ) {
          return false
        }

        let allowSign = true

        if (
          docData.some((doc) => Constants.TIPOS_FIRMA_WEB_CON_DF.includes(doc.modo_firma)) &&
          authToken?.trim().length <= 0
        ) {
          allowSign = false
        }

        if (docData.length === 1 && docData[0]?.permitir_adjuntar_documentos === 'S') {
          allowSign = selectedFile === null
        }
        
        return allowSign
      default:
        return false
    }
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={() => {
        handleCloseModal()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {docData.length <= 1 ? acciones[action]?.title : 'Firmar documentos'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {acciones[action]?.text}
        </DialogContentText>
        {action === 'O' && (
          <div style={{ overflow: 'auto' }}>
            <CommentsStepper
              commentsChat={docData[0]?.linea_observaciones_chat_list}
              commentsObs={docData[0]?.linea_observaciones_documento_list}
            />
          </div>
        )}
        <TextField
          autoFocus
          InputLabelProps={{ shrink: true }}
          multiline
          margin="dense"
          required={action === 'O'}
          id="name"
          label="Observaciones"
          placeholder={
            docData.length <= 1
              ? acciones[action]?.placeholder
              : 'Si desea aportar alguna observación a la firma de estos documentos, indíquelas aquí'
          }
          type="text"
          fullWidth
          variant="standard"
          value={observaciones}
          onChange={(event) => {
            setObservaciones(event.target.value)
          }}
        />
        {docData.length === 1 &&
          docData[0]?.permitir_adjuntar_documentos === 'S' &&
          Constants.TIPOS_FIRMA_WEB.includes(docData[0].modo_firma) &&
          action === 'F' && (
            <>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                href="#file-upload"
                sx={{ marginTop: '1rem' }}
              >
                Adjuntar archivo firmado
                <VisuallyHiddenInput type="file" name="file" onChange={handleFileChange} />
              </Button>
              {selectedFile !== undefined && selectedFile !== null ? (
                <p style={{ marginTop: '1rem', color: 'gray' }}>
                  Archivo seleccionado: {selectedFile.name}
                </p>
              ) : (
                <></>
              )}
            </>
          )}
        {docData.some((doc) => Constants.TIPOS_FIRMA_WEB_CON_DF.includes(doc.modo_firma)) &&
          action === 'F' && (
            <>
              <DialogContentText id="alert-dialog-description" sx={{ marginTop: '2rem' }}>
                Escoge un método de verificación:
              </DialogContentText>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  defaultValue={
                    docData[0].tipo_df_preferido !== undefined
                      ? docData[0].tipo_df_preferido
                      : docData[0].tipos_df_list[0]?.tipo || ''
                  }
                  variant="standard"
                  onChange={(e) => {
                    setAuthMethod(e.target.value)
                  }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {docData[0].tipos_df_list.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.tipo}>
                        {item.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <Button
                sx={{ marginLeft: '2rem' }}
                onClick={() => {
                  getTokenAction()
                }}
                autoFocus
              >
                Enviar token
              </Button>
              {tokenInputVisible && (
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  label="Código de confirmación"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event) => {
                    setAuthToken(event.target.value)
                  }}
                />
              )}
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleCloseModal()
          }}
          autoFocus
        >
          {acciones[action]?.actionFalse}
        </Button>
        <Button
          onClick={() => {
            handleSubmitForm(action)
            handleCloseModal()
          }}
          disabled={isDisabled()}
          sx={{ color: 'red' }}
        >
          {acciones[action]?.actionTrue}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DocumentDialogs
