import ROUTE_COMPONENTS from './routes'
import { Route } from 'react-router-dom'

/**
 *@param {Menu} menu
 */
export const iterar_menus = (menu) => {
  if (menu.programa) {
    const path = `/${menu.camino ?? menu.programa}`
    const Element = ROUTE_COMPONENTS[menu.programa]
    return (
      <Route
        key={menu.programa}
        exact
        path={path}
        element={<Element object={menu.objeto_programa} />}
      />
    )
  } else {
    if (menu.hijos.length > 0) {
      return menu.hijos.map(iterar_menus)
    } else {
      return null
    }
  }
}

/** @typedef {object} Menu
 * @property {number} id
 * @property {string} codigo
 * @property {string} nombre
 * @property {null} camino
 * @property {string} programa
 * @property {null} objeto_programa
 * @property {null} password
 * @property {string} cod_padre
 * @property {null} opcion_mmb
 * @property {null} subopcion_mmb
 * @property {null} icono
 * @property {string} modo_compatible
 * @property {null} titulo_ventana
 * @property {null} nodo
 * @property {null} id_personalizacion
 * @property {number} orden
 * @property {number} version
 * @property {null} plantilla_programa
 * @property {string} mostrar_expandido
 * @property {string} activo
 * @property {string} visible
 * @property {string} publico
 * @property {null} observaciones
 * @property {null} tipo_opcion
 * @property {string} idioma
 * @property {} hijos
 */

export default iterar_menus
