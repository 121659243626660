import CheckIcon from '@mui/icons-material/Check'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import UndoIcon from '@mui/icons-material/Undo'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'
import axios from 'api/axios'

const AccionesSolicitud = ({ codigo_solicitud, origen_solicitud, acciones }) => {
  const navigate = useNavigate()
  const [accion, setAccion] = useState(null)
  const [mensaje, setMensaje] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogTextButton, setDialogTextButton] = useState(null)
  const [dialogColorButton, setDialogColorButton] = useState(null)
  const [dialogIconButton, setDialogIconButton] = useState(null)
  const assets_accion = {
    A: {
      icono: <CheckIcon />,
      texto: 'Aceptar',
      color: 'success',
      confirmacion: false,
    },
    R: {
      icono: <DoDisturbIcon />,
      texto: 'Rechazar',
      color: 'error',
      confirmacion: true,
    },
    D: {
      icono: <UndoIcon />,
      texto: 'Devolver',
      color: 'warning',
      confirmacion: true,
    },
    B: {
      icono: <EditIcon />,
      texto: 'Editar',
      color: 'primary',
      confirmacion: false,
    },
    E: {
      icono: <DeleteForeverIcon />,
      texto: 'Eliminar',
      color: 'error',
      confirmacion: false,
    },
  }

  const ejecutarAccion = () => {
    setOpenBackdrop(true)

    const datos = {
      codigo_solicitud,
      origen_solicitud,
      accion,
      mensaje,
    }
    axios
      .patch(`/solicitudpendiente/validar/`, datos)
      .then((response) => {
        console.log(response.data)
        enqueueSnackbar(`Solicitud actualizada`)
        navigate('/pendientes/')
      })
      .catch((e) => enqueueSnackbar(`Se ha producido un error al validar la solicitud`))
      .finally(() => setOpenBackdrop(false))
  }

  const accionHandler = (e) => {
    setAccion(e.currentTarget.dataset.accion)
    const infoAccion = assets_accion[e.currentTarget.dataset.accion]

    if (infoAccion.confirmacion) {
      setDialogTextButton(infoAccion.texto)
      setDialogColorButton(infoAccion.color)
      setDialogIconButton(infoAccion.icono)
      setOpenDialog(true)
      return
    }

    ejecutarAccion()
  }

  const handleConfirmDialog = () => {
    setOpenDialog(false)
    ejecutarAccion()
  }

  const handleDismissDialog = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleDismissDialog}>
        <DialogTitle>Observaciones</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aquí puede añadir alguna informción extra para el usuario
          </DialogContentText>
          <TextField
            autoFocus
            id="observaciones"
            label="Observaciones"
            type="text"
            fullWidth
            variant="standard"
            value={mensaje}
            onChange={(e) => {
              setMensaje(e.target.value)
            }}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismissDialog}>Cancelar</Button>
          <Button
            color={dialogColorButton}
            startIcon={dialogIconButton}
            onClick={handleConfirmDialog}
          >
            {dialogTextButton}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarProvider
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{' '}
      {acciones.map((a) => {
        return (
          <Button
            variant="contained"
            color={assets_accion[a].color}
            startIcon={assets_accion[a].icono}
            data-accion={a}
            onClick={accionHandler}
          >
            {assets_accion[a].texto}
          </Button>
        )
      })}
    </>
  )
}

export default AccionesSolicitud
