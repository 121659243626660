// @ts-check
import React from 'react'
import { CircularProgress, Box, LinearProgress } from '@mui/material'
import back from 'assets/images/wallpaper.login.png'
import { useLocation } from 'react-router-dom'

const LoginBackground = ({ children, loading = true }) => {
  const imageBg = {
    backgroundColor: 'background.default',
    backgroundImage: `url(${back})`,
    background: `linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.40)), url(${back}) no-repeat`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  const white = {
    backgroundColor: 'background.default',
  }

  const bg = loading ? white : imageBg
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 1,
        transition: '.3s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...bg,
      }}
    >
      <>{loading ? <CircularProgress /> : <>{children}</>}</>
    </Box>
  )
}

export default LoginBackground
