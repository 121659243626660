import { selectMenus } from 'features/portal/menusSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notifyUnreadedComunicados from './libs/notifyUnreadComunicados'
import notifyUnreadedDocs from './libs/notifyUnreadedDocs'

const AutomationController = () => {
  const menu = useSelector(selectMenus)
  const [launched, setLaunched] = useState(false)

  const navigate = useNavigate()

  const navigateTo = (url) => {
    navigate(url)
  }

  useEffect(() => {
    if (menu.length < 2 || launched) return
    if (!launched) {
      setLaunched(true)
      notifyUnreadedDocs(menu, navigateTo)
      notifyUnreadedComunicados(menu, navigateTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return <></>
}

export default AutomationController
