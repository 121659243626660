//@ts-check
import axios from 'api/axios'
import ThemeManager from 'theming/ThemeManager'
const { useState, useEffect } = require('react')

/**
 * @module FetchHooks
 */

/**
 * Hook that fetch the my_users_data
 * @function
 * @param {number} [page=1]
 * @param {number} [page_size=13]
 * @param {NewsCategories | undefined} [category]
 * @param {Array<NewsTags> | undefined} [tags]
 * @returns {import('common/types/connections_types').UseFetchHookResponse<NewsDTOResponse | undefined>}
 */
const useFetchNews = (page = 1, category, tags, page_size) => {
  const Theme = new ThemeManager()
  const size = Theme.options?.newsOptions?.pageSize ?? page_size
  /**
   * @type {[NewsDTOResponse | undefined, React.Dispatch<NewsDTOResponse> | undefined]} responseState
   */
  const [response, setResponse] = useState()

  /**
   * @type {[AxiosErr | null, React.Dispatch<AxiosErr> | undefined]} responseState
   */
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    if (loading) {
      return
    }
    setLoading(true)
    axios
      .get(
        `/noticias?page=${page}&page_size=${page_size ?? size}&c=${category?.id ?? ''}&t=${
          tags ? tags.map((t) => t.id).join(',') : ''
        }`,
      )
      .then((/**@type {import('axios').AxiosResponse<NewsDTOResponse>}*/ request_response) => {
        const data = request_response.data
        setResponse(data)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading) fetchData()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, category, tags, page_size])

  return { response, error, loading }
}

export default useFetchNews
