import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'
import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import { esES } from '@mui/x-date-pickers/locales'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import Grid from '@mui/material/Unstable_Grid2'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function DateRangeComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)

  const datos = useSelector(selectDatosSolicitud)

  const [open, setOpen] = React.useState(false)
  const [desde, setDesde] = React.useState({})
  const [hasta, setHasta] = React.useState({})
  const [encontradoDesde, setEncontradoDesde] = React.useState(false)
  const [valorDesde, setValorDesde] = React.useState()
  const [valorHasta, setValorHasta] = React.useState()

  if (!encontradoDesde) {
    let encontrado = false
    for (const c of campos) {
      if (c.idpk === idpk) {
        setDesde(c)
        setEncontradoDesde(true)
        encontrado = true
      }
      if (encontrado && c.tipo_dato === 'DH2') {
        setHasta(c)
        break
      }
    }
  }

  console.log('desde', desde.idpk)
  console.log('hasta', hasta.idpk)

  React.useEffect(() => {
    setValorDesde(datos[desde.idpk])
    setValorHasta(datos[hasta.idpk])
  }, [datos, desde, hasta])

  const handleOpen = (valor) => {
    if (!valor) {
      setOpen(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleChangeDesde = (nuevo_valor) => {
    setValorDesde(nuevo_valor)
    dispatch(setDatoSolicitud({ idpk: desde.idpk, valor: nuevo_valor }))
  }
  const handleChangeHasta = (nuevo_valor) => {
    setValorHasta(nuevo_valor)
    dispatch(setDatoSolicitud({ idpk: hasta.idpk, valor: nuevo_valor }))
    if (valorDesde) {
    } else {
      setOpen(true)
    }
  }

  if (desde && hasta) {
    return (
      <LocalizationProvider
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale="es"
      >
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            No puede seleccionar este campo hasta haber cubierto el anterior
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid key={`g-${desde.idpk}`} xs={12} sm={6} md={6}>
            <DatePicker
              key={`desde-${desde.codigo_campo}`}
              label={desde.etiqueta}
              value={valorDesde}
              onChange={(v) => handleChangeDesde(v)}
              slotProps={{
                textField: {
                  helperText: desde.ayuda,
                },
              }}
            />
          </Grid>
          <Grid key={`g-${hasta.idpk}`} xs={12} sm={6} md={6}>
            <DatePicker
              key={`hasta-${hasta.codigo_campo}`}
              label={hasta.etiqueta}
              value={valorHasta}
              onChange={(v) => handleChangeHasta(v)}
              onOpen={() => handleOpen(valorDesde)}
              slotProps={{
                textField: {
                  helperText: hasta.ayuda,
                },
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    )
  }
}

export default DateRangeComponent
