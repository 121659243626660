import DocsController from './DocsController'

export async function descargarDocumento(docData, responseType) {
  let response = await DocsController.download({
    token_acceso: docData.token_acceso,
    id_archivo: docData.id_archivo,
  })

  const binary = atob(response.data.fichero.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i)
  }

  const type = responseType === 'pdf' ? 'application/pdf' : 'application/octet-stream'
  const blob = new Blob([view], { type })

  return { url: URL.createObjectURL(blob), nombreArchivo: response.data.nombre_archivo }
}
