import axios from 'api/axios'
/**
 * Mark a document as readed
 *
 * @async
 * @param {number} document_id - The document id
 * @throws {Error} Can throw an error if the axios request fails
 */
export async function markDocumentAsReaded(document_id) {
  try {
    const response = await axios.post('doccorporativa/marcarlectura/ ', { id: document_id })
    if (response.status !== 200) throw new Error('No se pudo marcar el archivo como leido')
    return response.data
  } catch (error) {
    throw error
  }
}
