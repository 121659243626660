import { CardActionArea } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetSolicitud } from '../solicitudesSlice'

import {
  fetchListaSolicitudes,
  selectListaSolicitudesDisponibles,
  selectListaSolicitudesStatus,
  setSolicitudSeleccionada,
} from '../solicitudesSlice'

const ListaSolicitudes = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const solicitudes = useSelector(selectListaSolicitudesDisponibles)
  const loading = useSelector(selectListaSolicitudesStatus)

  useEffect(() => {
    dispatch(fetchListaSolicitudes())
    dispatch(setSolicitudSeleccionada({}))
  }, [dispatch])

  const handleClick = (e) => {
    const solicitud = solicitudes.filter(
      (s, i) => Number(i) === Number(e.currentTarget.dataset.solicitud),
    )[0]
    dispatch(resetSolicitud())
    navigate('/solicitud', { state: { solicitud } })
  }

  return (
    <Box key="solicitudes" sx={{ flexGrow: 1 }}>
      {!loading ? (
        <Stack spacing={2}>
          {solicitudes.map((solicitud, id) => {
            return (
              <Card key={id}>
                <CardActionArea data-solicitud={id} onClick={handleClick}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {solicitud.descripcion}
                    </Typography>
                    {solicitud.ayuda ? (
                      <Typography variant="body2" color="text.secondary">
                        {solicitud.ayuda}
                      </Typography>
                    ) : null}
                  </CardContent>
                </CardActionArea>
                {/* <CardActions>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                </CardActions> */}
              </Card>
            )
          })}
        </Stack>
      ) : (
        <Grid container spacing={2}>
          {[...Array(12).keys()].map((i) => {
            return (
              <Grid key={i} xs={12} sm={12} md={12}>
                <Card>
                  <CardContent>
                    <Skeleton key="s-titulo" animation="wave" height={30} width="100%" />
                    <Skeleton key="s-descri" animation="wave" height={10} width="100%" />
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Box>
  )
}

export default ListaSolicitudes
