import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import useFetchSolicitudInterna from '../hooks/useFetchSolicitudInterna'
import { addSolicitudALista } from '../solicitudesSlice'

/**
 * Button to star a libra request
 *
 * @param {Object} props - The react component props
 * @param {string} props.titulo - The request title
 * @param {string} props.id_solicitud_pe - The request id
 * @param {import('@mui/material').SxProps} [props.sx={}] - The MuiButton sx
 * @param {import('@mui/material').ButtonProps} [props.buttonProps={}] - The MuiButton props
 */
const BotonSolicitudInterna = ({
  titulo = 'Cambiar',
  id_solicitud_pe,
  buttonProps = {},
  sx = { width: '100%', maxWidth: 345 },
}) => {
  const dispatch = useDispatch()

  const { response, loading } = useFetchSolicitudInterna({ id_solicitud_pe })
  const [solicitud, setSolicitud] = useState({})

  useEffect(() => {
    setSolicitud(response[0])
    dispatch(addSolicitudALista(response[0]))
  }, [response])

  return loading ? (
    <></>
  ) : solicitud ? (
    <Button variant="contained" color="primary" sx={{ ...sx }} {...buttonProps}>
      <Link
        to="/solicitud"
        style={{
          textDecoration: 'none',
          color: 'inherit',
          width: '100%',
          display: 'block',
          textAlign: 'inherit',
        }}
        state={{ solicitud }}
      >
        {titulo}
      </Link>
    </Button>
  ) : (
    <></>
  )
}

export default BotonSolicitudInterna
