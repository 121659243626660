import { useEffect, useState } from 'react'

// import { selectCurrentYear, fetchWidgets, selectWidgets } from './calendarioSlice'

import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined'
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined'
import { Grid, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useFetchWidget from '../hooks/useFetchWidget'

import { setDatosVacaciones } from 'features/solicitudes/solicitudesSlice'
import { useDispatch } from 'react-redux'

const WidgetsCalendario = ({
  currentYear = new Date().getFullYear(),
  useUser = [null, () => {}],
}) => {
  const dispatch = useDispatch()

  const [user, _] = useUser
  const [widgets, setWidgets] = useState(null)
  const { response, loading } = useFetchWidget({ year: currentYear, user })

  useEffect(() => {
    if (response) {
      console.log({response});
      setWidgets(response)
      dispatch(
        setDatosVacaciones({ pendientes: response.pendientes, tipo_dias: response.tipo_dias }),
      )
    }
  }, [response])

  const box = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  const icono = {
    position: 'absolute',
    top: '-50px',
    left: '-50px',
    height: '120px',
    width: '120px',
    opacity: '0.2',
  }
  const card = {
    padding: '0 10px',
    backgroundColor: 'paper.dark',
    '&:hover': {
      backgroundColor: 'paper.main',
      opacity: [0.9, 0.8, 0.7],
    },
  }
  const cardRojo = {
    backgroundColor: 'error.dark',
    color: 'error.contrastText',
    '&:hover': {
      backgroundColor: 'error.main',
      opacity: [0.9, 0.8, 0.7],
    },
  }
  const cardVerde = {
    backgroundColor: 'success.dark',
    color: 'success.contrastText',
    '&:hover': {
      backgroundColor: 'success.main',
      opacity: [0.9, 0.8, 0.7],
    },
  }


  return( 
    <>
      {loading ? <LinearProgress /> : 
        <Grid container spacing={2}>
          {widgets?.solicitud_vacaciones_activa ? 
          <Grid item xs={6} md={3}>
            <Card sx={card}>
              <CardContent>
                <Box sx={box}>
                  <HelpOutlineSharpIcon sx={icono} />
                  <Typography variant="h4">{widgets?.solicitadas}</Typography>
                  <Typography variant="body1">Solicitadas</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid> : <></>}
          {widgets?.solicitud_vacaciones_activa ? 
          <Grid item xs={6} md={3}>
            <Card sx={cardVerde}>
              <CardContent>
                <Box sx={box}>
                  <ThumbUpOutlinedIcon sx={icono} />
                  <Typography variant="h4">{widgets?.autorizadas}</Typography>
                  <Typography variant="body1">Autorizadas</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid> : <></>}
          <Grid item xs={6} md={widgets?.solicitud_vacaciones_activa ? 3:6}>
            <Card sx={card}>
              <CardContent>
                <Box sx={box}>
                  <FlightOutlinedIcon sx={icono} />
                  <Typography variant="h4">{widgets?.disfrutados}</Typography>
                  <Typography variant="body1">Disfrutadas</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={widgets?.solicitud_vacaciones_activa ? 3:6}>
            <Card sx={cardRojo}>
              <CardContent>
                <Box sx={box}>
                  <WorkHistoryOutlinedIcon sx={icono} />
                  <Typography variant="h4">{widgets?.pendientes}</Typography>
                  <Typography variant="body1">Pendientes</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  )
}

export default WidgetsCalendario
