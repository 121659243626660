import { Chip } from '@mui/material'
import { Box } from '@mui/system'

/**
 * Construye el elemento visual que se mostrará en el estado de una solicitud
 * @param {'number'} status - Status de la solicitud
 * @param {'string'} d_status - Descripción del status
 * @returns {import("@mui/material").ChipClasses}
 */
export const getChipSolicitud = (status, d_status) => {
  let color = 'warning'
  if (status === 200 || status === 201) {
    color = 'primary'
  } else if (status === 500 || status % 2 === 0) {
    color = 'success'
  } else if (status === 600 || status % 2 === 1) {
    color = 'error'
  }

  return (
    <Box sx={{ p: '0px', width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Chip label={d_status} color={color} />
    </Box>
  )
}
