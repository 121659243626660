import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import userSlice from 'features/users/stores/userSlice'
import portalSlice from 'features/portal/portalSlice'
import menusSlice from 'features/portal/menusSlice'
import datosTrabajadorSlice from 'features/datos_trabajador/datosTrabajadorSlice'
import documentosSlice from 'features/documentos/documentosSlice'
import hojasGastoSlice from 'features/hojasgasto/hojasGastoSlice'
import listausuariosSlice from 'features/users/stores/listausuariosslice'
import solicitudesSlice from 'features/solicitudes/solicitudesSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const userReducer = combineReducers({
  user: userSlice,
  portal: portalSlice,
  menus: menusSlice,
  hojasgasto: hojasGastoSlice,
  solicitudes: solicitudesSlice,
})

const persistedReducer = persistReducer(persistConfig, userReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
})

export const persistor = persistStore(store)
