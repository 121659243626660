import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  consultaInicial,
  selectDatosSolicitud,
  setDatoSolicitud,
  setCampoCargando,
  setCampoError,
} from 'features/solicitudes/solicitudesSlice'
import { validate } from '../libs/FieldResquestValidator'

import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'

function TextComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState(campo.valor_dfto ?? '')
  const [validationError, setValidationError] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor })
      }
    }
    return valores
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
  }, [])

  useEffect(() => {
    setValue(valor ?? '')
    if (valor) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: false }))
    }
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
  }, [valor])

  const handleBlur = (e) => {
    try {
      validate(campo, e.target.value)
      dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: e.target.value }))
      setAlertOpen(false)
      setValidationError('')
      dispatch(setCampoError({ idpk: campo.idpk, error: false }))
    } catch (e) {
      setAlertOpen(true)
      setValidationError(e.message)
      dispatch(setCampoError({ idpk: campo.idpk, error: true }))
    }
  }

  /**@typedef {CampoSolicitud} campo */
  if (campo) {
    const numerico = (
      <TextField
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const alfanumerico = (
      <TextField
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const textArea = (
      <TextField
        key={campo.idpk}
        id={campo.codigo_campo}
        label={campo.etiqueta}
        variant="standard"
        required={campo.obligatorio === 'S'}
        helperText={campo.ayuda}
        fullWidth
        multiline
        rows={4}
        InputLabelProps={{ shrink: value ? true : false }}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={handleBlur}
        InputProps={{
          startAdornment: campo.cargando ? <CircularProgress size={20} /> : null,
        }}
        error={campo.error}
      />
    )
    const validationAlert = (
      <Collapse in={alertOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          {validationError}
        </Alert>
      </Collapse>
    )

    if (campo.tipo_dato === 'N') {
      return (
        <>
          {numerico}
          {validationAlert}
        </>
      )
    } else if (Number(campo.longitud_char) <= 100) {
      return (
        <>
          {alfanumerico}
          {validationAlert}
        </>
      )
    } else {
      return (
        <>
          {textArea}
          {validationAlert}
        </>
      )
    }
  }
}

export default TextComponent
