import { Chip } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'

/**
 * Datos Extra de la noticia
 *
 * @param {Object} props
 * @param {NewsDetailsDTO} props.news - The new to show the extra details
 */
const NewsDetailsExtra = ({ news }) => {
  const route = news?.portada_nombre ? `/assets/images/noticias/${news.portada_nombre}` : null
  const [showImg, setShowImg] = useState(true)

  return (
    <>
      {news ? (
        <>
          <Box>
            {news.portada_nombre && showImg ? (
              <Box sx={{ aspectRatio: '16/9', mb: 1 }}>
                <img
                  src={route}
                  alt={news.titulo}
                  onError={() => {
                    setShowImg(false)
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    backgroundColor: 'inherit',
                  }}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            {news.etiquetas.map(({ nombre }, index) => {
              return (
                <Chip
                  key={index}
                  sx={{
                    mr: '4px',
                    borderRadius: '5px',
                  }}
                  label={nombre}
                />
              )
            })}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default NewsDetailsExtra
