import { ListItem, List, Button, Box } from "@mui/material"
import Confirmacion from "./Confirmacion"
import {deleteGastoLinDoc, descargaLinDoc} from '../features/hojasgasto/hojasGastoSlice'
import { useDispatch } from "react-redux"
import { FileIcon, defaultStyles } from 'react-file-icon';  //https://www.npmjs.com/package/react-file-icon

const liStyle = {
  "display": "grid",
  "grid-template-columns": "48px auto",
  "grid-template-rows": "repeat(2, auto)",
  'gap':'0.5rem',
  'flex-basis':'20%'
}

const iconStyle = {
  'grid-row':'1/3'
}

const buttStyle = {
  'grid-row': '2/3',
  'grid-column':'2/3',
  'display':'flex'
}

const ulStyle = {
  'display':'flex',
  'flex-wrap':'wrap',
}

const DocumentosCuadro = props => {
  const dispatch = useDispatch()
  const { permiteBorrar = true} = props

  const handleBorrado = (que) => {
    const dato = {
      'id_doc':que.id_doc,
    }
    dispatch(deleteGastoLinDoc(dato))
  }

  function handleDescarga(doc){
    dispatch(descargaLinDoc(doc))
  }

  if (props.docs.length > 0){
    return (
        <List sx={ulStyle}>
          {props.docs.map(doc => {
            const extension = doc.nombre_archivo.split('.').at(-1)
            return <ListItem sx={liStyle}>
              <Box sx={iconStyle}>
                <FileIcon extension={extension} {...defaultStyles[extension]} />
              </Box>
              {doc.nombre_archivo}
              <Box sx={buttStyle}>
                {props.permiteBorrar
                  ? <Confirmacion
                      aceptarFn={() => {handleBorrado(doc)}}
                      text={`¿Borrar ${doc.nombre_archivo}?`}
                      title={'Borrar archivo'}
                      botonTxt={'Borrar'}
                    />
                  : ''
                }
                <Button onClick={() => handleDescarga(doc.id_doc)}>Descargar</Button>
              </Box>
            </ListItem>
          })}
        </List>
    )
  }
}

export default DocumentosCuadro