import '@wangeditor/editor/dist/css/style.css' // import css

import { useSelector, useDispatch } from 'react-redux'

import React, { useState, useEffect } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor/editor'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/es'

import { selectEmpresasPermisos, selectPermisos } from '../../users/stores/userSlice'

import CustomDialog from 'common/components/CustomDialog'
import NewsForm from 'common/components/Editor'
import CustomTable from 'common/components/CustomTable'
import NewsController from '../libs/NewsController'
import { enqueueSnackbar } from 'notistack'
import { Grid } from '@mui/material'

i18nChangeLanguage('en')

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const EditorNoticia = () => {
  const { response, loading } = NewsController.get()
  const [noticias, setNoticias] = useState(response)
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => {
    setNoticias(response)
  }, [response])
  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const ahora = new Date()

  const [editing, setEditing] = useState(0)

  const [editor, setEditor] = useState(null)
  const [html, setHtml] = useState('')

  const [titulo, setTitulo] = useState('')
  const [visible, setVisible] = useState(true)
  const [orden, setOrden] = useState(1)
  const [publico, setPublico] = useState(false)
  const [empresa, setEmpresa] = React.useState([])
  const [fechapubli, setFechaPubli] = React.useState(dayjs(ahora.toISOString().split('T')[0]))
  const [fechabaja, setFechaBaja] = React.useState('')

  const [openDialog, setOpenDialog] = React.useState(false)

  const permisos = useSelector(selectEmpresasPermisos)

  const toolbarConfig = {} // JS syntax

  // Timely destroy editor, important!
  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor])

  const handleTitulo = (e) => setTitulo(e.target.value)
  const handleVisible = (e) => setVisible(e.target.checked)
  const handleOrden = (e) => setOrden(e.target.value)
  const handlePublico = (e) => setPublico(e.target.checked)
  const handleEmpresa = (event) => {
    const {
      target: { value },
    } = event
    setEmpresa(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSelectNoticia = (id) => {
    const noticia = noticias.filter((n) => n.id === id)[0]
    setTitulo(noticia.nombre)
    setHtml(noticia.contenido)
    setVisible(noticia.visible)
    setOrden(noticia.orden)
    setPublico(noticia.publico)
    setEmpresa(noticia.rh_cif.split(','))
    setFechaPubli(noticia.fechapubli ? dayjs(noticia.fechapubli) : null)
    setFechaBaja(noticia.fechabaja ? dayjs(noticia.fechabaja) : null)
    setEditing(id)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setHtml('')
    setTitulo('')
    setHtml('')
    setVisible('')
    setOrden('')
    setPublico('')
    setEmpresa([])
    setFechaPubli('')
    setFechaBaja('')
    setOpenDialog(false)
    editor.destroy()
    setEditor(null)
    setEditing(0)
  }

  const handleEliminarNoticia = async () => {
    //TODO Usar el controlador para eliminar la noticia
    const removedNews = await NewsController.delete(editing)
    if (removedNews) {
      enqueueSnackbar('Noticia eliminada satisfactoriamente', { variant: 'success' })
      const index = noticias.findIndex((link) => link.id === removedNews.id)
      const newState = [...noticias]
      newState.splice(index, 1)

      setNoticias(newState)
    } else {
      enqueueSnackbar('Ha ocurrido un error y no se pudo eliminar la noticia', {
        variant: 'error',
      })
    }
    handleCloseDialog()
  }

  const handleSave = async (e) => {
    const noticia = {
      id: editing,
      nombre: titulo,
      contenido: html,
      visible: visible ? 'S' : 'N',
      orden: orden,
      publico: publico ? 'S' : 'N',
      fechapubli: fechapubli.toISOString().split('T')[0],
      fechabaja: fechabaja ? fechabaja.toISOString().split('T')[0] : null,
      documentos: null,
      url: null,
      imagenes: null,
      rh_cif: empresa.join(','),
    }
    if (editing > 0) {
      const updatedNew = await NewsController.update(noticia)

      if (updatedNew) {
        enqueueSnackbar('Noticia modificada satisfactoriamente', { variant: 'success' })
        const newState = [...noticias]
        const index = newState.findIndex((news) => news.id === updatedNew.id)
        newState[index] = updatedNew
        setNoticias(newState)
      } else {
        enqueueSnackbar('Ha ocurrido un error y no se pudo guardar el enlace', {
          variant: 'error',
        })
      }
    } else {
      const nuevaNoticia = await NewsController.create(noticia)
      if (nuevaNoticia) {
        enqueueSnackbar('Noticia creada satisfactoriamente', { variant: 'success' })
        setNoticias([...noticias, nuevaNoticia])
      } else {
        enqueueSnackbar('Ha ocurrido un error y no se pudo guardar la noticia', {
          variant: 'error',
        })
      }
    }
    handleCloseDialog()
  }

  return (
    <>
      {editing === 0 ? (
        <div style={{ width: '100%' }}>
          <CustomTable
            loading={isLoading}
            hideFooter={false}
            headerChilds={
              <div style={{ marginBottom: '.5em' }}>
                <Button onClick={() => setEditing(-1)}>Nueva Noticia</Button>
              </div>
            }
            initialPageSize={10}
            showToolbar={true}
            dataSource={noticias ?? []}
            fields={[
              { value: 'id', key: 'ID', width: 150, sortable: true, flex: 0.5 },
              { value: 'nombre', key: 'Nombre', width: 150, sortable: true, flex: 1 },
              { value: 'autor', key: 'Autor', width: 150, sortable: true, flex: 1 },
              { value: 'publico', key: 'Público', width: 150, sortable: true, flex: 1 },
              { value: 'orden', key: 'Orden', width: 150, sortable: true, flex: 1 },
              { value: 'fechaalta', key: 'Fecha Publicacion', width: 150, sortable: true, flex: 1 },
              { value: 'fechabaja', key: 'Fecha Baja', width: 150, sortable: true, flex: 1 },
            ]}
            onClickEvent={(params, _, __) => {
              handleSelectNoticia(params.row.id)
            }}
          />
        </div>
      ) : (
        <Box component="form" noValidate autoComplete="off">
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                alignItems="start"
                alignContent={'start'}
                justifyContent="flex-start"
                justifyItems="start"
              >
                <Grid item md={11} sm={10} xs={9}>
                  <FormGroup>
                    <TextField
                      id="titulo"
                      label="Título"
                      variant="outlined"
                      value={titulo}
                      onChange={handleTitulo}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={1} sm={2} xs={3}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      labelPlacement="top"
                      label="Visible"
                      onChange={handleVisible}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6} xs={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="empresas-label">Empresa</InputLabel>
                    <Select
                      labelId="empresas-label"
                      id="empresa-selector"
                      multiple
                      value={empresa}
                      onChange={handleEmpresa}
                      input={<OutlinedInput label="Empresa" />}
                      renderValue={(selected) => selected?.join(',')}
                      MenuProps={MenuProps}
                    >
                      {permisos.map((permiso) =>
                        permiso.rh_cif ? (
                          <MenuItem key={permiso.id} value={permiso.rh_cif}>
                            <Checkbox checked={empresa.indexOf(permiso.rh_cif) > -1} />
                            <ListItemText primary={permiso.d_rh_cif} />
                          </MenuItem>
                        ) : null,
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5} xs={3}>
                  <FormGroup sx={{ width: '100%' }}>
                    <TextField
                      id="orden"
                      label="Orden"
                      variant="outlined"
                      value={orden}
                      onChange={handleOrden}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={1} xs={3}>
                  <FormGroup sx={{ width: '100%' }}>
                    <FormControlLabel
                      sx={{ textAlign: 'left' }}
                      control={<Switch />}
                      labelPlacement="top"
                      label="Público"
                      checked={publico === 'S' || publico === true}
                      onChange={handlePublico}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          minWidth: 'initial',
                          '&.MuiTextField-root': {
                            minWidth: 'initial',
                          },
                        }}
                        label="Fecha publicación"
                        value={fechapubli}
                        onChange={(newValue) => setFechaPubli(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                      <DatePicker
                        sx={{
                          width: '100%',
                          minWidth: 'initial',
                          '&.MuiTextField-root': {
                            minWidth: 'initial',
                          },
                        }}
                        label="Fecha baja"
                        value={fechabaja}
                        onChange={(newValue) => setFechaBaja(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item md={12} sx={{ width: '100%' }}>
                  <NewsForm editor={editor} html={html} setEditor={setEditor} setHtml={setHtml} />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    disableElevation
                    onClick={handleSave}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item md={2} xs={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    onClick={() => {
                      handleCloseDialog()
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item md={2} xs={6}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="error"
                    disableElevation
                    onClick={handleOpenDialog}
                  >
                    Eliminar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <CustomDialog
            open={openDialog}
            closeDialogEvent={handleCloseDialog}
            actionLeftText="No"
            actionLeft={handleCloseDialog}
            actionRightText="Si"
            actionRight={handleEliminarNoticia}
            title="Eliminar Noticia"
            description="¿Está seguro de querer eliminar esta noticia?"
          />
        </Box>
      )}
    </>
  )
}

export default EditorNoticia
