import { enqueueSnackbar } from 'notistack'
import axios from 'api/axios'

const { routeIsActive } = require('features/routing/libs/routeIsActive')

/**
 * Notify the user if he has unreaded documents
 *
 * @param {Array<MenuDto>} menus
 * @param {NavigateFunction} navigate
 */

async function notifyUnreadedDocs(menus, navigateTo) {
  const isRouteActive = routeIsActive('/doccorporativa', menus)
  if (!isRouteActive) return
  try {
    const response = await axios.get('/doccorporativa/pendientes')
    // navigateTo('/asdasdasd')
    if (response?.data?.pendientes > 0) {
      enqueueSnackbar(`Tiene ${response.data.pendientes} documentos de obligada lectura sin leer`, {
        variant: 'warning',
        SnackbarProps: {
          style: {
            cursor: 'pointer',
          },
          onClick: () => navigateTo('/doccorporativa'),
        },
        autoHideDuration: 3000,
        action: <></>,
      })
      enqueueSnackbar()
    }
  } catch (error) {}
}

export default notifyUnreadedDocs
