import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import CamposSolicitud from '../components/CamposSolicitud'
import DatosSolicitud from '../components/DatosSolicitud'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import axios from 'api/axios'
import { Alert, CircularProgress, LinearProgress } from '@mui/material'

const Solicitud = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [params, setSearchParams] = useSearchParams()

  const [solicitud, setSolicitud] = useState()
  const [campos, setCampos] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setLoading(true)

    if (location?.state?.solicitud) setSolicitud(location.state.solicitud)

    let origen = params.get('origen')
    let codigo = params.get('codigo')
    console.log({ origen, codigo })

    if (solicitud && (!origen || !codigo)) {
      codigo = solicitud.codigo_solicitud
      origen = solicitud.origen_solicitud
    }

    if ((!origen || !codigo) && location?.state?.solicitud) {
      origen = location.state.solicitud.origen_solicitud
      codigo = location.state.solicitud.codigo_solicitud
    }

    if (origen && codigo)
      axios
        .get(`solicitud/datos/${codigo}/${origen}/`)
        .then((response) => {
          const data = response.data
          setSolicitud(data.solicitud)
          setCampos(data.campos)
        })
        .catch((error) => setError(error))
        .finally(() => {
          setLoading(false)
          return
        })

    if (!location?.state?.solicitud && (!origen || !codigo)) navigate('/listasolicitudes/')
  }, [location]) // Es necesario tener location en el array de dependencias para que reaccione cuando se crea una nueva solicitud

  console.log({ solicitud })

  return (
    <Box>
      {solicitud?.codigo_solicitud && solicitud?.origen_solicitud ? (
        <DatosSolicitud solicitud={solicitud} campos={campos} />
      ) : solicitud?.tipo && solicitud?.subtipo ? (
        <CamposSolicitud solicitud={solicitud} />
      ) : (
        <Box>
          {loading ? (
            <LinearProgress />
          ) : error ? (
            <Alert severity="error">Ha ocurrido un error</Alert>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Solicitud
