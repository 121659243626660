import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'api/axios'
import storage from 'redux-persist/lib/storage'
import axiosPE from 'api/axios'

const sessionState = JSON.parse(sessionStorage.getItem('state'))
const stateVacio = { user: null, token: null, permisos: [], subempresa: null, d_subempresa: '' }
const initialState = sessionState || stateVacio

export const userLogin = createAsyncThunk('/usuario/login', async (loginData) => {
  const response = await axios.post('/usuario/login', loginData, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data
})
export const fetchPermisos = createAsyncThunk('/permisos', async () => {
  //const response = await axios.get('/permisos')
  return []// response.data
})
export const cambiarUsuario = createAsyncThunk('/cambiauser', async (user) => {
  let response = await axiosPE.get(`/usuario/cambiar/${user.id}/`)
  sessionStorage.setItem('nuevoToken', response.data.token)
  let permisos = await axios.get('/permisos')
  return {
    token: response.data.token,
    refresh: response.data.refresh,
    user,
    permisos: permisos.data,
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload
      state.user = user
      state.token = accessToken
      sessionStorage.setItem('state', JSON.stringify(state))
    },
    setToken: (state, action) => {
      const { token } = action.payload
      state.token = token
      sessionStorage.setItem('state', JSON.stringify(state))
    },
    logOut: (state, action) => {
      storage.removeItem('persist:root')
      sessionStorage.removeItem('state')
      window.location.href = '/'
    },
    setSubempresa: (state, action) => {
      const { subempresa, d_subempresa } = action.payload
      state.subempresa = subempresa
      state.d_subempresa = d_subempresa
      sessionStorage.setItem('state', JSON.stringify(state))
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.token = action.payload.token
        state.refresh = action.payload.refresh
        sessionStorage.setItem('state', JSON.stringify(state))
      })
      .addCase(fetchPermisos.fulfilled, (state, action) => {
        state.permisos = action.payload
        sessionStorage.setItem('state', JSON.stringify(state))
      })
      .addCase(cambiarUsuario.fulfilled, (state, action) => {
        storage.removeItem('persist:root')
        state.user = action.payload.user
        state.token = action.payload.token
        state.permisos = action.payload.permisos
        state.subempresa = null
        state.d_subempresa = ''
        state.refresh = action.payload.refresh
        window.location.pathname = '/'
      })
  },
})

export const { setCredentials, setToken, logOut, setSubempresa } = userSlice.actions
export const selectUsuario = (state) => state?.user?.user
export const selectToken = (state) => state.token

export default userSlice.reducer

export const selectCurrentUser = (state) => state.user.user
export const selectCurrentToken = (state) => state.user.token
export const selectPermisos = (state) => state.user.permisos
export const selectSubempresa = (state) => state.user.subempresa
export const selectNombreSubempresa = (state) => state.user.d_subempresa
export const selectEmpresasPermisos = (state) => {
  let soloEmpresas =
    state?.user?.permisos?.map((permiso) => {
      return { id: permiso.id, rh_cif: permiso.rh_cif, d_rh_cif: permiso.d_rh_cif }
    }) ?? []
  soloEmpresas = soloEmpresas.filter(
    (item, index) => soloEmpresas.findIndex((element) => element.rh_cif === item.rh_cif) === index,
  )
  return soloEmpresas
}
