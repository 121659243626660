import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHojasGasto, selectHojasGasto } from "../features/hojasgasto/hojasGastoSlice";
import NuevoGastoFormulario from "./NuevoGastoFormulario";
import EditarLineaGastos from "./EditarLineaGastos"

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import TagIcon from "@mui/icons-material/Tag";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BusinessIcon from "@mui/icons-material/Business";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";



const Hoja = () => {
  const dispatch = useDispatch()
  const {id} = useParams();
  const navigate = useNavigate();
  const hojas = useSelector(selectHojasGasto);
  const ahora = new Date();
  const [editLineaModalAbierto, setEditLineaModalAbierto] = useState(false)
  const [idLinea, setIdLinea] = useState()
  const [hoja, setHoja] = useState(hojas.filter((h) => Number(h.id) === Number(id))[0])
  const volver = () => {navigate(-1)};
  let meses = [];

  for (let i = 0; i < 12; i++) {
    const fecha = new Date(Date.UTC(ahora.getFullYear(), i, 1));
    const nombre = fecha.toLocaleDateString(navigator.language, { month: "long" });
    meses.push(nombre);
  }

  const split_fi = hoja?.fecha_inicio.split("-") || '';
  const split_ff = hoja?.fecha_fin.split("-") || '';
  const fi = new Date(Date.UTC(split_fi[0], split_fi[1] - 1, split_fi[2]));
  const ff = new Date(Date.UTC(split_ff[0], split_ff[1] - 1, split_ff[2]));
  const fecha_inicio = fi.toLocaleDateString(navigator.language, { month: "long", day: "numeric", year: "numeric" });
  const fecha_fin = ff.toLocaleDateString(navigator.language, { month: "long", day: "numeric", year: "numeric" });
  const periodo =
    hoja?.tipo === "M" ? (
      meses[hoja.periodo - 1]
    ) : (
      <Stack sx={{ flexDirection: "column" }}>
        <span>{fecha_inicio}</span>
        <span>{fecha_fin}</span>
      </Stack>
    );
  // fetch hojas cuando no hay. (en principio, cuando se ha hecho F5)
  useEffect(() => {
    if(hojas.length === 0){
      console.log('se ha actualizado?');
      dispatch(fetchHojasGasto())
    } 
  }, [])


  // Seteo de los datos de la hoja, cuando actualiza la página
  useEffect(() => {
    console.log('seteando datos de la hoja');
    setHoja(hojas.filter((h) => Number(h.id) === Number(id))[0])

    if(hoja){
      split_fi = hoja.fecha_inicio.split("-");
      split_ff = hoja.fecha_fin.split("-");
  
      periodo = hoja.tipo === "M" 
        ? (meses[hoja.periodo - 1])
        : (
          <Stack sx={{ flexDirection: "column" }}>
            <span>{fecha_inicio}</span>
            <span>{fecha_fin}</span>
          </Stack>
        );
    }
  }, [hojas])

  
  function handleAbrirCerrarEditLinea(linea, estado){
    setEditLineaModalAbierto(estado)
    setIdLinea(estado ? linea : undefined)
  }

  if(hoja) return (
    <>
    
    <EditarLineaGastos lin={idLinea} hoja={Number(id)} open={editLineaModalAbierto} cerrarModal={handleAbrirCerrarEditLinea}/>
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <Stack spacing={2} direction="row">
          <Button onClick={volver} sx={{ alignSelf: "flex-start" }} startIcon={<ArrowBackIcon />}>
            Volver atrás
          </Button>
          {!hoja?.codint
            ? <>
                <NuevoGastoFormulario hoja={hoja} />
                <Button onClick={volver} sx={{ alignSelf: "flex-end" }} startIcon={<ArrowBackIcon />}>
                  Eliminar
                </Button>
                <Button onClick={volver} sx={{ alignSelf: "flex-end" }} startIcon={<ArrowBackIcon />}>
                  Cerrar
                </Button>
              </>
            : ''
          }
        </Stack>
      </Stack>
      <Stack spacing={2} direction="row">
        <List sx={{ width: "100%", minWidth: 275, maxWidth: 360 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <TagIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={hoja?.id} secondary="ID." />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CalendarMonthIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={hoja?.ejercicio} secondary="Ejercicio" />
          </ListItem>
          <ListItem>
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={hoja?.tipo === "M" ? "Mensual" : "Período"} secondary="Tipo" />
          </ListItem>
          <ListItem>
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={periodo} secondary="Período" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BusinessIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={hoja?.d_subempresa} secondary="Subempresa" />
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="tabla lineas hoja de gastos">
            <TableHead sx={{ backgroundColor: "primary.main", color: "primary.contrastText" }}>
              <TableRow>
                <TableCell sx={{ color: "primary.contrastText" }}>Fecha</TableCell>
                <TableCell sx={{ color: "primary.contrastText", textAlign: "center" }}>Concepto</TableCell>
                <TableCell sx={{ color: "primary.contrastText" }}>Descripción</TableCell>
                <TableCell sx={{ color: "primary.contrastText" }}>Grupo</TableCell>
                <TableCell sx={{ color: "primary.contrastText", textAlign: "right" }}>Importe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hoja?.lineas?.map((l) => {
                return (
                  <TableRow key={l.id_lin} sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }} onClick={() => {handleAbrirCerrarEditLinea(l.id_lin, true)}}>
                    <TableCell component="th" scope="row">
                      {l.fecha}
                    </TableCell>
                    <TableCell>{l.concepto}</TableCell>
                    <TableCell>{l.descripcion}</TableCell>
                    <TableCell>{l.grupo_gastos}</TableCell>
                    <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>{l.cantidad * l.precio}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
    </>
  );
};

export default Hoja;
