import LoginForm from '../components/LoginForm'
import RecoveryPasswordForm from '../components/RecoveryPasswordForm'
import { useLocation } from 'react-router-dom'
import DefaultLoginBackGround from '../components/LoginBackground'
import { useState } from 'react'
import ThemeController from 'theming/ThemeManager' // importar el controlador general

const Login = () => {
  const themeController = new ThemeController() // instanciar el controlador
  const LoginBackground = themeController?.components
    ? themeController?.components['LoginBackground'] ?? DefaultLoginBackGround
    : DefaultLoginBackGround

  const location = useLocation()
  const [loading, setLoading] = useState(false)
  return (
    <>
      <LoginBackground loading={loading}>
        {location?.state?.recovery ? (
          <RecoveryPasswordForm />
        ) : (
          <LoginForm loadingParentCallback={setLoading} />
        )}
      </LoginBackground>
    </>
  )
}

export default Login
