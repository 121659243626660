import { useCallback } from 'react'
import axios from 'api/axios'
import { useState, useEffect } from 'react'

/**
 * @module FetchHooks
 */

/**
 * Fetch the calendar data for the user
 * @param {Object} props - The component props
 * @param {number} props.year - The year to see the calendar
 * @param {UserToHandle} [props.user] - The user to make the request
 * @returns {UseFetchHookResponse<any>}
 */
const useFetchDates = ({ year, user = null }) => {
  const [response, setResponse] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(() => {
    setLoading(true)
    setError(null)
    axios
      .get(`/calendariofechas/?y=${year ?? ''}&user=${user?.usuario_web ?? ''}`)
      .then((request_response) => {
        const res = []
        const data = request_response.data
        data.situaciones.forEach((situation) => {
          const fecha_origin = situation?.start?.split('-').map((i) => parseInt(i))
          const fecha = new Date(fecha_origin[0], fecha_origin[1] - 1, fecha_origin[2], 0, 0, 0)
          res.push({
            name: situation.description,
            startDate: fecha,
            endDate: fecha,
            color: situation.backgroundColor,
            tipo: situation.tipo_dia,
            situacion: situation.sit_base,
          })
        })
        setResponse(res)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [year, user?.usuario_web])

  useEffect(() => {
    fetchData()
  }, [year, user, fetchData])

  return { response, error, loading }
}

export default useFetchDates
