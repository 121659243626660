/**
 * Devuelve una fecha formateada desde un string ISO de base de datos
 * @param {'string'} fecha - Fecha en formato ISO
 * @param {'string'} iso - The iso format
 * @returns {'string'} Fecha formateada
 */
export const getFechaFromISO = (fecha, iso = 'es-Es') => {
  const fixed_date = fecha.split('-').map((i) => +i)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date(fixed_date[0], fixed_date[1] - 1, fixed_date[2])
  return date.toLocaleDateString('es-ES', options)
}
