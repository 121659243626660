import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack'

import { useLocation } from 'react-router-dom'

import { validate } from '../libs/FieldResquestValidator'

import axios from 'api/axios'

import {
  selectSolicitudSeleccionada,
  selectListaSolicitudesDisponibles,
  selectEstructuraSolicitud,
  fetchCamposSolicitudes,
  selectCamposSolicitud,
  selectDatosSolicitud,
  setSolicitudSeleccionada,
  fetchListaSolicitudes,
  setCampoError,
  selectDescripcionesSolicitud,
} from '../solicitudesSlice'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Chip from '@mui/material/Chip'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import SaveIcon from '@mui/icons-material/Save'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import DividerComponent from './DividerComponent'
import TextComponent from './TextComponent'
import DateComponent from './DateComponent'
import DateRangeComponent from './DateRangeComponent'
import FileComponent from './FileComponent'
import ListOfValuesComponent from './ListOfValuesComponent'
import ListComponent from './ListComponent'
import RadioComponent from './RadioComponent'

import CabeceraSolicitud from './CabeceraSolicitud'
import CalendarioAnual from 'features/calendario/components/CalendarioAnual'
import WidgetsCalendario from 'features/calendario/components/WidgetsCalendario'
import Calendario from 'features/calendario/pages/Calendario'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

function CamposSolicitud({ solicitud }) {
  const dispatch = useDispatch()
  const location = useLocation()

  const MAX_NOTIF = 5

  const estructura = useSelector(selectEstructuraSolicitud)
  const campos = useSelector(selectCamposSolicitud)
  const valores = useSelector(selectDatosSolicitud)
  const descripciones = useSelector(selectDescripcionesSolicitud)

  const [openBackdrop, setOpenBackdrop] = useState(false)

  useEffect(() => {
    dispatch(fetchCamposSolicitudes({ tipo: solicitud.tipo, subtipo: solicitud.subtipo }))
  }, [])

  const navigate = useNavigate()

  const handleSave = (nuevo) => {
    setOpenBackdrop(true)
    let encontrados = 0
    let errores = false
    campos.forEach((/**@type {CampoSolicitud} */ campo) => {
      if (campo.tipo_dato !== 'S' && campo.obligatorio === 'S' && !valores[campo.idpk]) {
        encontrados++
        if (encontrados < MAX_NOTIF) {
          enqueueSnackbar(`El campo ${campo.etiqueta} es obligatorio`)
        } else if (encontrados === MAX_NOTIF) {
          enqueueSnackbar(`Hay campos obligatorios sin cubrir`)
        }
        dispatch(setCampoError({ idpk: campo.idpk, error: true }))
        errores = true
      } else if (campo.id_validacion) {
        try {
          const valor = valores[campo.idpk]
          validate(campo, valor)
          dispatch(setCampoError({ idpk: campo.idpk, error: false }))
        } catch (e) {
          dispatch(setCampoError({ idpk: campo.idpk, error: true }))
          errores = true
        }
      }
    })
    if (!errores) {
      const registros = []
      for (const idpk in valores) {
        if (Object.hasOwnProperty.call(valores, idpk)) {
          const campo = campos.filter((c) => c.idpk === Number(idpk))[0]
          registros.push({
            codigo_plantilla: campo.codigo_plantilla,
            codigo_campo: campo.codigo_campo,
            orden: campo.orden,
            valor: valores[idpk],
            d_valor: descripciones[idpk],
          })
        }
      }
      const datos = {
        tipo: solicitud.tipo,
        subtipo: solicitud.subtipo,
        registros: registros,
      }
      axios
        .post(`/solicitudes/`, datos)
        .then((response) => {
          enqueueSnackbar(`Solicitud creada con éxito`)
          if (nuevo) {
            navigate('/solicitud/')
          }
          dispatch(setSolicitudSeleccionada(response.data))
          navigate('/solicitud/', { state: { solicitud: response.data } })
        })
        .catch((e) => enqueueSnackbar(`Se ha producido un error al guardar la solicitud`))
        .finally(() => setOpenBackdrop(false))
    } else {
      setOpenBackdrop(false)
    }
  }

  if (solicitud && estructura && estructura.length > 0) {
    return (
      <Box component="form" autoComplete="off">
        <SnackbarProvider
          maxSnack={MAX_NOTIF + 1}
          autoHideDuration={6000}
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
              <CloseRoundedIcon />
            </IconButton>
          )}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          variant={'error'}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <CabeceraSolicitud
          descripcion={solicitud.descripcion}
          ayuda={solicitud.ayuda}
          solicitud={solicitud}
        />
        <Divider sx={{ mt: 4, mb: 4 }} />
        {solicitud.id_solicitud_pe === 'VAC01' ? <Calendario vistaAmpliada={false} /> : null}
        <Stack spacing={4}>
          {estructura.map((campo) => {
            return (
              <Stack key={`s-${campo.idpk}`} spacing={2}>
                <DividerComponent key={`s-${campo.idpk}`} idpk={campo.idpk} />
                {campo.campos.length > 0 ? (
                  <Card>
                    <CardContent>
                      <Grid key="grid1" container spacing={2}>
                        {campo.campos.map((c) => {
                          return (
                            <Grid
                              key={`g-${c.idpk}`}
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{ display: c.visible !== 'S' ? 'none' : 'inherit' }}
                            >
                              {c.tipo_dato === 'V' ? (
                                c.lv_codigo !== '' && c.lv_tipo === 'LV' ? (
                                  <ListOfValuesComponent idpk={c.idpk} />
                                ) : c.lv_codigo !== '' && c.lv_tipo === 'LI' ? (
                                  <ListComponent idpk={c.idpk} />
                                ) : c.lv_codigo !== '' && c.lv_tipo === 'RI' ? (
                                  <RadioComponent idpk={c.idpk} />
                                ) : (
                                  <TextComponent idpk={c.idpk} />
                                )
                              ) : c.tipo_dato === 'N' ? (
                                <TextComponent idpk={c.idpk} />
                              ) : c.tipo_dato === 'D' ? (
                                <DateComponent idpk={c.idpk} />
                              ) : c.tipo_dato === 'DH1' ? (
                                <DateRangeComponent idpk={c.idpk} />
                              ) : c.tipo_dato === 'B' ? (
                                <FileComponent idpk={c.idpk} />
                              ) : (
                                <></>
                              )}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                ) : (
                  <></>
                )}
              </Stack>
            )
          })}
        </Stack>
        <SpeedDial
          ariaLabel="Guardar solicitud"
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          icon={<SaveIcon />}
          onClick={() => handleSave(false)}
        >
          <SpeedDialAction
            key="act-2"
            icon={<SaveAsIcon />}
            tooltipTitle="Guardar y Nuevo"
            onClick={() => handleSave(true)}
          />
        </SpeedDial>
      </Box>
    )
  }
  return (
    <Box component="form" autoComplete="off">
      <Typography key="sol_desc" variant="h4" gutterBottom>
        <Skeleton key="s-titulo" animation="wave" width="100%" />
      </Typography>
      <Typography key="sol_ayu" variant="subtitle1" gutterBottom>
        <Skeleton key="s-descri" animation="wave" width="100%" />
      </Typography>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Stack spacing={4}>
        {[...Array(3).keys()].map((i) => {
          return (
            <Stack key={`s-${i}`} spacing={2}>
              <Divider textAlign="left">
                <Chip label="" sx={{ width: '150px' }} />
              </Divider>
              <Card>
                <CardContent>
                  <Grid key="grid1" container spacing={2}>
                    {[...Array(3).keys()].map((j) => {
                      return (
                        <Grid key={j} xs={12} sm={12} md={12}>
                          <Skeleton key="s-titulo" animation="wave" height={70} width="100%" />
                        </Grid>
                      )
                    })}
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export default CamposSolicitud
