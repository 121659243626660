import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosPE from 'api/axios'

const initialState = {
  listaUsuariosStatus: 'idle',
  listaUsuariosError: '',
  listaUsuarios: [],
}

// recupera lista de usuarios
export const fetchListaUsuarios = createAsyncThunk('/usuario/listar', async () => {
  const response = await axiosPE.get('/usuario/cambiar/')
  return response.data
})

export const listausuariosSlice = createSlice({
  name: 'listausuariosState',
  initialState,
  reducer: (state, action) => {
    state.listausuariosState.listaUsuarios = []
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListaUsuarios.pending, (state) => {
        state.listaUsuariosStatus = 'loading'
      })
      .addCase(fetchListaUsuarios.rejected, (state, action) => {
        state.listaUsuariosStatus = 'failed'
        state.listaUsuariosError = action.error.message
      })
      .addCase(fetchListaUsuarios.fulfilled, (state, action) => {
        state.listaUsuariosStatus = 'succeeded'
        state.listaUsuarios = action.payload.results
      })
  },
})

export const listaUsuariosStatus = (state) => state.listausuariosState.listaUsuariosStatus
export const listaUsuariosError = (state) => state.listausuariosState.listaUsuariosError
export const listaUsuarios = (state) => state.listausuariosState.listaUsuarios

export default listausuariosSlice.reducer
