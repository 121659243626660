

export const MODO_FIRMA_SIN_FIRMA_DOCUMENTOS = "0"
export const MODO_FIRMA_MANUSCRITA_DOCUMENTOS = "1"
export const MODO_FIRMA_ACEPTACION_EMAIL ="2"
export const MODO_FIRMA_SIN_DOBLE_FACTOR ="3"
export const MODO_FIRMA_CON_DOBLE_FACTOR ="4"
export const MODO_FIRMA_A_TRAVES_PLATAFORMA_EXTERNA = "5"

export const TIPOS_FIRMA_WEB = [MODO_FIRMA_MANUSCRITA_DOCUMENTOS, MODO_FIRMA_SIN_DOBLE_FACTOR, MODO_FIRMA_CON_DOBLE_FACTOR];
export const TIPOS_FIRMA_WEB_CON_DF = [MODO_FIRMA_CON_DOBLE_FACTOR];
export const TIPOS_FIRMA_WEB_SIN_DF = [MODO_FIRMA_MANUSCRITA_DOCUMENTOS, MODO_FIRMA_SIN_DOBLE_FACTOR];

/*Si el tipo de firma es 0: Nada )
Si el tipo de firma es 1: Debe usted desdcargar el documento, firmalo por sus medios....
Si el tipo de firma es 2: Estos no salen en la web
Si el tipo de firma es 3 (firma sin mas): Debe pulsar el botón de firmar para aceptar el documento.
Si el tipo de firma es 4: Debe pulsar el botón de firmar para aceptar el documento e indicar el segundo factor de verificaión
Si el tipo de firma es 5: El mismo texto que en la 1 "Debe usted desdcargar el documento, firmalo por sus medios...."*/
