import {
  DriveFolderUpload,
  FolderSharp,
  FolderZip,
  SnippetFolder,
  Source,
} from '@mui/icons-material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { Badge, Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
const ONLY_COUNT = false

const FolderView = ({ tree, setCurrentLevel }) => {
  const [currentTree, setCurrentTree] = useState(tree)
  const [path, setPath] = useState([])

  useState(() => {
    setPath([])
  }, [])

  useEffect(() => {
    if (!path.length > 0) return setCurrentTree(tree)
    path.forEach((pos) => {
      setCurrentTree(currentTree[pos].hijos)
    })
  }, [path])

  useEffect(() => {
    // if (!currentTree?.length > 0) setCurrentTree(tree)
    setPath([])
  }, [tree])

  const handleFolderClick = (element, idx) => {
    if (element.ultimo_nivel !== 'S' && element.num_doc > 0) {
      return setPath([...path, idx])
    }
    if (element.num_doc > 0) setCurrentLevel(element)
  }

  return (
    <Paper sx={{ p: 2 }}>
      {path.length > 0 ? (
        <Box sx={{ mb: 2 }}>
          <IconButton sx={{ mr: 2 }} onClick={() => setPath(path.slice(0, -1))}>
            <ArrowBack />
          </IconButton>
          Regresar
        </Box>
      ) : (
        ''
      )}
      {tree ? (
        <Grid container spacing={1} flexGrow={1}>
          {currentTree.map((element, idx) => {
            return element.num_doc === 0 && ONLY_COUNT ? (
              <></>
            ) : (
              <Grid item key={idx} md={2} sm={3} xs={6} xl={1}>
                <Button
                  disabled={element.num_doc === 0}
                  onClick={() => {
                    handleFolderClick(element, idx)
                  }}
                  sx={{ p: 3, width: '100%', height: '100%' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyItems: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Badge color="secondary" badgeContent={element.num_doc}>
                      {element.ultimo_nivel === 'S' ? (
                        <Source sx={{ fontSize: '6em' }} />
                      ) : (
                        <FolderSharp sx={{ fontSize: '6em' }} />
                      )}
                    </Badge>
                    <Typography
                      color={element.num_doc === 0 ? 'text.disabled' : 'text.primary'}
                      variant="title"
                    >
                      {element.descripcion}
                    </Typography>
                  </Box>
                </Button>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <></>
      )}
    </Paper>
  )
}

export default FolderView
