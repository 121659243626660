import { Outlet } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUsuario } from 'features/users/stores/userSlice'
import { selectTheme } from 'default_themes'
import { createTheme } from '@mui/material/styles'
import ThemeManager from 'theming/ThemeManager'
import { useLocation } from 'react-router-dom'
const theme = new ThemeManager()

const Layout = ({ container }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const usuario = useSelector(selectUsuario)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const location = useLocation()

  useEffect(() => {
    setMobileOpen(false)
  }, [location])

  const CustomHeader = theme.activeTheme?.components
    ? theme.activeTheme?.components['Header'] ?? null
    : null

  return (
    <>
      <Menu
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <Box
        className="main"
        sx={{
          width: '100%',
          backgroundColor: 'background.default',
          color: 'text.primary',
          '[data-tooltip]::after': {
            backgroundColor: 'background.default',
          },
          '[data-tooltip]::before': {
            backgroundColor: 'background.default',
          },
          '--w-e-textarea-bg-color': theme.pallete.background.paper,
          '--w-e-textarea-color': theme.pallete.text.primary,
          '--w-e-textarea-border-color': theme.pallete.background.default,
          '--w-e-textarea-slight-border-color': theme.pallete.background.paper,
          '--w-e-textarea-slight-color': theme.pallete.text.disabled,
          '--w-e-textarea-slight-bg-color': theme.pallete.background.default,

          // toolbar - css vars
          '--w-e-toolbar-color': theme.pallete.text.primary,
          '--w-e-toolbar-bg-color': theme.pallete.background.default,
          '--w-e-toolbar-active-color': theme.pallete.text.primary,
          '--w-e-toolbar-active-bg-color': theme.pallete.background.paper,
          '--w-e-toolbar-disabled-color': theme.pallete.text.disabled,
          '--w-e-toolbar-border-color': theme.pallete.background.paper,

          '--w-e-modal-button-bg-color': theme.pallete.primary.main,
          '--w-e-modal-button-border-color': theme.pallete.text.disabled,
          '--calendar-background': theme.pallete.background.paper,
          '--calendar-header-background': 'inherit',
          '--calendar-header-border': theme.pallete.text.disabled,
          '--calendar-header-hover': theme.pallete.background.alternative,
          '--calendar-header-hover-color': theme.pallete.background.alternativeContrastText,
          '--calendar-header-color': theme.pallete.text.primary,
          '--calendar-header-color-neighbor': theme.pallete.text.secondary,
        }}
      >
        {CustomHeader ? (
          <CustomHeader handleDrawerToggle={handleDrawerToggle} />
        ) : (
          <Header handleDrawerToggle={handleDrawerToggle} />
        )}
        {container ? (
          <Box
            sx={{
              transition: '.3s',
              marginTop: {
                xs: '70px',
                md: '10px',
              },
              padding: usuario ? 2.2 : 0,
              paddingRight: usuario ? 2 : 0,
              paddingBottom: usuario ? 1 : 0,
            }}
          >
            <Outlet />
          </Box>
        ) : (
          <Outlet />
        )}
      </Box>
    </>
  )
}

export default Layout
