import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import FilesHelper from 'common/libs/Files'

const FileComponent = (props) => {
  const dispatch = useDispatch()

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      FilesHelper.fileToBase64(file).then((result) => {
        dispatch(
          setDatoSolicitud({ idpk: campo.idpk, valor: result.base64, descripcion: result.name }),
        )
      })
    })
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  })

  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === props.idpk)[0]

  const datos = useSelector(selectDatosSolicitud)

  const files = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    )
  })

  if (campo) {
    return (
      <Stack spacing={2}>
        <Typography variant="body1">
          {campo.etiqueta}
          {campo.obligatorio === 'S' ? <sup>*</sup> : ''}
        </Typography>
        <Box
          component="span"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            border: '2px dashed',
            borderColor: campo.error ? 'error.main' : 'primary.main',
            backgroundColor: 'background.default',
          }}
        >
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <SaveAltIcon />
            <p>Arrastre aquí ficheros o haga click para seleccionar</p>
            <aside>
              <h4>Ficheros</h4>
              <ul>{files}</ul>
            </aside>
          </div>
        </Box>
      </Stack>
    )
  }
}

export default FileComponent
