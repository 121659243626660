import { Card, CardActions, CardContent, CardHeader, Grid, Skeleton } from '@mui/material'

const DocumentSkeleton = ({ animation }) => {
  return (
    <Grid container spacing={2}>
      {Array.from(Array(4)).map((_, index) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardHeader
              title={<Skeleton variant="rectangular" height={40} animation={animation?animation:true}/>}
              subheader={<Skeleton variant="text" animation={animation?animation:true}/>}
            ></CardHeader>
            <CardContent sx={{ flexGrow: 1 }}>
              <Skeleton variant="rectangular" height={200} animation={animation?animation:true}/>
            </CardContent>
            <CardActions disableSpacing={true}>
              <Skeleton variant="rectangular" sx={{ width: 25, height: 25, marginLeft: 'auto' }} animation={animation?animation:true} />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default DocumentSkeleton
