import axios from 'api/axios'
import useFetchDocs from '../hooks/useFetchDocs'
/**
 * Class to manage document actions (approve, reject, observations)
 * @class
 */
class DocsController {
  static get = useFetchDocs

  /**
   * Function to sign approval, rejection or observations
   * @function
   */
  static sign = async (docInfo) => {
    try {
      const response = await axios.post('documentos/gestionDocumento', docInfo, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  /**
   * Function to send token via 2FA method
   */
  static sendToken = async (method) => {
    try {
      let type = {
        tipo_df: method || '',
      }
      const response = await axios.post('documentos/getToken', type)
      return response
    } catch (error) {
      return error.response
    }
  }

  static download = async (fileData) => {
    try {
      const response = await axios.post('documentos/descargaFichero', fileData)
      return response
    } catch (error) {
      console.log(error)
    }
  }
}

export default DocsController
