import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { fetchGrupoGastos, selectGrupoGastos, createLineaGastos, statusLineaGastos, lineaGastosError } from "../features/hojasgasto/hojasGastoSlice";
import { convertirArchivosABase64 } from "../tool/funciones";


import { Select, Input, InputLabel, FormControl, MenuItem, Alert, Snackbar } from "@mui/material";
import { MuiFileInput } from "mui-file-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const formustyle = {
  display: "grid",
  "grid-template-columns": "repeat(2, 1fr)",
  gap: "1rem",
  "column-gap": "2rem",
  width: "max-content",
};

const NuevoGastoFormulario = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const gruposGastos = useSelector(selectGrupoGastos);
  const errorLineas = useSelector(lineaGastosError);
  const statusLineas = useSelector(statusLineaGastos);

  // cerrar siempre el modal con handleClose()
  const handleClose = () => {
    setOpen(false);
    resetForm(); // borrar el formulario al cerrar el modal
  };

  const divisa = useSelector((state) => state.divisa);
  const locale = useSelector((state) => state.locale);
  const [precio, setPrecio] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [fecha, setFecha] = useState(new Date(Date.now()).toISOString().split("T")[0]); // Setea la fecha predeterminada a la fecha de 'hoy' (buena idea?)
  const [grupoGastos, setGrupoGastos] = useState("");
  const [concepto, setConcepto] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [obs, setObs] = useState("");
  const [importe, setImporte] = useState("");
  const [conceptosLista, setConceptosLista] = useState([]);
  const [precioReadOnly, setPrecioReadOnly] = useState(false);
  const [adjuntoRequired, setAdjuntoRequired] = useState(false);
  const [archivo, setArchivo] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [precioMax, setPrecioMax] = useState('')


  // limitar las fechas a la de inicio y fin de la hoja de gastos
  useEffect(() => {
    const inicio = new Date(props.hoja?.fecha_inicio);
    const fin = new Date(props.hoja?.fecha_fin);
    const fActual = new Date(fecha);

    // Notificación si esto pasa ?
    if (fActual > fin) setFecha(props.hoja?.fecha_fin);
    if (fActual < inicio) setFecha(props.hoja?.fecha_inicio);
  }, [fecha]);

  // pon importe cuando cambie cantidad o precio
  useEffect(() => {
    let elImporte = 0;

    // TODO Obtener el currecy y los decimales de bd?
    if (cantidad * precio > 0) {
      elImporte = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "EUR",
      }).format(Number.parseFloat(cantidad * precio).toFixed(2));
    }

    setImporte(elImporte || "");
  }, [cantidad, precio]);

  // setea lista de conceptos de gasto cuando cambia el grupo de gastos
  useEffect(() => {
    setConcepto("");
    let concepto = gruposGastos.filter((g) => g.codigo === grupoGastos)[0];
    setConceptosLista(concepto?.conceptos || []);
  }, [grupoGastos]);

  // setea precios si tiene que setear
  useEffect(() => {
    let conceptoSeleccionado = conceptosLista.filter((c) => c.concepto === concepto)[0];
    let precioFijo = conceptoSeleccionado?.precio;
    setPrecio(precioFijo || "");
    setPrecioReadOnly(Boolean(precioFijo));
    setPrecioMax(conceptoSeleccionado?.precio_maximo || '')
    setAdjuntoRequired(conceptoSeleccionado?.obligar_adjunto === "S");
  }, [concepto]);

  useEffect(() => {
    if (errorLineas && statusLineas === "failed") {
      showAlert();
    }

    if (statusLineas === "succeeded") {
      showAlert();
      handleClose();
    }
  }, [statusLineas]);

  const handleAnadir = () => {
    // convertir lista de archivos
    convertirArchivosABase64(archivo).then((res) => {
      // cuando termine de convertir, hacer el objeto de documentos
      const datas = res;
      const nombres = archivo.map((a) => a.name);
      const doc = nombres.map((n, i) => {
        return { nombre_archivo: n, data: datas[i] };
      });

      // montar objeto para la consulta
      let linea = {
        lin: {
          fecha,
          id_hoja: props.hoja.id,
          grupo_gastos: grupoGastos,
          concepto,
          descripcion,
          observaciones: obs,
          cantidad,
          precio,
        },
        doc,
      };

      dispatch(createLineaGastos(linea));
    });
  };

  function resetForm() {
    setPrecio("");
    setCantidad("");
    setFecha(new Date(Date.now()).toISOString().split("T")[0]);
    setGrupoGastos("");
    setConcepto("");
    setDescripcion("");
    setObs("");
    setArchivo([]);
  }


  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <div>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={closeAlert} severity={statusLineas === "succeeded" ? "success" : "error"} sx={{ width: "100%" }}>
          {statusLineas === "succeeded" ? "Linea de gastos creada" : "No se pudo crear el gasto"}
        </Alert>
      </Snackbar>
      <Button onClick={handleOpen}>NUEVO GASTO</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={formustyle}>
            <FormControl>
              <InputLabel htmlFor="fecha" required>
                Fecha
              </InputLabel>
              <Input id="fecha" aria-describedby="my-helper-text" type="date" value={fecha} onChange={(e) => setFecha(e.target.value)} />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="grupo" required>
                Grupo gastos
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="grupo"
                value={grupoGastos}
                label="Age"
                required
                onChange={(e) => {
                  setGrupoGastos(e.target.value);
                }}
              >
                {/*A rellenar desde backend*/}
                {gruposGastos.map((grupo) => {
                  return <MenuItem key={grupo.codigo} value={grupo.codigo}>{grupo.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="concepto" required>
                Concepto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="concepto"
                value={concepto}
                label="Age"
                onChange={(e) => {
                  setConcepto(e.target.value);
                }}
              >
                {conceptosLista.map((con) => {
                  return <MenuItem key={con.concepto} value={con.concepto}>{con.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="descripcion" required>
                Descripción
              </InputLabel>
              <Input id="descripcion" aria-describedby="my-helper-text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="observaciones">Observaciones</InputLabel>
              <Input id="observaciones" aria-describedby="my-helper-text" value={obs} onChange={(e) => setObs(e.target.value)} />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="cantidad" required>
                Cantidad
              </InputLabel>
              <Input
                id="cantidad"
                aria-describedby="my-helper-text"
                type="number"
                slotProps={{input:{min:1}}}
                onChange={(e) => {
                  setCantidad(parseFloat(e.target.value));
                }}
                value={cantidad}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="precio" required>
                Precio
              </InputLabel>
              <Input
                id="precio"
                type="number"
                aria-describedby="my-helper-text"
                readOnly={precioReadOnly}
                slotProps={{input:{min:0, max:precioMax}}}
                required
                onChange={(e) => {
                  setPrecio(parseFloat(e.target.value));
                }}
                value={precio}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="importe">Importe</InputLabel>
              <Input id="importe" aria-describedby="my-helper-text" disabled value={importe} inputprops={{ readOnly: true }} />
            </FormControl>
            <Box>
              <InputLabel htmlFor="fichero">Añir documentos</InputLabel>
              <MuiFileInput value={archivo} onChange={(file) => setArchivo(file)} id="fichero" required={adjuntoRequired} inputProps={{ multiple: true }} />
            </Box>
            <Box sx={{ display: "grid", gap: "2rem", "grid-template-columns": "1fr 1fr" }}>
              <Button onClick={handleAnadir}>Añadir Gasto</Button>
              <Button onClick={resetForm}>Borrar formulario</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NuevoGastoFormulario;
