import Layout from 'common/layout/Layout'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { fetchMenus, getMenusStatus, reloadMenus, selectMenus } from '../../portal/menusSlice'
import { iterar_menus } from '../libs/iterateMenu'
import Hoja from 'components/Hoja'
import ResetPassword from 'features/auth/pages/ResetPassword'
import { fetchPermisos, selectUsuario } from 'features/users/stores/userSlice'
import ROUTE_COMPONENTS from '../libs/routes'
import ThemeManager from 'theming/ThemeManager'
import Logout from 'common/pages/Logout'
import { useRouteIsActive } from '../libs/routeIsActive'
import { CircularProgress } from '@mui/material'
import noticias_routes from 'features/noticias/noticias.routes'

/** @type {MenuDtoArray | null}  */
const initial = null
const RoutesController = () => {
  /**@type {MenuDtoArray}*/
  const menus = useSelector(selectMenus)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const routeIsActive = useRouteIsActive()
  const menusStatus = useSelector(getMenusStatus)
  const usuario = useSelector(selectUsuario)
  const SettingsComponent = ROUTE_COMPONENTS['settings']

  /**
   * @property {MenuDto} 0
   * @property {string} 1
   */
  const [StartRoute, setStartRoute] = useState(initial)
  const [MainComponent, setMainComponent] = useState(null)
  const [showContainer, setShowContainer] = useState(false)
  const themeController = new ThemeManager()
  const CustomLayout = themeController?.components
    ? themeController?.components['Layout'] || null
    : null

  useEffect(() => {
    // @ts-ignore
    if (menusStatus === 'idle') dispatch(fetchMenus)
  }, [menusStatus, dispatch])

  useEffect(() => {
    if (!routeIsActive) navigate('/')
  }, [routeIsActive])

  const changeVariables = () => {
    if (menus && menus.length > 0) {
      setStartRoute(
        menus.find(
          (
            /** @type {MenuDto} */
            menu,
          ) => menu.activo === 'S' && menu.programa && menu.programa.substring(0, 4) !== 'http',
        ),
      )
    }
  }
  useEffect(() => {
    const Element = StartRoute?.programa ? ROUTE_COMPONENTS[StartRoute.programa] : null
    setMainComponent(Element ? <Element /> : <></>)
    setShowContainer(StartRoute?.programa !== 'login')
  }, [StartRoute])

  useEffect(() => {
    if (
      showContainer &&
      location?.state?.url &&
      menusStatus === 'succeeded' &&
      menus.length > 1 &&
      !location?.state?.stop
    ) {
      navigate(location?.state?.url, { state: { redirected: true } })
    }
  }, [showContainer, navigate, menusStatus, menus, location?.state])

  useEffect(() => {
    changeVariables()
  })

  useEffect(() => {
    if (usuario) {
      dispatch(reloadMenus())
      dispatch(fetchPermisos())
    }
  }, [usuario, dispatch])

  useEffect(() => {
    if (menusStatus === 'idle') dispatch(fetchMenus())
  }, [menusStatus, dispatch])

  return (
    <>
      {menus?.length > 0 ? (
        <Routes>
          <Route exact key={'recuperar'} path="/recuperar" element={<ResetPassword />} />

          <Route
            path="/"
            element={
              CustomLayout ? (
                <CustomLayout container={showContainer} />
              ) : (
                <Layout container={showContainer} />
              )
            }
          >
            {MainComponent ? (
              <Route key="inicio" exact path="/" element={MainComponent} />
            ) : (
              <Route key="no-inicio" />
            )}
            {menus.map((menu) => {
              const menus = iterar_menus(menu)
              return menus
            })}
            <Route exact key={'settings'} path="/ajustes" element={<SettingsComponent />} />
            <Route exact key={'hojasgasto'} path="/hojagasto/:id" element={<Hoja />} />
            <Route exact key={'hojasgasto'} path="/logout" element={<Logout />} />
            {themeController.routes ?? <></>}
            {noticias_routes}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </>
  )
}

export default RoutesController
