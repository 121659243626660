import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../api/axios'

const initialState = {
  datos: [],
  datosStatus: 'idle',
  otrosDatos: {},
  otrosStatus: 'idle',
  datosProfesionales: [],
  profesionalesStatus: 'idle',
  beneficiosTrabajador: [],
  beneficiosStatus: 'idle',
  experienciaTrabajador: [],
  experienciaStatus: 'idle',
  formacionExterna: [],
  formacionExternaStatus: 'idle',
}

export const fetchDatosTrabajador = createAsyncThunk('/datostrabajador', async () => {
  const response = await axios.get('/datostrabajador')
  return response.data
})
export const fetchOtrosDatos = createAsyncThunk('/otrosdatos', async () => {
  const response = await axios.get('/otrosdatos')
  return response.data
})
export const fetchDatosProfesionales = createAsyncThunk('/datosprofesionales', async () => {
  const response = await axios.get('/datosprofesionales')
  return response.data
})
export const fetchBeneficiosTrabajador = createAsyncThunk('/beneficiostrabajador', async () => {
  const response = await axios.get('/beneficiostrabajador')
  return response.data
})
export const fetchExperienciaTrabajador = createAsyncThunk('/experienciatrabajador', async () => {
  const response = await axios.get('/experienciatrabajador')
  return response.data
})
export const fetchFormacionExterna = createAsyncThunk('/formacionexterna', async () => {
  const response = await axios.get('/formacionexterna')
  return response.data
})

export const datosTrabajadorSlice = createSlice({
  name: 'trabajador',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDatosTrabajador.pending, (state, action) => {
        state.datosStatus = 'loading'
      })
      .addCase(fetchDatosTrabajador.rejected, (state, action) => {
        state.datosStatus = 'failed'
      })
      .addCase(fetchDatosTrabajador.fulfilled, (state, action) => {
        state.datosStatus = 'succeeded'
        state.datos = action.payload
      })
      .addCase(fetchOtrosDatos.pending, (state, action) => {
        state.otrosStatus = 'loading'
      })
      .addCase(fetchOtrosDatos.rejected, (state, action) => {
        state.otrosStatus = 'failed'
      })
      .addCase(fetchOtrosDatos.fulfilled, (state, action) => {
        state.otrosStatus = 'succeeded'
        state.otrosDatos = action.payload
      })
      .addCase(fetchDatosProfesionales.pending, (state, action) => {
        state.profesionalesStatus = 'loading'
      })
      .addCase(fetchDatosProfesionales.rejected, (state, action) => {
        state.profesionalesStatus = 'failed'
      })
      .addCase(fetchDatosProfesionales.fulfilled, (state, action) => {
        state.profesionalesStatus = 'succeeded'
        state.datosProfesionales = action.payload
      })
      .addCase(fetchBeneficiosTrabajador.pending, (state, action) => {
        state.beneficiosStatus = 'loading'
      })
      .addCase(fetchBeneficiosTrabajador.rejected, (state, action) => {
        state.beneficiosStatus = 'failed'
      })
      .addCase(fetchBeneficiosTrabajador.fulfilled, (state, action) => {
        state.beneficiosStatus = 'succeeded'
        state.beneficiosTrabajador = action.payload
      })
      .addCase(fetchExperienciaTrabajador.pending, (state, action) => {
        state.experienciaStatus = 'loading'
      })
      .addCase(fetchExperienciaTrabajador.rejected, (state, action) => {
        state.experienciaStatus = 'failed'
      })
      .addCase(fetchExperienciaTrabajador.fulfilled, (state, action) => {
        state.experienciaStatus = 'succeeded'
        state.experienciaTrabajador = action.payload
      })
      .addCase(fetchFormacionExterna.pending, (state, action) => {
        state.formacionExternaStatus = 'loading'
      })
      .addCase(fetchFormacionExterna.rejected, (state, action) => {
        state.formacionExternaStatus = 'failed'
      })
      .addCase(fetchFormacionExterna.fulfilled, (state, action) => {
        state.formacionExternaStatus = 'succeeded'
        state.formacionExterna = action.payload
      })
  },
})

export const selectDatosTrabajador = (state) => state.trabajador.datos[0]
export const getDatosStatus = (state) => state.trabajador.datosStatus
export const selectOtrosDatos = (state) => state.trabajador.otrosDatos[0]
export const getOtrosStatus = (state) => state.trabajador.otrosStatus
export const selectDatosProfesionales = (state) => state.trabajador.datosProfesionales[0]
export const getProfesionalesStatus = (state) => state.trabajador.profesionalesStatus
export const selectBeneficiosTrabajador = (state) => state.trabajador.beneficiosTrabajador
export const getBeneficiosStatus = (state) => state.trabajador.beneficiosStatus
export const selectExperienciaTrabajador = (state) => state.trabajador.experienciaTrabajador
export const getExperienciaStatus = (state) => state.trabajador.experienciaStatus
export const selectFormacionExterna = (state) => state.trabajador.formacionExterna
export const getFormacionExternaStatus = (state) => state.trabajador.formacionExternaStatus

export default datosTrabajadorSlice.reducer
