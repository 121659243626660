import Calendario from 'features/calendario/pages/Calendario'
import DatosTrabajador from 'features/datos_trabajador/pages/DatosTrabajador'
import DocCorporativa from 'features/documentos/pages/DocCorporativa'
import DocLaboral from 'features/documentos/pages/DocLaboral'
import DocPersonal from 'features/documentos/pages/DocPersonal'
import ListaUsuarios from 'features/users/pages/ListaUsuarios'
import MyUsers from 'features/users/pages/MyUsers'
import Home from 'common/pages/Home'
import Login from 'features/auth/pages/Login'
import Logout from 'common/pages/Logout'
import Noticias from '../../noticias_web/pages/Noticias'
import EdicionNoticias from '../../noticias_web/pages/EdicionNoticias'
import Organigrama from 'features/organigrama/pages/Organigrama'
import VisorEnlaces from 'features/enlaces/components/VisorEnlaces'
import EdicionEnlaces from 'features/enlaces/pages/EdicionEnlaces'
import Preguntas from 'features/preguntas/pages/VisorPreguntas'
import EdicionPreguntas from 'features/preguntas/pages/EditorPregunta'
import ListadoSolicitudes from 'features/solicitudes/pages/ListadoSolicitudes'
import Solicitud from 'features/solicitudes/pages/Solicitud'
import MisSolicitudes from 'features/solicitudes/components/MisSolicitudes'
import SolicitudesPendientes from 'features/solicitudes/components/SolicitudesPendientes'
import FirmaDocumentos from 'features/firma_documentos/pages/FirmaDocumentos'
import Settings from 'features/ajustes/pages/Settings'
import ThemeManager from 'theming/ThemeManager'
import NewsPage from 'features/noticias/pages/NewsPage'
import Model145 from 'features/datos_trabajador/pages/Model145'
import VistaFacturas from 'features/vista_facturas/pages/VistaFacturas'

const theme = new ThemeManager()

const ROUTE_COMPONENTS = {
  login: Login,
  home: Home,
  datostrabajador: DatosTrabajador,
  calendario: Calendario,
  doclaboral: DocLaboral,
  doccorporativa: DocCorporativa,
  docpersonal: DocPersonal,
  organigrama: Organigrama,
  visorNoticias: Noticias,
  editorNoticias: EdicionNoticias,
  visorPreguntas: Preguntas,
  editorPreguntas: EdicionPreguntas,
  visorEnlaces: VisorEnlaces,
  editorEnlaces: EdicionEnlaces,
  logout: Logout,
  // usuarios: ListaUsuarios ,
  usuarios: MyUsers,
  listasolicitudes: ListadoSolicitudes,
  solicitud: Solicitud,
  solicitudes: MisSolicitudes,
  pendientes: SolicitudesPendientes,
  firmaFicheros: FirmaDocumentos,
  settings: Settings,
  noticias: NewsPage,
  model145: Model145,
  vistaFacturas: VistaFacturas,
  //crear entrada tipo nombreMenu (db): nombre de la vista (crear componente)
}

if (theme?.activeTheme?.pages) {
  Object.keys(theme.activeTheme.pages).forEach((key) => {
    if (theme.activeTheme.pages[key]) ROUTE_COMPONENTS[key] = theme.activeTheme.pages[key]
  })
}

export default ROUTE_COMPONENTS
