import { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { selectTheme } from 'default_themes'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import SnackbarCloseButton from 'common/components/SnackbarCloseButton'
import RoutesController from 'features/routing/components/RoutesController'
import ThemeManager from 'theming/ThemeManager'
import AutomationController from 'automations/AutomationController'
import styled from '@emotion/styled'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '1em',
  },
}))

function App() {
  const themeController = new ThemeManager()

  if (themeController.activeTheme.options.fullHideMenu)
    localStorage.setItem('NO_EXPAND_MENU', 'true')

  const [themePallete] = useState(
    createTheme({
      palette: themeController.activePallete(),
      components: themeController?.activeTheme?.components_styles ?? {},
    }),
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={themePallete}>
        <CssBaseline enableColorScheme={true}>
          <SnackbarProvider
            action={(key) => <SnackbarCloseButton snackbarKey={key} />}
            autoHideDuration={2000}
            Components={{
              default: StyledMaterialDesignContent,
              info: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              success: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            maxSnack={3}
            SnackbarProps={{}}
          >
            <AutomationController />
            <RoutesController />
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
