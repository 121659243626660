import { CardActions, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'
import { getFechaFromISO } from 'common/libs/fechas'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'
import AccionesSolicitud from './AccionesSolicitud'

const CabeceraSolicitud = (/**@type {CabeceraSolicitud} */ { solicitud }) => {
  const user = useSelector(selectUsuario)
  console.log({ solicitud })

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Typography key="sol_desc" variant="h4" sx={{ flexGrow: 1 }}>
            {solicitud.descripcion ?? solicitud.d_tipo}
          </Typography>
          {solicitud.status ? getChipSolicitud(solicitud.status, solicitud.d_status) : ''}
        </Stack>

        {solicitud.ayuda ? (
          <Typography key="sol_ayu" variant="subtitle1" gutterBottom>
            {solicitud.ayuda}
          </Typography>
        ) : (
          ''
        )}

        {solicitud.d_dni ? (
          <Stack direction="row" spacing={2}>
            <List subheader={<ListSubheader>Solicitante</ListSubheader>}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={solicitud.d_dni?.split(', ')[1]}
                    src={`/assets/images/${user.codigo_empresa}_${user.codigo_portal}_${solicitud.dni_usuario}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={solicitud.d_dni}
                  secondary={getFechaFromISO(solicitud.fecha_creacion)}
                />
              </ListItem>
            </List>
            {solicitud.status > 201 ? (
              <List subheader={<ListSubheader>Validador</ListSubheader>}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={solicitud.d_validador?.split(', ')[1]}
                      src={`/assets/images/${user.codigo_empresa}_${user.codigo_portal}_${solicitud.validador_usuario}.jpg`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={solicitud.d_validador}
                    secondary={getFechaFromISO(solicitud.status_fecha)}
                  />
                </ListItem>
              </List>
            ) : null}
          </Stack>
        ) : null}
      </CardContent>
      {solicitud?.acciones ? (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <AccionesSolicitud
            codigo_solicitud={solicitud.codigo_solicitud}
            origen_solicitud={solicitud.origen_solicitud}
            acciones={solicitud.acciones}
          />
        </CardActions>
      ) : null}
    </Card>
  )
}

export default CabeceraSolicitud
