import { Paper, Typography } from '@mui/material'
import logo from 'assets/images/logo.svg'
import useQuery from 'common/hooks/useQuery'
import LoginBackground from '../components/LoginBackground'
import PasswordForm from '../components/PasswordForm'
import { Link } from 'react-router-dom'

const ResetPassword = () => {
  const query = useQuery()
  return (
    <LoginBackground loading={false}>
      <Paper sx={{ backgroundColor: 'background.paper', p: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/">
            <img src={logo} alt="Logo" style={{ maxHeight: '40px', width: '100%' }} />
          </Link>
        </div>
        <div
          style={{
            minWidth: '25ch',
            width: '100%',
            mx: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" sx={{ m: 3 }}>
            Establecer nueva contraseña
          </Typography>
        </div>

        <PasswordForm token={query.get('t')} buttonWidth="100%" variant="standard" />
      </Paper>
    </LoginBackground>
  )
}

export default ResetPassword
