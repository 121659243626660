import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  editarLineaGastos,
  selectGrupoGastos,
  getEditarLineaStatus,
  getEditarLineaError,
  deleteLineaGastos,
  selectHojasGasto,
} from "../features/hojasgasto/hojasGastoSlice";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Select, Input, InputLabel, FormControl, MenuItem, Alert, Snackbar } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import DocumentosCuadro from "./DocumentosCuadro";
import Confirmacion from "./Confirmacion";
import { convertirArchivosABase64 } from "../tool/funciones";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  'max-width':'90vw',
  'max-height':'80vw',
};

const formustyle = {
  'display': 'grid',
  'grid-template-columns': 'repeat(2, 1fr)',
  'gap': '1rem',
  'column-gap': '2rem',
  'width': 'max-content',
};



const EditarLineaGastos = (props) => {
  const locale = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const gruposGastos = useSelector(selectGrupoGastos);
  const [precio, setPrecio] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [fecha, setFecha] = useState(""); // Setea la fecha predeterminada a la fecha de 'hoy' (buena idea?)
  const [grupoGastos, setGrupoGastos] = useState("");
  const [concepto, setConcepto] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [obs, setObs] = useState();
  const [importe, setImporte] = useState("");
  const [conceptosLista, setConceptosLista] = useState([]);
  const [precioReadOnly, setPrecioReadOnly] = useState(false);
  const [adjuntoRequired, setAdjuntoRequired] = useState(false);
  const [archivo, setArchivo] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const errorLineas = useSelector(getEditarLineaError);
  const statusLineas = useSelector(getEditarLineaStatus);
  const [docs, setDocs] = useState([]);
  const hojas = useSelector(selectHojasGasto);
  const [hojaEnState, setHojaEnState] = useState();
  const [lineaEnState, setLineaEnState] = useState();
  const [abierto, setAbierto] = useState(false)
  const [precioMax, setPrecioMax] = useState()

  useEffect(() => {
    if (props.lin && props.hoja) {
      const hojaEffect = hojas.filter((h) => Number(h.id) === Number(props.hoja))[0];
      const lineaEffect = hojaEffect.lineas.filter((lin) => Number(lin.id_lin) === Number(props.lin))[0];
      setHojaEnState(hojaEffect);
      setLineaEnState(lineaEffect);
      setAbierto(!Boolean(hojaEffect.codint)) // true si no hay codint (hoja abierta)
    } else {
      setHojaEnState();
      setLineaEnState();
    }
  }, [props.lin, props.hoja, hojas]);

  useEffect(() => {
    if (lineaEnState) {
      setFecha(lineaEnState.fecha);
      setCantidad(lineaEnState.cantidad);
      setGrupoGastos(lineaEnState.grupo_gastos);
      setConcepto(lineaEnState.concepto);
      setDescripcion(lineaEnState.descripcion);
      setObs(lineaEnState.observaciones);
      setImporte(lineaEnState.importe);
      setDocs(lineaEnState.documentos);
      setPrecio(lineaEnState.precio);
    } else {
      setFecha("");
      setCantidad("");
      setGrupoGastos("");
      setConcepto("");
      setDescripcion("");
      setObs("");
      setImporte("");
      setDocs([]);
      setPrecio("");
      setArchivo([])
    }
  }, [lineaEnState]);



  // limitar las fechas a la de inicio y fin de la hoja de gastos
  useEffect(() => {
    if (hojaEnState && lineaEnState) {
      const inicio = new Date(hojaEnState.fecha_inicio);
      const fin = new Date(hojaEnState.fecha_fin);
      const fActual = new Date(fecha);

      if (fActual > fin) setFecha(hojaEnState.fecha_fin);
      if (fActual < inicio) setFecha(hojaEnState.fecha_inicio);
    }
  }, [fecha]);

  // pon importe cuando cambie cantidad o precio
  useEffect(() => {
    if (lineaEnState) {
      let elImporte = 0;

      // TODO Obtener el currecy y los decimales de bd?
      if (cantidad * precio > 0) {
        elImporte = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: "EUR",
        }).format(Number.parseFloat(cantidad * precio).toFixed(2));
      }

      setImporte(elImporte || "");
    }
  }, [cantidad, precio]);

  // setea lista de conceptos de gasto cuando cambia el grupo de gastos
  useEffect(() => {
    if(lineaEnState){
      let concepto = gruposGastos.filter((g) => Number(g.codigo) === Number(grupoGastos))[0];
      setConceptosLista(concepto?.conceptos || []);
    }
  }, [grupoGastos]);

  // setea precios si tiene que setear
  useEffect(() => {
    if (lineaEnState) {
      let conceptoSeleccionado = conceptosLista.filter((c) => Number(c.concepto) === Number(concepto))[0];
      let precioFijo = conceptoSeleccionado?.precio;

      setPrecio(precioFijo || lineaEnState.precio || "");
      console.log(':',conceptosLista);
      setPrecioReadOnly(Boolean(precioFijo));
      setPrecioMax(conceptoSeleccionado?.precio_maximo || '')
      setAdjuntoRequired(conceptoSeleccionado?.obligar_adjunto === "S");
    }
  }, [concepto]);

  useEffect(() => {
    if (errorLineas && statusLineas === "failed") {
      showAlert();
    }

    if (statusLineas === "succeeded") {
      showAlert();
      handleClose();
    }
  }, [statusLineas]);

  const handleClose = () => {
    props.cerrarModal(undefined, false);
  };


  const handleEditar = () => {
    // convertir lista de archivos
    convertirArchivosABase64(archivo)
    .then((res) => {
      // cuando termine de convertir, hacer el objeto de documentos
      const datas = res;
      const nombres = archivo.map((a) => a.name);
      const doc = nombres.map((n, i) => {
        return { nombre_archivo: n, data: datas[i] };
      });

      //Montar objeto

      let codigoGasto = gruposGastos.filter(g => Number(g.codigo) === Number(grupoGastos))[0].codigo
      let listaConceptos = gruposGastos.filter(g => Number(g.codigo) === Number(grupoGastos))[0].conceptos
      let conceptoGasto = listaConceptos.filter(c => Number(c.concepto) === Number(concepto))[0].concepto

      let linea = {
        lin: {
          fecha,
          id_hoja: hojaEnState.id,
          id_lin: props.lin,
          grupo_gastos: codigoGasto,
          concepto: conceptoGasto,
          descripcion,
          observaciones: obs,
          cantidad,
          precio,
        },
        doc,
      };

      dispatch(editarLineaGastos(linea));
    })

  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleBorrar = () => {
    dispatch(deleteLineaGastos({ id_lin: props.lin }));
    handleClose();
  };

  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={closeAlert} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={closeAlert} severity={statusLineas === "succeeded" ? "success" : "error"} sx={{ width: "100%" }}>
          {statusLineas === "succeeded" ? "Linea de gastos creada" : "No se pudo crear el gasto"}
        </Alert>
      </Snackbar>
      <Modal open={props.open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" sx={{'max-height':'85vw'}}>
        <Box sx={style}>
          <Box sx={formustyle}>
            <FormControl>
              <InputLabel htmlFor="fecha" required>
                Fecha
              </InputLabel>
              <Input 
                id="fecha" 
                aria-describedby="my-helper-text" 
                type="date"
                disabled={!abierto}
                value={fecha} onChange={(e) => setFecha(e.target.value)} 
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="grupo" required>
                Grupo gastos
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="grupo"
                value={Number(grupoGastos)}
                label="Age"
                required
                disabled={!abierto}
                onChange={(e) => {
                  setGrupoGastos(e.target.value);
                }}
              >
                {gruposGastos.map((grupo) => {
                  return <MenuItem key={Number(grupo.codigo)} value={Number(grupo.codigo)}>{grupo.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="concepto" required>
                Concepto
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="concepto"
                value={Number(concepto)}
                label="Age"
                disabled={!abierto}
                onChange={(e) => {
                  setConcepto(e.target.value);
                }}
              >
                {conceptosLista.map((con) => {
                  return <MenuItem key={Number(con.concepto)} value={Number(con.concepto)}>{con.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="descripcion" required>
                Descripción
              </InputLabel>
              <Input 
                id="descripcion" 
                aria-describedby="my-helper-text" 
                value={descripcion}
                disabled={!abierto}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="observaciones">Observaciones</InputLabel>
              <Input 
                id="observaciones" 
                aria-describedby="my-helper-text" 
                value={obs}
                disabled={!abierto}
                onChange={(e) => setObs(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="cantidad" required>
                Cantidad
              </InputLabel>
              <Input
                id="cantidad"
                aria-describedby="my-helper-text"
                type="number"
                disabled={!abierto}
                slotProps={{ input: { min: 1 } }}
                onChange={(e) => {
                  setCantidad(parseFloat(e.target.value));
                }}
                value={cantidad}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="precio" required>
                Precio
              </InputLabel>
              <Input
                id="precio"
                type="number"
                aria-describedby="my-helper-text"
                readOnly={precioReadOnly}
                slotProps={{ input: { min: 0, max:precioMax } }}
                required
                disabled={!abierto}
                onChange={(e) => {
                  setPrecio(parseFloat(e.target.value));
                }}
                value={Number(precio)}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="importe">Importe</InputLabel>
              <Input id="importe" aria-describedby="my-helper-text" disabled value={importe} inputprops={{ readOnly: true }} disabled={!abierto}/>
            </FormControl>
            {abierto
              ? <Box>
                  <InputLabel htmlFor="fichero">Fichero</InputLabel>
                  <MuiFileInput value={archivo} onChange={(file) => setArchivo(file)} id="fichero" required={adjuntoRequired} inputProps={{ multiple: true }} />
                </Box>
              : ''
            }
            <DocumentosCuadro docs={docs} permiteBorrar={abierto} />
            {abierto
              ? <Box sx={{ display: "grid", gap: "2rem", "grid-template-columns": "1fr 1fr" }}>
                  <Button onClick={handleEditar}>Editar Linea</Button>
                  <Confirmacion 
                    botonTxt={"Borrar"} 
                    aceptarFn={handleBorrar} 
                    title={"Borrar Gasto"} 
                    text={`¿Borrar "${lineaEnState?.descripcion}"?`} />
                </Box>
              : ''
            }
            
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditarLineaGastos;