import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  consultaInicial,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'

import axios from 'api/axios'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

function ListComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState()
  const [inputValue, setInputValue] = useState()

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [lvLoading, setLvLoading] = useState(false)
  const [error, setError] = useState('')

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor })
      }
    }
    return valores
  }

  useEffect(() => {
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
  }, [valor])

  useEffect(() => {
    if (open && options.length === 0) {
      setLvLoading(true)
      const valores = getValoresConsulta()
      axios
        .post(`/consultalov/${idpk}/`, { valores, busqueda: value })
        .then((request_response) => {
          const data = request_response.data.respuesta
          setOptions(data.registros)
        })
        .catch((error) => setError(error))
        .finally(() => setLvLoading(false))
    }
  }, [open])

  const handleChange = (nuevoValor) => {
    dispatch(
      setDatoSolicitud({
        idpk: campo.idpk,
        valor: nuevoValor.codigo,
        descripcion: nuevoValor.descripcion,
      })
    )
    setValue(nuevoValor)
  }

  if (campo) {
    return (
      <Autocomplete
        disablePortal
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={(option, val) => {
          return option.codigo === valor
        }}
        getOptionLabel={(option) => option.descripcion}
        options={options}
        loading={lvLoading}
        key={`list-${campo.idpk}`}
        id={campo.codigo_campo}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={campo.etiqueta}
            error={campo.error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {lvLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    )
  }
}

export default ListComponent
