import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  consultaInicial,
  selectDatosSolicitud,
  setDatoSolicitud,
} from 'features/solicitudes/solicitudesSlice'

import axios from 'api/axios'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

function RadioComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState('')
  const [options, setOptions] = useState([])
  const [lvLoading, setLvLoading] = useState(false)
  const [error, setError] = useState('')

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor })
      }
    }
    return valores
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
  }, [])

  useEffect(() => {
    if (options.length === 0) {
      setLvLoading(true)
      const valores = getValoresConsulta()
      axios
        .post(`/consultalov/${idpk}/`, { valores, busqueda: value })
        .then((request_response) => {
          const data = request_response.data.respuesta
          setOptions(data.registros)
        })
        .catch((error) => setError(error))
        .finally(() => setLvLoading(false))
    }
  }, [options])

  useEffect(() => {
    setValue(valor)
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
  }, [valor])

  const handleChange = (e) => {
    debugger
    const descripcion = options.filter((o) => o.codigo === e.target.value)[0].descripcion
    dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: e.target.value, descripcion }))
  }

  if (campo) {
    return (
      <FormControl>
        <FormLabel id={`r-${campo.idpk}`}>{campo.etiqueta}</FormLabel>
        <RadioGroup
          row
          aria-labelledby={campo.etiqueta}
          name={campo.codigo_campo}
          value={value}
          onChange={handleChange}
          color={campo.error ? 'error' : ''}
        >
          {options.map((o) => (
            <FormControlLabel
              key={o.codigo}
              value={o.codigo}
              control={<Radio />}
              label={o.descripcion}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }
}

export default RadioComponent
