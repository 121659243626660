import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCamposSolicitud,
  consultaInicial,
  selectDatosSolicitud,
  setDatoSolicitud,
  setCampoCargando,
} from 'features/solicitudes/solicitudesSlice'

import dayjs from 'dayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import { esES } from '@mui/x-date-pickers/locales'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function DateComponent({ idpk }) {
  const dispatch = useDispatch()
  const campos = useSelector(selectCamposSolicitud)
  const campo = campos.filter((c) => c.idpk === idpk)[0]

  const datos = useSelector(selectDatosSolicitud)
  const valor = datos[campo.idpk]

  const [value, setValue] = useState(null)

  const getValoresConsulta = () => {
    const valores = []
    for (const idpk in datos) {
      if (Object.hasOwnProperty.call(datos, idpk)) {
        const valor = datos[idpk]
        const field = campos.filter((c) => Number(c.idpk) === Number(idpk))[0]
        valores.push({ codigo_campo: field.codigo_campo, valor })
      }
    }
    return valores
  }

  useEffect(() => {
    if (campo && campo.inicializar) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: true }))
      dispatch(consultaInicial({ idpk: campo.idpk, valores: [] }))
    }
  }, [dispatch, campo])

  useEffect(() => {
    setValue(valor)
    if (valor) {
      dispatch(setCampoCargando({ idpk: campo.idpk, cargando: false }))
    }
    // Lanzamos las queries iniciales que contienen a este campo
    campos.forEach((c) => {
      if (c.depende_de && c.depende_de.includes(campo.codigo_campo) && valor) {
        const valores = getValoresConsulta()
        dispatch(consultaInicial({ idpk: c.idpk, valores }))
      }
    })
  }, [valor])

  const handleChange = (nuevo_valor) => {
    dispatch(setDatoSolicitud({ idpk: campo.idpk, valor: nuevo_valor }))
  }

  if (campo && campo.visible === 'S') {
    return (
      <LocalizationProvider
        localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale="es"
      >
        <DatePicker
          label={campo.etiqueta}
          value={value}
          onChange={(v) => handleChange(v)}
          slotProps={{
            textField: {
              helperText: campo.ayuda,
              error: campo.error,
            },
          }}
        />
      </LocalizationProvider>
    )
  }
}

export default DateComponent
