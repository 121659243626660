import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Typography from '@mui/material/Typography'
import ActionsMenu from './ActionsMenu'
import { Button, Box, Checkbox, Alert, Snackbar } from '@mui/material'

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { descargarDocumento } from '../libs/descargarDocumento'
import { useState } from 'react'
import DocumentDialogs from './DocumentDialogs'

const TarjetaDocumento = ({
  item,
  index,
  setAction,
  action,
  setTrigger,
  selectedDocs,
  setSelectedDocs,
}) => {
  const defaultErrorState = {
    error: false,
    code: 0,
    msg: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [errorStatus, setErrorStatus] = useState(defaultErrorState)

  const verDocumentoPdf = async () => {
    const downloadData = await descargarDocumento(item, 'pdf')
    window.open(downloadData.url, '_blank')
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorStatus(defaultErrorState)
  }

  const handleDocumentSelect = (checked) => {
    if (checked) {
      setSelectedDocs((prevSet) => {
        const updatedSet = new Set(prevSet)
        updatedSet.add(item.id_documento)
        return updatedSet
      })
    } else {
      setSelectedDocs((prevSet) => {
        const updatedSet = new Set(prevSet)
        updatedSet.delete(item.id_documento)
        return updatedSet
      })
    }
  }

  return (
    <>
      <Card
        key={index}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <p className={`document-status-label-${item.color_texto_firma}`}>
              {item.literal_texto_firma}
            </p>
          </div>
          {item.puede_firmar === 'S' && item.permitir_adjuntar_documentos === 'N' ? (
            <Checkbox
              checked={selectedDocs.has(item.id_documento)}
              onChange={(e) => {
                handleDocumentSelect(e.target.checked)
              }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
            />
          ) : null}
        </Box>

        <CardHeader
          title={item.nombre_fichero.replaceAll('_', ' ').split('.')[0]}
          subheader={
            <div className="card-subheader">
              <p className="card-subheader-number">{item.id_documento.split('-')[1]}</p>
              <p className="card-subheader-date">
                {new Date(item.fecha_documento).toLocaleString('es-ES').split(',')[0]}
              </p>
            </div>
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          {item.informacion_cabecera_documento_list.map((literal, index) => (
            <Typography variant="body2" color="text.secondary" key={index}>
              <span style={{ fontWeight: 'bold' }}>{literal.literal}</span>
              <br />
              {literal.texto}
            </Typography>
          ))}
        </CardContent>

        <CardActions disableSpacing={true}>
          <div>
            {item.mime_type === 'application/pdf' ? (
              <Button onClick={verDocumentoPdf}>
                <PictureAsPdfIcon
                  sx={{
                    marginRight: '0.2rem',
                    color: 'red',
                  }}
                />
                Ver documento
              </Button>
            ) : null}
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <ActionsMenu
              puedeFirmar={item.puede_firmar}
              docData={{
                index: index,
                id_archivo: item.id_archivo,
                token_acceso: item.token_acceso,
                mime_type: item.mime_type,
              }}
              setModalOpen={setModalOpen}
              setAction={setAction}
              setTrigger={setTrigger}
            />
          </div>
        </CardActions>
      </Card>

      <DocumentDialogs
        setErrorStatus={setErrorStatus}
        docData={[item]}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        action={action}
        setTrigger={setTrigger}
      />
      {errorStatus.error ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorStatus.error}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={errorStatus.code > 300 ? 'error' : 'success'}
            sx={{ width: '100%' }}
          >
            {errorStatus.msg}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  )
}

export default TarjetaDocumento
