// @ts-check
import { GridView, TableRows } from '@mui/icons-material'
import { Button, ButtonGroup } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import Laboral from '../components/LaboralGridView'
import LaboralTableView from '../components/LaboralTableView'

const DocLaboral = () => {
  /**
   * @type {[ViewType,  import('react').Dispatch<import('react').SetStateAction<ViewType>>]} viewType
   */
  const [viewType, setViewType] = useState('TABLE')

  return (
    <>
      <Typography variant="h6" component="h2" mt={2} mb={2}>
        Documentación Laboral
      </Typography>
      <ButtonGroup>
        <Button
          variant={viewType === 'TABLE' ? 'contained' : 'outlined'}
          onClick={() => {
            setViewType('TABLE')
          }}
        >
          <TableRows />
        </Button>
        <Button
          variant={viewType === 'TABLE' ? 'outlined' : 'contained'}
          onClick={() => {
            setViewType('GRID')
          }}
        >
          <GridView />
        </Button>
      </ButtonGroup>

      {viewType === 'TABLE' ? <LaboralTableView /> : <Laboral />}
    </>
  )
}

export default DocLaboral
