import { useSelector, useDispatch } from 'react-redux'

import React, { useState, useEffect } from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/es'

import { selectEmpresasPermisos, selectPermisos } from '../../users/stores/userSlice'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CustomTable from 'common/components/CustomTable'
import FaqController from '../libs/FaqController'
import { enqueueSnackbar } from 'notistack'
import { CardHeader, IconButton, Typography } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const EditorPregunta = () => {
  const dispatch = useDispatch()
  const { response, loading } = FaqController.get()
  const [preguntas, setPreguntas] = useState(response)
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => setPreguntas(response), [response])
  useEffect(() => setIsLoading(loading), [loading])

  const ahora = new Date()

  const [editing, setEditing] = useState(0)

  const [respuesta, setRespuesta] = useState('')
  const [visible, setVisible] = useState(true)
  const [orden, setOrden] = useState(1)
  const [correo, setCorreo] = useState(false)
  const [empresa, setEmpresa] = useState([])
  const [fecha, setFecha] = useState(dayjs(ahora.toISOString().split('T')[0]))
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const [openDialog, setOpenDialog] = React.useState(false)

  const permisos = useSelector(selectEmpresasPermisos)

  const handleRespuesta = (e) => setRespuesta(e.target.value)
  const handleVisible = (e) => setVisible(e.target.checked)
  const handleOrden = (e) => setOrden(e.target.value)
  const handleCorreo = (e) => setCorreo(e.target.checked)
  const handleEmpresa = (event) => {
    const {
      target: { value },
    } = event
    setEmpresa(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSelectPregunta = (id) => {
    const pregunta = preguntas.filter((n) => n.id === id)[0]
    setSelectedQuestion(pregunta)
    setRespuesta(pregunta.texto_respuesta)
    setVisible(pregunta.visible)
    setOrden(pregunta.orden)
    setCorreo(pregunta.enviar_correo)
    setEmpresa(pregunta.rh_cif.split(','))
    setFecha(pregunta.fecha_respuesta ? dayjs(pregunta.fecha_respuesta) : fecha)
    setEditing(id)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleEliminarPregunta = async () => {
    const deletedQuestion = await FaqController.delete(editing)
    if (deletedQuestion) {
      enqueueSnackbar('Pregunta eliminada satisfactoriamente', { variant: 'success' })
      const index = preguntas.findIndex(
        (currentQuestion) => currentQuestion.id === deletedQuestion.id,
      )
      const newState = [...preguntas]
      newState.splice(index, 1)
      setPreguntas(newState)
    } else {
      enqueueSnackbar('Ha ocurrido un error y no se pudo eliminar la pregunta', {
        variant: 'error',
      })
    }
    setEditing(0)
  }

  const handleSave = async () => {
    const question = {
      id: editing,
      texto_respuesta: respuesta,
      visible: visible ? 'S' : 'N',
      orden: orden,
      correo: correo ? 'S' : 'N',
      fecha_respuesta: fecha.toISOString().split('T')[0],
      rh_cif: empresa.join(','),
    }
    if (editing > 0) {
      const updatedQuestion = await FaqController.update(question)
      if (updatedQuestion) {
        enqueueSnackbar('Pregunta modificada satisfactoriamente', { variant: 'success' })
        const newState = [...preguntas]
        const index = newState.findIndex(
          (curentQuestion) => curentQuestion.id === updatedQuestion.id,
        )
        newState[index] = updatedQuestion
        setPreguntas(newState)
      } else {
        enqueueSnackbar('Ha ocurredo un error y no se pudo guardar la pregunta', {
          variant: 'error',
        })
      }
      // dispatch(updatePregunta(question))
    } else {
      const newQuestion = await FaqController.create(question)
      if (newQuestion) {
        enqueueSnackbar('Pregunta creada satisfactoriamente', { variant: 'success' })
        setPreguntas([...preguntas, newQuestion])
      } else {
        enqueueSnackbar('Ha ocurrido un error y no se pudo guardar la pregunta', {
          variant: 'error',
        })
      }
    }
    setEditing(0)
  }

  if (editing === 0) {
    return (
      <div style={{ width: '100%' }}>
        <CustomTable
          hideFooter={false}
          initialPageSize={10}
          loading={isLoading}
          showToolbar={true}
          dataSource={preguntas ?? []}
          fields={[
            { value: 'usuario_pregunta', key: 'Usuario', width: 150, sortable: true, flex: 1 },
            { value: 'texto_pregunta', key: 'Pregunta', width: 150, sortable: true, flex: 1 },
            { value: 'fecha_pregunta', key: 'Fecha', width: 150, sortable: true, flex: 1 },
            { value: 'respuesta', key: 'Respuesta', width: 150, sortable: false, flex: 1 },
            {
              value: 'fecha_respuesta',
              key: 'Fecha Publicacion',
              width: 150,
              sortable: true,
              flex: 1,
            },
            { value: 'visible', key: 'Visible', width: 150, sortable: false, flex: 1 },
            { value: 'orden', key: 'Orden', width: 150, sortable: true, flex: 1 },
          ]}
          onClickEvent={(params, _, __) => {
            handleSelectPregunta(params.id)
          }}
        />
      </div>
    )
  }

  return (
    <Box component="form" noValidate autoComplete="off">
      <Card>
        <CardHeader
          title={
            <>
              <IconButton
                onClick={() => setEditing(0)}
                sx={{ mr: 2, transform: 'translateY(-3px)' }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="title">Responder Pregunta</Typography>
            </>
          }
          subheader={selectedQuestion?.texto_pregunta ?? '---'}
        />
        <CardContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <FormGroup sx={{ flexGrow: 1 }}>
                <TextField
                  id="respuesta"
                  label="Respuesta"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={respuesta ?? ''}
                  onChange={handleRespuesta}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Visible"
                  checked={visible === true || visible === 'S'}
                  onChange={handleVisible}
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <FormControl sx={{ width: 300 }}>
                <InputLabel id="empresas-label">Empresa</InputLabel>
                <Select
                  labelId="empresas-label"
                  id="empresa-selector"
                  multiple
                  value={empresa ?? ''}
                  onChange={handleEmpresa}
                  input={<OutlinedInput label="Empresa" />}
                  renderValue={(selected) => selected?.join(',')}
                  MenuProps={MenuProps}
                >
                  {permisos.map((p) =>
                    p.rh_cif ? (
                      <MenuItem key={p.id} value={p.rh_cif}>
                        <Checkbox checked={empresa.indexOf(p.rh_cif) > -1} />
                        <ListItemText primary={p.d_rh_cif} />
                      </MenuItem>
                    ) : null,
                  )}
                </Select>
              </FormControl>
              <FormGroup sx={{ flexGrow: 1 }}>
                <TextField
                  id="orden"
                  label="Orden"
                  variant="outlined"
                  value={orden ?? ''}
                  onChange={handleOrden}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Enviar correo"
                  checked={correo === true || correo === 'S'}
                  onChange={handleCorreo}
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <DemoContainer components={['DatePicker']} sx={{ flexGrow: 1 }}>
                  <DatePicker
                    label="Fecha publicación"
                    value={fecha ?? ''}
                    onChange={(newValue) => setFecha(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={handleSave}>
                Guardar
              </Button>
              <Button variant="contained" onClick={() => setEditing(0)}>
                Cancelar
              </Button>
              <Button variant="outlined" color="error" onClick={handleOpenDialog}>
                Eliminar
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Eliminar Notiica'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de querer eliminar esta pregunta?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>No</Button>
          <Button onClick={handleEliminarPregunta} autoFocus>
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default EditorPregunta
