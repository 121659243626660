import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axiosPE from '../../api/axios'

const initialState = {
    laboral: [],
    statusLaboral: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    corporativa: [],
    statusCorporativa: 'idle',
    arbolPersonal: [],
    statusArbolPersonal: 'idle',
    personal: [],
    statusPersonal: 'idle',
    error: '',
    statusDescarga: 'idle'
}

export const fetchDocLaboral = createAsyncThunk('/doclaboral', async () => {
    const response = await axiosPE.get('/doclaboral');
    return response.data;
})
export const fetchDocCorporativa = createAsyncThunk('/doccorporativa', async () => {
    const response = await axiosPE.get('/doccorporativa');
    return response.data;
})
export const fetchDocPersonalArbol = createAsyncThunk('/docpersonal/tree', async () => {
    const response = await axiosPE.get('/docpersonal/tree');
    return response.data;
})
export const fetchDocPersonal = createAsyncThunk('/docpersonal', async (codigo_nivel) => {
    const response = await axiosPE.get(`/docpersonal/?n=${codigo_nivel}`);
    return response.data;
})
export const descargaDocumento = createAsyncThunk('/descargararchivo', async (id) => {
    const response = await axiosPE.get(`/descargararchivo/?t=laboral&f=${id}`);
    return response.data;
})

export const documentosSlice = createSlice({
    name: 'documentos',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchDocLaboral.pending, (state, action) => {
            state.statusLaboral = 'loading'
        })
            .addCase(fetchDocLaboral.rejected, (state, action) => {
                state.statusLaboral = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDocLaboral.fulfilled, (state, action) => {
                state.statusLaboral = 'succeeded'
                state.laboral = action.payload
            })
            .addCase(fetchDocCorporativa.pending, (state, action) => {
                state.statusCorporativa = 'loading'
            })
            .addCase(fetchDocCorporativa.rejected, (state, action) => {
                state.statusCorporativa = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDocCorporativa.fulfilled, (state, action) => {
                state.statusCorporativa = 'succeeded'
                state.corporativa = action.payload
            })
            .addCase(fetchDocPersonalArbol.pending, (state, action) => {
                state.statusArbolPersonal = 'loading'
            })
            .addCase(fetchDocPersonalArbol.rejected, (state, action) => {
                state.statusArbolPersonal = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDocPersonalArbol.fulfilled, (state, action) => {
                state.statusArbolPersonal = 'succeeded'
                state.arbolPersonal = action.payload
            })
            .addCase(fetchDocPersonal.pending, (state, action) => {
                state.statusPersonal = 'loading'
            })
            .addCase(fetchDocPersonal.rejected, (state, action) => {
                state.statusPersonal = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDocPersonal.fulfilled, (state, action) => {
                state.statusPersonal = 'succeeded'
                state.personal = action.payload
            })
            .addCase(descargaDocumento.pending, (state, action) => {
                state.statusDescarga = 'loading'
            })
            .addCase(descargaDocumento.rejected, (state, action) => {
                state.statusDescarga = 'failed'
                state.error = action.error.message
            })
            .addCase(descargaDocumento.fulfilled, (state, action) => {
                state.statusDescarga = 'succeeded'
                const binary = atob(action.payload.fichero.replace(/\s/g, ""));
                const len = binary.length;
                const buffer = new ArrayBuffer(len);
                const view = new Uint8Array(buffer);

                for (let i = 0; i < len; i += 1) {
                    view[i] = binary.charCodeAt(i);
                }

                const blob = new Blob([view], { type: action.payload.mime_type });
                const url = URL.createObjectURL(blob);

                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = action.payload.nombre_archivo;

                a.click();
            })
    }
})

export const selectDocLaboral = (state) => state.documentos.laboral;
export const selectStatusLaboral = (state) => state.documentos.statusLaboral;
export const selectDocCorporativa = (state) => state.documentos.corporativa;
export const selectStatusCorporativa = (state) => state.documentos.statusCorporativa;
export const selectDocArbolPersonal = (state) => state.documentos.arbolPersonal;
export const selectStatusArbolPersonal = (state) => state.documentos.statusArbolPersonal;
export const selectDocPersonal = (state) => state.documentos.personal;
export const selectStatusPersonal = (state) => state.documentos.statusPersonal;
export const selectStatusDescarga = (state) => state.documentos.statusDescarga;

export default documentosSlice.reducer