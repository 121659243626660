import { AccountCircleOutlined } from '@mui/icons-material'
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material'
import logo from 'assets/images/logo.svg'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PasswordController from '../lib/PasswordController'

const RecoveryPasswordForm = () => {
  const [username, setUsername] = useState('')
  const userRef = useRef()
  const [responseStatus, setResponseStatus] = useState(null)

  useEffect(() => {
    userRef.current.focus()
  }, [])
  return (
    <div>
      <Paper sx={{ backgroundColor: 'background.paper', p: 3 }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Logo" style={{ maxHeight: '40px' }} />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h6" sx={{ m: 2, width: '100%' }}>
            REESTABLECER CONTRASEÑA
          </Typography>
        </div>
        <div>
          <FormControl sx={{ minWidth: '25ch', width: '100%' }} variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Usuario</InputLabel>
            <Input
              error={responseStatus === 404}
              id="standard-adornment-password"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="icono usuario">
                    <AccountCircleOutlined
                      sx={{ opacity: !username ? 0.7 : 1, transition: '.3s' }}
                      color={responseStatus === 404 ? 'error' : 'primary.text'}
                    />
                  </IconButton>
                </InputAdornment>
              }
              autoComplete="false"
              ref={userRef}
              value={username}
              onChange={(e) => {
                if (responseStatus === 404) setResponseStatus(null)
                setUsername(e.target.value)
              }}
            />
          </FormControl>
        </div>
        <div>
          <Button
            onClick={async () => {
              try {
                await PasswordController.requestPasswordReset(username)
                enqueueSnackbar(
                  'Solicitud realizada correctamente, verifica tu correo para continuar',
                  { variant: 'success' },
                )
              } catch (error) {
                setResponseStatus(error.response.status)
                enqueueSnackbar(error.message, { variant: 'error' })
              }
            }}
            disabled={!username}
            variant="contained"
            sx={{ width: '100%', mt: 2 }}
          >
            Enviar
          </Button>
        </div>
        <div>
          <Button sx={{ width: '100%', mt: 2 }} LinkComponent={Link} to="/login">
            Regresar
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default RecoveryPasswordForm
