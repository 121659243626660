import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getFechaFromISO } from 'common/libs/fechas'
import { getChipSolicitud } from '../libs/SolicitudesHelpers'

import { selectSolicitudSeleccionada, setSolicitudSeleccionada } from '../solicitudesSlice'

import { selectUsuario } from 'features/users/stores/userSlice'

import axios from 'api/axios'

import SendIcon from '@mui/icons-material/Send'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import CabeceraSolicitud from './CabeceraSolicitud'

import Calendario from 'features/calendario/pages/Calendario'

const DatosSolicitud = ({ solicitud, campos }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const seleccionada = useSelector(selectSolicitudSeleccionada)
  const user = useSelector(selectUsuario)

  const [chat, setChat] = useState([])

  const [mensaje, setMensaje] = useState('')
  const [enviar, setEnviar] = useState(false)

  let fechasSolicitud
  if (solicitud.id_solicitud_pe === 'VAC01') {
    fechasSolicitud = solicitud.datos
      .map((d) => {
        if (d.valor_date) return d.valor_date
      })
      .filter((f) => f)
    console.log({ fechasSolicitud })
  }

  useEffect(() => {
    if (!solicitud) {
      if (location?.state?.solicitud) {
        dispatch(setSolicitudSeleccionada(location.state.solicitud))
        return
      }
      navigate(`/listasolicitudes/`)
    }
  }, [dispatch, navigate, location, solicitud, seleccionada])

  useEffect(() => {
    if (enviar) {
      const fecha = new Date().toISOString()
      const datos = {
        mensaje,
        fecha,
      }
      axios
        .post(`solicitud/chat/${solicitud.codigo_solicitud}/${solicitud.origen_solicitud}/`, datos)
        .then((request_response) => {
          const data = request_response.data
          setChat([...chat, data])
          setEnviar(false)
        })
        .catch((error) => setEnviar(false))
        .finally(() => setEnviar(false))
    }
  }, [enviar, solicitud, mensaje, chat])

  return solicitud ? (
    <Grid container spacing={2}>
      <Grid key="cabecera" xs={12}>
        <CabeceraSolicitud descripcion={solicitud.d_tipo} solicitud={solicitud} />
      </Grid>
      <Grid key="datos" xs={12} sm={12} md={8}>
        <Card>
          <CardHeader title="Datos" />
          <CardContent>
            {solicitud.id_solicitud_pe === 'VAC01' && fechasSolicitud ? (
              <Calendario
                requestUser={
                  solicitud.usuario_creacion
                    ? {
                        usuario_web: solicitud.usuario_creacion,
                      }
                    : null
                }
                vistaAmpliada={false}
                fechasSolicitud={fechasSolicitud}
              />
            ) : null}
            <List>
              {campos.map((c) => {
                if (c.visible !== 'S') return null
                const dato = solicitud.datos.filter(
                  (d) =>
                    d.codigo_plantilla === c.codigo_plantilla && d.codigo_campo === c.codigo_campo,
                )[0]
                if (!dato) return null
                let valor = null
                switch (c.tipo_dato) {
                  case 'V':
                    valor = dato.valor_alfa
                    break
                  case 'N':
                    valor = parseInt(dato.valor_number)
                    break
                  case 'D':
                  case 'DH1':
                  case 'DH2':
                    valor = getFechaFromISO(dato.valor_date)
                    break
                  default:
                    break
                }
                return (
                  <>
                    <ListItem key={c.id}>
                      <ListItemText primary={dato.d_valor ?? valor} secondary={c.etiqueta} />
                    </ListItem>
                    <Divider component="li" />
                  </>
                )
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid key="stausychat" xs={12} sm={12} md={4}>
        <Stack spacing={2}>
          <Card>
            <CardHeader title="Histórico" />
            <CardContent>
              <List>
                {solicitud.estados?.map((e) => {
                  return (
                    <>
                      <ListItem
                        key={e.id}
                        secondaryAction={getChipSolicitud(Number(e.status), e.d_status)}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={e.d_usuario?.split(', ')[1]}
                            src={`/assets/images/${user.codigo_empresa}_${user.codigo_portal}_${e.usuario}.jpg`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={e.d_usuario}
                          secondary={getFechaFromISO(e.fecha_hora)}
                        />
                      </ListItem>
                      {/*
                      <Typography sx={{ ml: 3 }}>{e.status_texto}</Typography>
                          */}
                    </>
                  )
                })}
              </List>
            </CardContent>
          </Card>
          {/*
          <Card>
            <CardHeader title="Chat" />
            <CardContent>
              <List>
                {chat?.length === 0 ? (
                  <ListItem key="no_hay">
                    <ListItemText primary="No hay mensajes" />
                  </ListItem>
                ) : (
                  chat.map((c) => {
                    return (
                      <ListItem key={c.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={c.d_usuario_web?.split(', ')[1]}
                            src={`/assets/images/${c.codigo_empresa}_${c.codigo_portal}_${c.usuario_web}.jpg`}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={getFechaFromISO(c.fecha)}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {c.d_usuario_web}
                              </Typography>
                              {` - ${c.mensaje}`}
                            </>
                          }
                        />
                      </ListItem>
                    )
                  })
                )}
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <TextField
                  id="chat_textfield"
                  label="Mensaje"
                  multiline
                  maxRows={4}
                  sx={{ flexGrow: 1 }}
                  onChange={(e) => setMensaje(e.target.value)}
                />
                <IconButton
                  aria-label="enviar"
                  sx={{ flexGrow: 0 }}
                  onClick={() => setEnviar(true)}
                >
                  <SendIcon />
                </IconButton>
              </Stack>
            </CardActions>
          </Card>
          */}
        </Stack>
      </Grid>
    </Grid>
  ) : (
    'Cargando'
  )
}

export default DatosSolicitud
