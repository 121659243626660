import { Avatar, Stack, CardContent, Box, CardHeader, IconButton, TextField } from '@mui/material'
import { GridMoreVertIcon } from '@mui/x-data-grid'
import MyUsersPopup from 'features/users/components/MyUsersPopup'
import { selectUsuario } from 'features/users/stores/userSlice'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useFetchWorkerData from '../hooks/useFetchWorkerData'

const WorkerData = ({ datos = null }) => {
  const [anchorEl, setAnchorEl] = useState()
  const user = useSelector(selectUsuario)
  const location = useLocation()
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <CardHeader
        sx={{ position: 'relative' }}
        avatar={
          <Avatar
            sx={{ width: '128px', height: '128px', position: 'absolute', top: '-62px' }}
            alt={`${datos?.nombre} ${datos?.primer_apellido} ${datos?.segundo_apellido}`}
            aria-label="trabajador"
          />
        }
        action={
          <IconButton onClick={(e) => setAnchorEl(e.target)} aria-label="settings">
            <GridMoreVertIcon />
          </IconButton>
        }
        title={`${datos?.primer_apellido ?? '--'} ${datos?.segundo_apellido ?? '--'}`}
        subheader={`${datos?.nombre ?? '--'}`}
        titleTypographyProps={{ marginLeft: '140px' }}
        subheaderTypographyProps={{ marginLeft: '140px' }}
      />
      <CardContent>
        <Box component="form" sx={{ display: 'flex' }}>
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <TextField
              disabled
              id="outlined-disabled"
              label="NIF"
              value={datos?.dni ?? '--'}
              sx={{ width: '33%' }}
            />
            <TextField
              disabled
              id="outlined-disabled"
              label="Código trabajador"
              value={datos?.codigo_trabajador ?? '--'}
              sx={{ width: '33%' }}
            />
            <TextField
              disabled
              id="outlined-disabled"
              label="Número SS"
              value={datos?.numero_ss ?? '--'}
              sx={{ width: '33%' }}
            />
          </Stack>
        </Box>
      </CardContent>
      <MyUsersPopup
        currentIsOwner={!location?.state?.user}
        user={location?.state?.user ?? user}
        anchorElement={anchorEl}
        handleClose={handleClose}
      />
    </>
  )
}

export default WorkerData
