// @ts-check
import { Autocomplete, autocompleteClasses, Box, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import useFetchNewsMeta from 'features/noticias/hooks/useFetchNewsMeta'
/** @type {Array<string>} */
const base_array = []

/**
 * Dibuja y selecciona las categorias de las noticias
 *
 * @param {Object} props
 * @param {Array<NewsCategories>} props.categories - Array of the categories to show
 * @param {onCategorySelectedCallback} props.onCategorySelected - Event triggered when
 * the user selects a category
 */
const CategoryFilter = ({ categories, onCategorySelected = (_) => {} }) => {
  /**
   *  @type {[NewsCategories| undefined | null, React.Dispatch<NewsCategories | undefined | null>]}
   */
  const [selectedCategory, setSelectedCategory] = useState()

  return (
    <Box sx={{ mb: 2, mt: 0 }}>
      <Autocomplete
        // value={selectedCategory}
        onChange={(_, value) => {
          onCategorySelected(value)
          // onCategorySelected(value)
        }}
        sx={{
          ml: 'auto',
          transition: '.3s',
          width: {
            sm: '35ch',
            xs: '100%',
          },
          backgroundColor: 'background.alternative',
          borderRadius: '5px',
        }}
        autoHighlight
        PaperComponent={(props) => {
          return (
            <Paper
              {...props}
              sx={{
                mt: '5px',
                inset: '2px 0 0 0 ',
                backgroundColor: 'background.alternative',
                opacity: 1.0,
                color: 'background.alternativeContrastText',
                p: 1,
                [`& .${autocompleteClasses.root}`]: {
                  p: 0,
                },
                [`& .${autocompleteClasses.listbox}`]: {
                  opacity: 1.0,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  p: 0,
                },

                '&.Mui-focused': {
                  borderColor: 'primary.main',
                  backgroundColor: '#fff !important',
                  color: 'background.alternative',
                },
                [`& .${autocompleteClasses.noOptions}`]: {
                  color: 'background.alternativeContrastText',
                  fontSize: '1.2em',
                  textAlign: 'center',
                },
              }}
            />
          )
        }}
        options={categories}
        noOptionsText="No se han encontrado categorías"
        getOptionLabel={(option) => option.descripcion}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              [`& .${autocompleteClasses.input}`]: {
                color: 'background.alternativeContrastText',
                borderRadius: '5px',
                padding: '0',
                fontSize: '1.3em',
                fontWeight: 'bold',
                '&::placeholder': {
                  opacity: 1,
                  color: 'background.alternativeContrastText',
                },
              },
              '& .MuiButtonBase-root': {
                color: 'background.alternativeContrastText',
              },
              '& fieldset': { border: 'none', color: 'red', padding: '0px' },
              backgroundColor: 'background.alternative',
              borderRadius: '5px',
              '&::placeholder': {
                color: 'white',
                fontSize: '1em',
              },
            }}
            placeholder="Selecciona una categoria"
            label=""
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    </Box>
  )
}

export default CategoryFilter
