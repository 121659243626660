import { DarkMode, LightMode, Menu as MenuIcon, Person } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  createTheme,
} from '@mui/material/'
import { useEffect, useState } from 'react'
import { Link as LinkNav, useNavigate } from 'react-router-dom'

import logo from 'assets/images/logo.svg'

import { selectNombreSubempresa, selectUsuario } from 'features/users/stores/userSlice'
import { useSelector } from 'react-redux'

import ThemesManager from 'theming/ThemeManager'
import Logo from 'common/components/Logo'

const Header = ({ handleDrawerToggle }) => {
  const navigate = useNavigate()
  const usuario = useSelector(selectUsuario)
  const nombreSubempresa = useSelector(selectNombreSubempresa)
  const [anchorEl, setAnchorEl] = useState(null)
  const themeController = new ThemesManager()
  const [theme, setTheme] = useState(
    createTheme({
      palette: themeController.activePallete(),
    }),
  )

  /**
   * @param {string} theme
   **/
  const changeTheme = (theme) => {
    localStorage.setItem('theme', theme)
    setTheme(theme)
    window.location.reload()
  }

  useEffect(() => {
    setTheme(theme)
  }, [])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!usuario) return <></>

  return (
    <AppBar
      position="static"
      sx={{
        // backgroundColor: { xs: 'background.alternative', md: 'transparent' },
        // backgroundColor: 'red',
        boxShadow: 'none',
        zIndex: 99,
        position: { xs: 'fixed', md: 'initial' },
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: {
            xs: 'background.alternative',
            md: 'background.default',
          },
          paddingTop: {
            xs: '0px',
            md: '10px',
          },
        }}
      >
        <IconButton
          sx={{
            display: {
              xs: 'flex',
              md: themeController.activeTheme.options.fullHideMenu ? 'flex' : 'none',
            },
            color: { xs: 'background.alternativeContrastText', md: 'text.primary' },
          }}
          aria-label="delete"
          size="large"
          onClick={handleDrawerToggle}
        >
          <MenuIcon color="background.alternativeContrastText" fontSize="inherit" />
        </IconButton>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, padding: '0', width: '100%' }}>
          <LinkNav to="/" style={{ width: 'auto', maxWidth: '20%', height: '100%' }}>
            <Logo logoType="Header" sx={{ height: '100%', width: 'auto' }} />
          </LinkNav>
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        {/* <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: { xs: 'text.primary' } }}>
          <SelectorSubempresa />
          <IconButton size="small" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="error">
              <MailIcon sx={{ color: 'text.primary' }} />
            </Badge>
          </IconButton>
          <IconButton size="small" aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="error">
              <NotificationsIcon sx={{ color: 'text.primary' }} />
            </Badge>
          </IconButton>
        </Box> */}
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            alignItems: 'flex-end',
            color: 'text.primary',
            width: '100%',
          }}
        >
          <Typography variant="body">{usuario.nombre}</Typography>
          {nombreSubempresa ? <Typography variant="body2">{nombreSubempresa}</Typography> : null}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="dialog"
              // onClick={handleMenu}
              onClick={() => navigate('/datos')}
              color="inherit"
            >
              <Avatar
                alt={usuario.nombre.split(', ')[1]}
                src={`/assets/images/${usuario.codigo_empresa}_${usuario.codigo_portal}_${usuario.usuario_web}.jpg`}
                sx={{
                  backgroundColor: 'secondary.main',
                  width: 40,
                  height: 40,
                  color: 'secondary.contrastText',
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate('/datos')
                }}
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary="Mis Datos" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeTheme(theme.palette.mode === 'dark' ? 'light' : 'dark')
                }}
              >
                <ListItemIcon>
                  {theme.palette.mode === 'light' ? <DarkMode /> : <LightMode />}
                </ListItemIcon>
                <ListItemText
                  primary={theme.palette.mode === 'dark' ? 'Modo Claro' : 'Modo Oscuro'}
                />
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default Header
