import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import ClearIcon from '@mui/icons-material/Clear'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import InfoIcon from '@mui/icons-material/Info'
import { useState } from 'react'
import { descargarDocumento } from '../libs/descargarDocumento'

export default function ActionsMenu({
  puedeFirmar,
  docData,
  setModalOpen,
  setAction,
  setTrigger,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const setModalConfig = (type) => {
    setModalOpen(true)
    setAction(type)
  }

  const descargar = async () => {
    const downloadData = await descargarDocumento(docData, 'octet-stream')

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = downloadData.url
    a.download = downloadData.nombreArchivo

    a.click()

    setTrigger(true)
  }

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Acciones
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={descargar}>
          {docData.mime_type === 'application/pdf' ? (
            <>
              <PictureAsPdfIcon sx={{ marginRight: '0.7rem', color: 'red' }} /> Descargar
            </>
          ) : (
            <>
              <CloudDownloadIcon sx={{ marginRight: '0.7rem' }} /> Descargar
            </>
          )}
        </MenuItem>
        {puedeFirmar !== 'N' ? (
          <MenuItem
            disabled={puedeFirmar === 'N'}
            onClick={() => {
              setModalConfig('O')
              handleClose()
            }}
          >
            <InfoIcon sx={{ marginRight: '0.7rem', color: '#426bba' }} /> Observaciones
          </MenuItem>
        ) : null}
        {puedeFirmar !== 'N' ? (
          <MenuItem
            disabled={puedeFirmar === 'N'}
            onClick={() => {
              setModalConfig('F')
              handleClose()
            }}
          >
            <FileDownloadDoneIcon sx={{ marginRight: '0.7rem', color: '#7FB77E' }} /> Firmar
          </MenuItem>
        ) : null}
        {puedeFirmar !== 'N' ? (
          <MenuItem
            disabled={puedeFirmar === 'N'}
            onClick={() => {
              setModalConfig('R')
              handleClose()
            }}
          >
            <ClearIcon sx={{ marginRight: '0.7rem', color: '#E64848' }} /> Rechazar
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  )
}
