import React from 'react'

import WorkerData from 'features/datos_trabajador/components/WorkerData'

import axios from 'api/axios'
import { Box, Button, Card, Grid, Paper } from '@mui/material'
import BotonSolicitudInterna from 'features/solicitudes/components/BotonSolicitudInterna'

const Home = () => {
  const campos_noticias = [
    ['autor', 'Autor'],
    ['nombre', 'Nombre'],
    ['fechaalta', 'Fecha'],
  ]

  const getUser = async () => {
    const data = await axios.get('/misusuarios')
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card sx={{ width: '100%', boxSizing: 'border-box' }}>
            <WorkerData />
          </Card>
        </Grid>
      </Grid>
      <Button onClick={getUser}>MIS USUARIOS</Button>
      <BotonSolicitudInterna titulo="Solicitar Vacaciones" id_solicitud_pe="VAC01" />
    </div>
  )
}

export default Home
