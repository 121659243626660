import { useState } from 'react'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const FilaFormacion = (props) => {
  const { row } = props
  const [open, setOpen] = useState(false)
  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.denominacion}
        </TableCell>
        <TableCell align="right">{row.materia ?? '--'}</TableCell>
        <TableCell align="right">{row.fecha_inicio ?? '--'}</TableCell>
        <TableCell align="right">{row.fecha_fin ?? '--'}</TableCell>
        <TableCell align="right">
          {row.duracion_horas ? `${row.duracion_horas} Horas` : 'No Terminado'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalles
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Tipo formación</TableCell>
                    <TableCell>Subtipo formación</TableCell>
                    <TableCell>Centro Formación</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.tipo_formacion}>
                    <TableCell component="th" scope="row">
                      {row.tipo_formacion}
                    </TableCell>
                    <TableCell>{row.subtipo_formacion}</TableCell>
                    <TableCell>{row.d_centro_formacion}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default FilaFormacion
