import React from 'react'
import { logOut } from 'features/users/stores/userSlice'
import { Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

const Logout = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logOut())
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        height: '80vh',
        justifyItems: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color="text.disabled" width={'100%'}>
        <span style={{ textAlign: 'center', width: '100%', color: 'inherit' }}>
          Cerrando Sesion
        </span>
      </Typography>
    </div>
  )
}

export default Logout
