import { useEffect, useState } from 'react'

import DownloadIcon from '@mui/icons-material/Download'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import { red } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { useLocation } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { descargarDocumento } from 'tool/funciones'
import useFetchLaboral from '../hooks/useFetchLaboral'

const Laboral = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [user, setUser] = useState(location?.state?.user ?? null)
  const { response, loading } = useFetchLaboral(user)
  const [documentos, setDocumentos] = useState([])
  const [ejercicios, setEjercicios] = useState([])
  const [ejerciciosSeleccionados, setEjerciciosSeleccionados] = useState([])
  const [tiposDoc, setTipoDoc] = useState([])
  const [tipoDocSeleccionado, setTipoDocSeleccionado] = useState(location?.state?.tipeDoc ?? '')
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => setIsLoading(loading), [loading])

  useEffect(() => {
    setDocumentos(response)
    const ej = Array.from(
      new Set(
        response.map((d) => {
          return d.ejercicio
        }),
      ),
    )
    setEjercicios(ej)
    setEjerciciosSeleccionados([Number(ej[0])])

    let tiposDoc = []
    const tipos = response
      .map((doc) => {
        if (tiposDoc.indexOf(doc.rh_documento) === -1) {
          tiposDoc.push(doc.rh_documento)
          return {
            rh_documento: doc.rh_documento,
            d_rh_documento: doc.d_rh_documento,
          }
        }
        return false
      })
      .filter((el) => el !== false)

    setTipoDoc(tipos)
  }, [response])

  const handleEjercicio = (e) => {
    const ejercicio = Number(e.currentTarget.dataset.ejercicio)
    const aux = ejerciciosSeleccionados
    if (aux.indexOf(ejercicio) === -1) {
      setEjerciciosSeleccionados([...ejerciciosSeleccionados, ejercicio])
    } else {
      setEjerciciosSeleccionados(aux.filter((a) => a !== ejercicio))
    }
  }

  const handleTipoDoc = (e) => {
    setTipoDocSeleccionado(e.target.value)
  }

  const downloadFile = async (e) => {
    descargarDocumento(e.target.dataset.tipo, e.target.dataset.id)
  }

  return (
    <Box sx={{ width: '100%' }}>
      {user ? <CustomUserCard user={user} returnToUser={() => setUser(null)} /> : <></>}
      <Stack spacing={0}>
        <Stack
          key="filtros"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 2 }}
        >
          <Stack key="ejercicios-grid" direction="row" spacing={2} sx={{ flexGrow: 1 }}>
            {ejercicios.map((ejercicio, idx) => {
              return ejerciciosSeleccionados.indexOf(Number(ejercicio)) === -1 ? (
                <Chip
                  key={ejercicio + idx}
                  data-ejercicio={ejercicio}
                  label={`${ejercicio}`}
                  variant="outlined"
                  onClick={handleEjercicio}
                  color="primary"
                />
              ) : (
                <Chip
                  key={ejercicio + idx}
                  data-ejercicio={ejercicio}
                  label={`${ejercicio}`}
                  onClick={handleEjercicio}
                  color="primary"
                />
              )
            })}
          </Stack>
          <FormControl key="tipo-doc-form" variant="standard" sx={{ m: 1, minWidth: 240 }}>
            <InputLabel key="tipo_documento" id="tipo_documento">
              Tipo de documento
            </InputLabel>
            <Select
              labelId="tipo-documento-select"
              key="tipo-documento-select"
              id="tipo-documento-select"
              value={tiposDoc?.length > 0 ? tipoDocSeleccionado : ''}
              onChange={handleTipoDoc}
              label="Tipo de documento"
            >
              <MenuItem key="menu-0" value="">
                Todos los documentos
              </MenuItem>
              {tiposDoc.map((t, index) => {
                return (
                  <MenuItem key={t.rh_documento + index} value={t.rh_documento}>
                    {t.d_rh_documento}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

        <Box key="documentos" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            {documentos.length > 0 ? (
              documentos.map((d) => {
                const fecha_inicio = d.nom_inicio ? new Date(d.nom_inicio) : null
                const fecha_fin = d.nom_fin ? new Date(d.nom_fin) : null
                return ejerciciosSeleccionados.indexOf(Number(d.ejercicio)) === -1 ? (
                  <></>
                ) : tipoDocSeleccionado !== '' && d.rh_documento !== tipoDocSeleccionado ? (
                  <></>
                ) : (
                  <Grid key={d.id} xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: red[500] }}
                            alt={d.d_rh_documento}
                            src="/static/images/avatar/2.jpg"
                          />
                        }
                        title={d.d_rh_documento}
                        subheader={`${d.ejercicio} - ${d.periodo}`}
                      />
                      <CardContent>
                        <Grid container>
                          <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                              Fecha inicio
                            </Typography>
                            <Typography variant="body1">
                              {fecha_inicio.toLocaleDateString()}
                            </Typography>
                          </Grid>
                          <Divider orientation="vertical" flexItem />
                          <Grid item xs>
                            <Typography variant="caption" display="block" gutterBottom>
                              Fecha fin
                            </Typography>
                            <Typography variant="body1">
                              {fecha_fin.toLocaleDateString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions disableSpacing>
                        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                          <Button
                            size="small"
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            data-id={d.id}
                            data-tipo={d.tipo_doc_principal}
                            onClick={downloadFile}
                          >
                            PDF
                          </Button>
                          {d.has_xml > 0 ? (
                            <Button
                              size="small"
                              variant="contained"
                              color="warning"
                              startIcon={<DownloadIcon />}
                              data-id={d.id}
                              data-tipo={d.tipo_doc_secundario}
                              onClick={downloadFile}
                            >
                              XML
                            </Button>
                          ) : (
                            ''
                          )}
                        </Stack>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
            ) : isLoading ? (
              [...Array(12).keys()].map((i) => {
                return (
                  <Grid key={i} xs={12} sm={6} md={4}>
                    <Card>
                      <CardHeader
                        avatar={
                          <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        }
                        title={
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={<Skeleton animation="wave" height={10} width="40%" />}
                      />
                      <CardContent>
                        <Skeleton animation="wave" height={10} width="50%" />
                      </CardContent>
                      <CardActions disableSpacing>
                        <Skeleton animation="wave" variant="rectangular" height={30} width="35%" />
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
            ) : (
              <h1 style={{ opacity: 0.3, textAlign: 'center' }}>No se encontraron documentos</h1>
            )}
          </Grid>
        </Box>
      </Stack>
    </Box>
  )
}

export default Laboral
