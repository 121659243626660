
import ListadoFacturas from '../components/ListadoFacturas';

function VistaFacturas() {
  return (
    <>
        <ListadoFacturas />
    </>
  )
}

export default ListadoFacturas;