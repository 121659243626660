import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LinearProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CustomUserCard from 'features/users/components/CustomUserCard'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFetchDates from '../hooks/useFetchDates'
import '../styles.scss'
import Calendar from './Calendar'

import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack'

import { selectDatosSolicitud, setDatosSolicitud } from 'features/solicitudes/solicitudesSlice'

const CalendarioAnual = ({
  currentYear = new Date().getFullYear(),
  setCurrentYear = null,
  useUser = [null, () => {}],
  vacacionesPendientes = null,
  vacacionesTipoDias = null,
  allowSelection = false,
  fechasSolicitud = [],
}) => {
  const dispatch = useDispatch()

  const [user, setUser] = useUser
  const { response, loading, error } = useFetchDates({
    year: currentYear,
    user: user,
  })
  const [fechas, setFechas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [requestError, setRequestError] = useState(null)

  const [pendientes, setPendientes] = useState(vacacionesPendientes)
  const [tipoDias, setTipoDias] = useState(vacacionesTipoDias)

  const datos = useSelector(selectDatosSolicitud)

  useEffect(() => {
    for (const fecha of fechasSolicitud) {
      const date = new Date(fecha)
      response.push({
        color: '#fad101',
        name: 'Solicitada',
        situacion: 'S',
        tipo: 'S',
        startDate: date,
        endDate: date,
      })
    }

    setFechas(response)
  }, [response])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    if (error)
      return setRequestError({
        type: 'error',
        text:
          error?.response?.status === 500 ? `Ha ocurrido un error en el servidor` : error.message,
      })
    setRequestError(null)
  }, [error])

  useEffect(() => {
    if (datos) {
      let _datos = { ...datos }
      for (const idpk in _datos) {
        if (Object.hasOwnProperty.call(_datos, idpk)) {
          _datos[idpk] = null
        }
      }
      for (const fecha of fechas) {
        if (fecha.tipo === 'seleccion') {
          for (const idpk in _datos) {
            if (Object.hasOwnProperty.call(_datos, idpk)) {
              if (!_datos[idpk]) {
                _datos[idpk] = fecha.startDate
                break
              }
            }
          }
        }
      }
      dispatch(setDatosSolicitud(_datos))
    }
  }, [fechas])

  const ahora = new Date()
  const minDate = new Date(ahora.getFullYear() - 1, 0, 1)
  const maxDate = new Date(ahora.getFullYear() + 1, 11, 31)

  const selectedRange = (rango) => {
    const _fechas = rango.calendar.getDataSource()
    const ahora = new Date()

    console.log({ pendientes, tipoDias })

    if (rango.startDate.getTime() <= rango.endDate.getTime()) {
      let nuevas_fechas = []

      for (
        let d = new Date(rango.startDate.getTime());
        d.getTime() <= rango.endDate.getTime();
        d.setDate(d.getDate() + 1)
      ) {
        if (d.getTime() < ahora.getTime()) {
          enqueueSnackbar(`No se pueden seleccionar fechas anteriores al día de hoy`)
          return
        }
        const _fecha = new Date(d.getTime())
        _fecha.setHours(1)

        // Si ya estaba seleccionada la fecha la quita
        const index = _fechas.findIndex(
          (f) => f.startDate.getTime() === _fecha.getTime() && f.tipo === 'seleccion',
        )
        if (index > -1) {
          _fechas.splice(index, 1)
          continue
        }

        // Comprueba el tipo de día que va a seleccionar
        const diaSeleccionado = _fechas.filter(
          (f) =>
            f.startDate.getFullYear() === _fecha.getFullYear() &&
            f.startDate.getMonth() === _fecha.getMonth() &&
            f.startDate.getDate() === _fecha.getDate() &&
            f.tipo !== 'seleccion',
        )[0]
        const tipoDiaSeleccionado = diaSeleccionado ? diaSeleccionado.tipo : 'L'
        if (tipoDias === 'X' && tipoDiaSeleccionado === 'F') continue
        if (tipoDias === 'L' && tipoDiaSeleccionado !== 'L') continue

        // No permite seleccionar más días de los que le quedan pendientes
        const seleccionados =
          _fechas.filter((f) => f.tipo === 'seleccion').length + nuevas_fechas.length
        if (pendientes - seleccionados <= 0) {
          enqueueSnackbar(`No le quedan más días disponibles`)
          break
        }

        nuevas_fechas.push({
          name: 'Selección',
          startDate: _fecha,
          endDate: _fecha,
          color: 'red',
          tipo: 'seleccion',
          seleccion: 'seleccion',
        })
      }
      const seleccion = [..._fechas, ...nuevas_fechas]
      setFechas(seleccion)
      rango.calendar.setDataSource(seleccion)
    }
  }

  const changeYear = (year) => {
    // setCurrentYear(year.currentYear)
  }

  const addTooltip = (element, events) => {
    let tooltip = ''
    if (events.length > 0) {
      events.forEach((event) => {
        tooltip += `${event.name}\n`
      })
      element.dataset.tooltip = tooltip
      element.setAttribute('tabindex', '0')
    }
  }

  const fechas_ds = fechas
  return (
    <>
      <SnackbarProvider
        maxSnack={5}
        autoHideDuration={6000}
        action={(snackbarId) => (
          <IconButton onClick={() => closeSnackbar(snackbarId)} aria-label="cerrar alerta">
            <CloseRoundedIcon />
          </IconButton>
        )}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        variant={'error'}
      />
      {user?.id ? (
        <CustomUserCard
          returnToUser={() => {
            setUser(null)
          }}
          loading={isLoading}
          user={user}
          message={requestError ?? null}
        />
      ) : (
        <></>
      )}
      {isLoading & !user ? <LinearProgress /> : <></>}
      <Calendar
        language="es"
        enableRangeSelection={allowSelection}
        onDayEnter={(event) => {
          addTooltip(event.element, event.events)
        }}
        roundRangeLimits={false}
        enableContextMenu={true}
        minDate={minDate}
        maxDate={maxDate}
        onRangeSelected={selectedRange}
        onYearChanged={changeYear}
        dataSource={fechas_ds}
      />
    </>
  )
}

export default CalendarioAnual
