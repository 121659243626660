import Corporativa from '../components/Corporativa'

const DocCorporativa = ({ object }) => {
  return (
    <>
      <Corporativa document_type={object ?? null} />
    </>
  )
}

export default DocCorporativa
